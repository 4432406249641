import React from 'react';
import { Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

function ProductContentLoading() {

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Skeleton height={150} />
                    </Grid>
                    <Grid item xs={4}>
                        <Skeleton height={150} />
                    </Grid>
                    <Grid item xs={4}>
                        <Skeleton height={150} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Skeleton height={50} />
                <Skeleton height={50} />
                <Skeleton height={50} />
                <Skeleton height={50} />
            </Grid>
        </Grid>
    )

}

export default ProductContentLoading;
