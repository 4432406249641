import React, { useState } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import { useMainCompany } from '../../../relations/component/user-relation-provider';
import RenewSubscriptionsModal from './renew-subscriptions-modal';
import { useTranslation } from 'react-i18next';

export default function RenewSubscriptionButton({ }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.renew-subscriptions.button' })
    const [open, setOpen] = useState(false);
    const onClose = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(false);
    }
    const onOpen = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(true);
    }

    const company = useMainCompany();

    return (
        <React.Fragment>
            <ListItem button onClick={onOpen}>
                <ListItemIcon><SubscriptionsIcon /></ListItemIcon>
                <ListItemText primary={t('label')} />
            </ListItem >
            <RenewSubscriptionsModal
                vayandata={company}
                open={open} onClose={onClose} />
        </React.Fragment>
    );
}
