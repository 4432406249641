import React from 'react';
import { Typography } from '@material-ui/core';

function LinesCount({ order, ...props }) {
    const count = order.payload.lines.length;

    return (
        <Typography {...props} >
            {count}
        </Typography>
    )
}

function OrderLinesCount({ order, ...props }) {

    return (
        <LinesCount {...props} order={order} />
    );
}

export default OrderLinesCount;
