import Resource from '../resource';
import axios from 'axios';
import _ from 'lodash';

export default class ReferenceResource extends Resource {

    list(auth, customer) {
        return async (dispatch, getState) => {
            dispatch(this._makePendingPayload('list', null));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'get',
                    baseURL: this.uri,
                    url: `c/${customer}/references`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                dispatch(this._makeSuccessPayload('list', res.data.data, null));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('list', error, null, flag));
                throw error;
            }
        }
    }

    get(auth, id, customer) {
        return async (dispatch, getState) => {
            dispatch(this._makePendingPayload('get', id));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'get',
                    baseURL: this.uri,
                    url: `c/${customer}/references/${id}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                dispatch(this._makeSuccessPayload('get', res.data.data, id));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('get', error, id, flag));
                throw error;
            }
        }
    }

}