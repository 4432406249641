import React from 'react';
import { Card, CardHeader, makeStyles } from '@material-ui/core';
import defaultBackground from "../../../assets/images/banner.jpg"

const useStyles = makeStyles(theme => ({
    root: props => ({
        position: 'relative',
        width: '100%',
        "&::before": {
            content: '" "',
            display: 'block',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            opacity: '0.2',
            backgroundImage: props.background ? `url("${props.background}")` : "none",
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundColor: theme.palette.primary.main,
        }
    }),
    content: {
        position: 'relative'
    },
}));

function PageHeader({ background, title, children }) {
    const classes = useStyles({ background: background || defaultBackground });

    return (
        <Card className={classes.root}>
            {title && <CardHeader title={title} />}
            {children && children}
        </Card>
    )
}

export default PageHeader;
