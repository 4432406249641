import React from 'react';
import { Typography } from '@material-ui/core';

function OrderNumber({ order, ...props }) {
    const number = order.payload.order_number || '';

    return (
        <Typography {...props}>
            {number}
        </Typography>
    )
}

export default OrderNumber;
