import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    padded: {
        paddingRight: theme.spacing(4),
    }
}))

export function CreditCardAvatar() {
    return (
        <Avatar>
            <CreditCardIcon />
        </Avatar>
    )
}

export default function CreditCardContent({ card }) {

    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.forms.payment-method-form.credit-card-content' });

    return (
        <Grid container spacing={2}>
            <Grid item xs={2}>
                <Typography className={classes.padded} variant='overline' color='primary'>{card.brand}</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography className={classes.padded} component="span" variant="body2" color="textPrimary">
                    **** **** **** {card.last4}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography className={classes.padded} component="span" variant="body2" color="textPrimary">
                    {`${card.exp_month < 10 ? '0' : ''}${card.exp_month}/${card.exp_year}`}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                {card.three_d_secure_usage.supported
                    ? <Typography className={classes.padded} variant='overline' color='primary'>{t('3D-secure-supported')}</Typography>
                    : <Typography className={classes.padded} variant='overline' color='secondary'>{t('3D-secure-not-supported')}</Typography>
                }
            </Grid>
        </Grid>
    );
}
