import Resource from '../resource';
import _ from 'lodash';
import axios from 'axios';

export default class CompanyResource extends Resource {

    put(auth, data) {
        return async (dispatch, getState) => {
            dispatch(this._makePendingPayload('put', null));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'put',
                    baseURL: this.uri,
                    url: `/companies`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    data,
                });
                dispatch(this._makeSuccessPayload('put', res.data?.data?.company, null));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('put', error, null, flag));
                throw error;
            }
        }
    }

    postAddress(auth, identifier, data, query = {}) {

        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.${this.resource}`);

            const existing = !!state.byId[identifier];
            const pending = existing && state.byId[identifier].status.includes('pending');

            // dont do anything if operation is pending.
            if (pending) {
                return null;
            }

            dispatch(this._makePendingPayload('post', identifier));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `/companies/${identifier}/address`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    data,
                    params: query,
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, identifier, flag));
                throw error;
            }
        }
    }

    postBilling(auth, identifier, data, query = {}) {

        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.${this.resource}`);

            const existing = !!state.byId[identifier];
            const pending = existing && state.byId[identifier].status.includes('pending');

            // dont do anything if operation is pending.
            if (pending) {
                return null;
            }

            dispatch(this._makePendingPayload('post', identifier));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `/companies/${identifier}/billing`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    data,
                    params: query,
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, identifier, flag));
                throw error;
            }
        }
    }

    listClients(clients) {
        return async (dispatch) => {
            dispatch(this._makePendingPayload('list', null));
            dispatch(this._makeSuccessPayload('list', clients, null));
        }
    }
}