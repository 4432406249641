import React, { forwardRef, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useImpersonateSdk } from '../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../lib/auth0-wrapper';
import InteractiveLoadingButton from '../../../common/component/interactive-loading-button';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { useNotif } from '../../../common/component/notification';
import ActionOk from '../../../common/component/notification/action-ok';
import { useCustomer } from '../../../customers/component/customer-provider';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    button: {
        marginRight: theme.spacing(1),
    },
}));

export const DownloadLicense = forwardRef((props, ref) => {

    const { t } = useTranslation('translation', { keyPrefix: 'app.devices.actions.download-license' });
    const classes = useStyles();

    return (
        <Button
            color='secondary'
            className={classes.button}
            size='small'
            {...props}
            ref={ref}
        >{t('button-label')}</Button>
    )
});

export function DownloadLicenseController(props) {

    const { component, device, ...innerProps } = props;

    const { t } = useTranslation('translation', { keyPrefix: 'app.devices.actions.download-license' });
    const dispatch = useDispatch();
    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const notif = useNotif();

    const customer = useCustomer();

    const loading = device.status.includes('pending');
    const erred = device.status.includes('error');
    const disabled = loading || erred || !device.payload.active;
    const tooltip =
        loading ? t('loading') :
            erred ? t('error') :
                !device.payload.active ? t('deactivated') :
                    t('tooltip');

    innerProps.disabled = disabled;
    innerProps.onClick = useCallback((ev) => {
        
        ev.preventDefault();
        ev.stopPropagation();

        dispatch(sdk.devices.license(auth, device.target, `${device.payload.winid}-license.json`, customer.target))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            });
        // eslint-disable-next-line   
    }, [sdk, auth, device.target]);

    return (
        <InteractiveLoadingButton tooltip={tooltip} loading={loading} >
            {React.createElement(component, innerProps)}
        </InteractiveLoadingButton>
    )
}



export default function DownloadLicenseButton({ device }) {
    return (
        <WaitingLoaded data={device} render={() =>
            <DownloadLicenseController
                device={device}
                component={DownloadLicense}
            />}
        />
    )
}
