import React, { } from 'react';
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    granted: {
        verticalAlign: 'middle',
        color: theme.palette.success[theme.palette.type],
    },
}));

export function CanProceed(props) {
    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.order-lines.sumup.check' });

    return (
        <span>
            <Typography display='inline'>
                {t('can-proceed')}
            </Typography>
            <DoneAllOutlinedIcon className={classes.granted} />
        </span>
    );
}