import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import MakeOrderDialog from '../../orders/component/order-modal';
import ReferenceCard from './card/reference-card';
import SubscribeButton from '../../prices/component/actions/subscribe-button';
import { useReferenceById } from '../selector';

const useStyles = makeStyles({
    actionRight: {
        flexDirection: 'row-reverse',
    },
});

export function ReferenceGridItemController({ product, price, component }) {

    const [open, setOpen] = useState(false);
    const openDialog = useCallback((ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        setOpen(true);
    }, []);
    const closeDialog = useCallback((ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        setOpen(false);
    }, []);

    const reference = useReferenceById(price.payload.reference);

    return React.createElement(component, { product, reference, price, open, openDialog, closeDialog });
}

export function ReferenceGridItemView({ price, reference, product, open, openDialog, closeDialog }) {
    const classes = useStyles();

    return (
        <ReferenceCard price={price} reference={reference?.target} actions={(
            <CardActions className={classes.actionRight}>
                <SubscribeButton onClick={openDialog} color='secondary' size="small" />
                <MakeOrderDialog price={price} reference={reference} product={product} open={open} onClose={closeDialog} />
            </CardActions>
        )} />
    );

}

export default function ReferenceGridItem(props) {
    return <ReferenceGridItemController component={ReferenceGridItemView} {...props} />
};
