import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useSdk } from "../../lib/licensing-sdk";
import { GRADE_CUSTOMER, GRADE_DISTRIBUTOR, GRADE_VAYAN_DATA, ONBOARDING_PENDING } from "../companies/constants";
import { useCompanyIsDistributor, useCompanyIsVayanData } from "../companies/selector";
import { useMainCompany } from "../relations/component/user-relation-provider";
import { useCustomer } from "./component/customer-provider";
import { createSelector } from "reselect";

export const useCustomersList = () => {
    const sdk = useSdk();
    const selector = useMemo(() => sdk.customers.selectors.makeListIds(), []);
    const customers = useSelector(state => selector(state));
    return customers;
}

export const useDistributorsList = () => {
    const sdk = useSdk();
    const selector = useMemo(() => createSelector(
        sdk.customers.selectors.getDb,
        (customers) => {
            const distributors = _.chain(customers)
                .filter(customers => customers.payload)
                .filter(customers => customers.payload.grade === GRADE_DISTRIBUTOR)
                .orderBy(['payload.name'], ['asc'])
                .map(customers => customers.target)
                .value();
            return distributors;
        }
    ), []);
    const distributors = useSelector(state => selector(state));
    return distributors;
}

export const useAwaitingValidationCustomersList = () => {
    const sdk = useSdk();
    const selector = useMemo(() => createSelector(
        sdk.customers.selectors.getDb,
        (customers) => {
            const awaiting = _.chain(customers)
                .filter(customers => customers.payload)
                .filter(customers => customers.payload.onboarding_status === ONBOARDING_PENDING)
                .orderBy(['payload.created_at'], ['desc'])
                .value();
            return awaiting;
        }
    ), []);
    return useSelector(selector);
}

export const useCustomerInDb = (id) => {
    const sdk = useSdk();
    const selector = useMemo(() => sdk.customers.selectors.makeGet(), []);
    const data = useSelector(state => selector(state, id));
    return data;
}

export const useIsViewingMainCustomer = () => {
    const company = useMainCompany();
    const customer = useCustomer();
    return company && customer && customer.target === company.target;
}

export const useCustomerIsClient = () => {
    const customer = useCustomer();
    return customer.payload.grade !== GRADE_CUSTOMER;
}

export const useCustomerIsDistributor = () => {
    const customer = useCustomer();
    return customer.payload.grade === GRADE_DISTRIBUTOR;
}

export const useCustomerIsVayanData = () => {
    const customer = useCustomer();
    return customer.payload.grade === GRADE_VAYAN_DATA;
}

export const useViewingAsDistributor = () => {
    const isMainCompany = useIsViewingMainCustomer();
    const isDistributor = useCompanyIsDistributor();
    return !isMainCompany && isDistributor;
}

export const useViewingAsVayanData = () => {
    const isMainCompany = useIsViewingMainCustomer();
    const isVayanData = useCompanyIsVayanData();
    return !isMainCompany && isVayanData;
}

export const useCustomerIsDistributed = () => {
    const customer = useCustomer();
    const isDistributed = Boolean(customer.payload.distributor_id);
    return isDistributed;
}