import React, { useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import CenteredFullScreen from '../../../common/component/centered-full-screen';
import { useNotif } from '../../../common/component/notification';
import { useSdk } from '../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../lib/auth0-wrapper';
import ActionOk from '../../../common/component/notification/action-ok';
import CustomerCoefficientField from '../fields/coefficient-field';
import { useTranslation } from 'react-i18next';

function useHandleSubmit(onClose, customer, distributor) {
    const notif = useNotif();
    const sdk = useSdk();
    const auth = useAuth0();
    const dispatch = useDispatch();

    return useCallback((values, { setSubmitting }) => {

        const payload = {
            coefficient: values.coefficient
        };

        const imp = sdk.impersonateByDistributor(distributor.target);

        dispatch(imp.customers.changeCoefficient(auth, payload, customer.target))
            .then(() => {
                setSubmitting(false);
                onClose();
            })
            .catch(err => {
                const message = err.response?.data?.message || err.message;
                notif.error(message, { action: (key) => <ActionOk notif={notif} handle={key} /> });
                setSubmitting(false);
            });
    }, []);
}

export default function ChangeCoefficientByDistributorModal({ open, onClose, customer, distributor, ...props }) {

    const initialValues = { coefficient: customer.payload.coefficient };
    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.change-coefficient.distributor-modal' })
    const handleSubmit = useHandleSubmit(onClose, customer, distributor);

    const min = distributor.payload.coefficient;
    const max = 1;

    if (open) {
        return (
            <Dialog maxWidth='lg' fullWidth open={open} onClose={onClose} >
                <DialogTitle>
                    <Typography display='inline'>{t('title', { min, max })}</Typography>
                </DialogTitle>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {(props) => (
                        <Form>
                            <DialogContent>
                                <CenteredFullScreen>
                                    <CustomerCoefficientField {...props}
                                        min={min}
                                        max={max}
                                    />
                                </CenteredFullScreen>
                            </DialogContent>
                            <DialogActions>
                                <Button disabled={props.isSubmitting} onClick={onClose}>
                                    {t('cancel')}
                                </Button>
                                <Button disabled={props.isSubmitting} onClick={props.handleReset}>
                                    {t('reset')}
                                </Button>
                                <Button disabled={props.isSubmitting} onClick={props.handleSubmit} color='secondary' >
                                    {t('proceed')}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik >
            </Dialog >
        );
    } else {
        return null;
    }

}