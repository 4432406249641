import Resource from '../resource';
import _ from 'lodash';
import axios from 'axios';

export default class OrderlinesResource extends Resource {
    count = undefined;
    post = undefined;
    put = undefined;

    constructor(deviceResource, orderResource, ...opts) {
        super(...opts);
        this.deviceResource = deviceResource;
        this.orderResource = orderResource;
    }

    list(auth, customer, force = false) {
        return async (dispatch, getState) => {

            const state = _.get(getState(), `${this.path}.orders`);

            const pending = state.allIds.status && state.allIds.status.includes('pending');

            // dont do anything if operation is pending.
            if (!force && pending) {
                return null;
            }

            dispatch(this._makePendingPayload('list', null));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'get',
                    baseURL: this.uri,
                    url: `c/${customer}/orderlines/`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                dispatch(this._makeSuccessPayload('list', res.data.data, null));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('list', error, null, flag));
                throw error;
            }
        }
    }

    get(auth, identifier, customer, force = false) {
        return async (dispatch, getState) => {

            const state = _.get(getState(), `${this.path}.orderlines`);

            const existing = state.byId[identifier];
            const pending = existing && existing.status.endsWith('pending');
            const hasErred = existing && existing.status.endsWith('error');

            // dont do anything if operation is pending or already exists.
            // The update can be forced if its existing
            if ((!force && (existing && !hasErred)) || pending) {
                if (existing && existing.payload) return existing.payload;
                return null;
            }

            dispatch(this._makePendingPayload('get', identifier));


            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'get',
                    baseURL: this.uri,
                    url: `c/${customer}/orderlines/${identifier}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: {},
                });
                dispatch(this._makeSuccessPayload('get', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('get', error, identifier, flag));
                throw error;
            }
        }
    }

    cancel(auth, identifier, customer) {
        return async (dispatch) => {
            dispatch(this._makePendingPayload('post', identifier));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `c/${customer}/orderlines/${identifier}/cancel`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: {},
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, identifier, flag));
                throw error;
            }
        }
    }

    uncancel(auth, identifier, customer) {
        return async (dispatch) => {
            dispatch(this._makePendingPayload('post', identifier));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `c/${customer}/orderlines/${identifier}/uncancel`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: {},
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, identifier, flag));
                throw error;
            }
        }
    }

    async countForSameReference(auth, line, customer) {
        try {
            const token = await auth.getTokenSilently();
            const res = await axios({
                method: 'get',
                baseURL: this.uri,
                url: `c/${customer}/orderlines/${line}/count-same-reference`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return res.data.data;
        } catch (error) {
            throw error;
        }
    }

    putForDevices(auth, devices, price, customer) {
        return async (dispatch, getState) => {
            dispatch(this._makePendingPayload('put', null));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'put',
                    baseURL: this.uri,
                    url: `c/${customer}/orderlines/devices`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    data: {
                        quantity: devices,
                        price,
                    }
                });
                dispatch(this._makeSuccessPayload('put', res.data.data, null));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('put', error, null, flag));
                throw error;
            }
        }
    }

    putForSessions(auth, quantity, price, customer) {
        return async (dispatch, getState) => {
            dispatch(this._makePendingPayload('put', null));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'put',
                    baseURL: this.uri,
                    url: `c/${customer}/orderlines/sessions`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    data: {
                        quantity,
                        price,
                    }
                });
                dispatch(this._makeSuccessPayload('put', res.data.data, null));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('put', error, null, flag));
                throw error;
            }
        }
    }

    remove(auth, line, customer) {
        return async (dispatch, getState) => {
            dispatch(this._makePendingPayload('remove', line));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'delete',
                    baseURL: this.uri,
                    url: `c/${customer}/orderlines/${line}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                dispatch(this.orderResource._makeSuccessPayload('post', res.data.order, res.data.order._id));
                _.each(res.data.data, line => {
                    dispatch(this._makeSuccessPayload('remove', null, line));
                });
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('remove', error, line, flag));
                throw error;
            }
        }
    }

    associateDevice(auth, line, device, customer) {

        return async (dispatch, getState) => {
            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `c/${customer}/orderlines/${line}/associate-device`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    data: {
                        device
                    },
                });
                dispatch(this._makeSuccessPayload('post', res.data.data.line, line));
                dispatch(this.deviceResource._makeSuccessPayload('post', res.data.data.device, res.data.data.device._id));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, line, flag));
                throw error;
            }
        }
    }

    markFree(auth, line, customer) {
        return async (dispatch, getState) => {
            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'get',
                    baseURL: this.uri,
                    url: `c/${customer}/orderlines/${line}/mark-free`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                dispatch(this._makeSuccessPayload('post', res.data.data.line, line));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, line, flag));
                throw error;
            }
        }
    }

    revertFree(auth, line, customer) {
        return async (dispatch, getState) => {
            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'get',
                    baseURL: this.uri,
                    url: `c/${customer}/orderlines/${line}/revert-free`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                dispatch(this._makeSuccessPayload('post', res.data.data.line, line));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, line, flag));
                throw error;
            }
        }
    }
}