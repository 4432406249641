import React from 'react';
import { Typography } from '@material-ui/core';
import { ONBOARDING_COMPLETED } from '../../constants';
import { ResourceGetter } from '../../../../lib/licensing-sdk/getter';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { useTranslation } from 'react-i18next';

function CompanyOnboarding({ company, capitalize, ...props }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.elements.company-onboarding' })
    return (
        <ResourceGetter resource='companies' id={company} render={({ companie: company }) => (
            <WaitingLoaded data={company} render={() => {
                const status = company?.payload?.onboarding_status !== ONBOARDING_COMPLETED ? t('pending') : null;
                if (!status) return null
                return (
                    <Typography
                        {...props}
                        style={{ textTransform: capitalize ? 'capitalize' : 'inherit' }}
                    >
                        {status}
                    </Typography>
                )
            }} />
        )} />

    )
}

export default CompanyOnboarding;
