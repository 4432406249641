import React from 'react';
import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ReactCountryFlag from "react-country-flag"
import _ from 'lodash';
import { Field, useField } from 'formik';
import { Select, TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    field: {
        width: '100%'
    },
    taxTypeBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%'
    },
    typo: {
        marginLeft: theme.spacing(1)
    },
    header: {
        fontSize: '0.8rem',
    },
    subheader: {
        fontSize: '0.7rem',
    }
}));

const types = _.map(require('./taxids.json'), (item, key) => key).sort();
const taxes = require('./taxids.json')

export const taxIdSchema = (t) => ({
    tax_id_type: Yup.string().required(t('tax-id-type-validation')),
    tax_id_value: Yup.string().required(t('tax-id-value-validation')),
})

const CompanyTaxIdTypeField = ({ namespace, errors, disabled, isSubmitting }) => {
    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.forms.billing-form.fields.company-tax-id-field' });

    const name = `${namespace ? namespace + '.' : ''}tax_id_type`;
    const error = _.get(errors, name);

    return (
        <FormControl className={classes.field} error={Boolean(error)}>
            <Field
                component={Select}
                fullWidth
                name={name}
                disabled={disabled || isSubmitting}
                renderValue={(value) => (
                    <Box className={classes.taxTypeBox}>
                        <ReactCountryFlag countryCode={taxes[value]?.country} />
                        <Box className={classes.typo}>
                            <Typography variant='body1' className={classes.header} >{value.toUpperCase().split('_').join(' ')}</Typography>
                        </Box>
                    </Box>
                )}
                validate={value => types.includes(value) ? undefined : t('type-validation')}
            >
                {types.map((item) => (
                    <MenuItem key={item} value={item}>
                        <Box className={classes.taxTypeBox}>
                            <ReactCountryFlag countryCode={taxes[item]?.country} />
                            <Box className={classes.typo}>
                                <Typography variant='body1' className={classes.header} >{item.toUpperCase().split('_').join(' ')}</Typography>
                                <Typography variant='body1' className={classes.subheader} >{taxes[item].description}</Typography>
                            </Box>
                        </Box>
                    </MenuItem>
                ))}
            </Field>
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    )
}

const CompanyTaxIdValueField = ({ namespace, disabled, isSubmitting }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.forms.billing-form.fields.company-tax-id-field' });
    const name = `${namespace ? namespace + '.' : ''}tax_id_value`;
    const [field] = useField(`${namespace ? namespace + '.' : ''}tax_id_type`);
    const validate = (value) => {
        const tax_type = field.value;
        const regex = tax_type && taxes[tax_type] ? new RegExp(taxes[tax_type].format) : null;
        if (regex) {
            return regex.test(value);
        } else {
            return true;
        }
    }

    return (
        <Field
            component={TextField}
            fullWidth
            name={name}
            disabled={disabled || isSubmitting}
            validate={value => validate(value) ? undefined : t('value-validation')}
        />
    )
}

const CompanyTaxIdField = (props) => {

    return (
        <Grid container spacing={2} alignItems='baseline'>
            <Grid item xs={3}>
                <CompanyTaxIdTypeField {...props} />
            </Grid>
            <Grid item xs={9}>
                <CompanyTaxIdValueField {...props} />
            </Grid>
        </Grid>
    )
}


export default CompanyTaxIdField;
