import Resource from '../resource';
import _ from 'lodash';
import axios from 'axios';

export default class ConsentsResource extends Resource {

    async get(auth) {
        try {
            const token = await auth.getTokenSilently();
            const res = await axios({
                method: 'get',
                baseURL: this.uri,
                url: `/consents`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return res.data.data || null;
        } catch (error) {
            throw error;
        }
    }

    async give(auth, consent, opinion) {
        try {
            const token = await auth.getTokenSilently();
            const res = await axios({
                method: 'post',
                baseURL: this.uri,
                url: `/consents/${consent}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                data: {
                    opinion
                }
            });
            return res.data.data || null;
        } catch (error) {
            throw error;
        }
    }

}