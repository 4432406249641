import React, { useState } from 'react';
import ApplicationLoadingBar from './application-loading-bar';
import Header from './app-bar-header';

import { makeStyles } from '@material-ui/styles';
import UserRelationProvider, { useMainCompany } from '../../relations/component/user-relation-provider';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import NoMatch from '../../error/component/no-match';
import { useCompanyIsDistributor, useCompanyIsVayanData } from '../../companies/selector';
import Workbench from '../../workbench/component';
import Loader from '../../common/component/loader';
import CenteredFullScreen from '../../common/component/centered-full-screen';
import GoHomeButton from '../../error/component/go-home-button';
import { Typography } from '@material-ui/core';
import { useLoadPermanentDataForCustomer, useLoadPermanentDataForDistributor, useLoadPermanentDataForVayanData } from '../../customers/actions';
import UserConsentsController from './user-consents-controller';
import { ONBOARDING_COMPLETED } from '../../companies/constants';
import UnderReviewScreen from './under-review-screen';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        width: '100%',
    }
}));

const useLoading = (defaultState = true) => {
    const [loading, setLoading] = useState(defaultState);
    const [error, setError] = useState(false);
    return { loading, setLoading, error, setError };
}

const LoadingScreen = (props) => {

    const company = useMainCompany();
    const { t } = useTranslation('translation', { keyPrefix: 'app.loading-screen' })

    return (
        <CenteredFullScreen>
            <Loader message={t('loading-clients', { name: company.payload.name })} />
        </CenteredFullScreen>
    )
}

const ErrorScreen = (props) => {
    return (
        <CenteredFullScreen>
            <Typography>An error occurs.</Typography>
            <GoHomeButton variant='outlined'></GoHomeButton>
        </CenteredFullScreen>
    )
}

const ApplicationStartingPointForVayanData = (props) => {

    const { loading, setLoading, error, setError } = useLoading();
    useLoadPermanentDataForVayanData(setLoading, setError);

    if (error) {
        return <ErrorScreen />
    }

    if (loading) {
        return <LoadingScreen {...props} />
    }

    return (
        <Workbench />
    );
}

const ApplicationStartingPointForDistributor = (props) => {
    const { loading, setLoading, error, setError } = useLoading();

    useLoadPermanentDataForDistributor(setLoading, setError);

    if (error) {
        return <ErrorScreen />
    }

    if (loading) {
        return <LoadingScreen {...props} />
    }

    return <Workbench />;
}

const ApplicationStartingPointForCustomer = (props) => {
    const { loading, setLoading, error, setError } = useLoading(false);

    useLoadPermanentDataForCustomer(setLoading, setError);

    if (error) {
        return <ErrorScreen />
    }

    if (loading) {
        return <LoadingScreen {...props} />
    }

    return (
        <Workbench />
    );
}

const ApplicationStartingPoint = (props) => {

    const classes = useStyles();
    const title = "Active Licensing";

    const company = useMainCompany();
    const isVayanData = useCompanyIsVayanData();
    const isDistributor = useCompanyIsDistributor();

    const child =
        isVayanData ? ApplicationStartingPointForVayanData :
            company?.payload?.onboarding_status !== ONBOARDING_COMPLETED ? UnderReviewScreen :
                isDistributor ? ApplicationStartingPointForDistributor :
                    ApplicationStartingPointForCustomer;

    return (
        <div className={classes.root}>
            <ApplicationLoadingBar />
            <Header title={title} />
            {React.createElement(child, props)}
        </div>
    );
}

const App = (props) => {

    let { url } = useRouteMatch();
    url = url.endsWith('/') ? url.slice(0, -1) : url;

    return (
        <UserConsentsController>
            <Switch >
                <Route path={`${url}/l`} render={() => (
                    <UserRelationProvider>
                        <ApplicationStartingPoint {...props} />
                    </UserRelationProvider>
                )} />
                <Route exact path={url} render={() => (<Redirect to={`${url}/l`} />)} />
                <Route /*no match*/ component={NoMatch} />
            </Switch>
        </UserConsentsController>
    )
}

export default App;