
import React, { Fragment, useState } from 'react';
import _ from 'lodash';
import { TableContainer, Table, TableHead, Paper, TableCell, TableRow, TableBody, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useOrdersLinesByProductAndPrice } from '../../../selectors';
import { useProductName } from '../../../../products/component/element/product-title';
import { useFindProductById } from '../../../../products/selector';
import OrderTotalTable from './order-total-table';
import OrderInnerTable from './order-inner-table';

const useStyles = makeStyles(theme => ({
    head: {
        backgroundColor: theme.palette.background.default
    }
}));

function OrderProductTable({ product, prices, ...props }) {

    const classes = useStyles();
    product = useFindProductById(product);

    return (
        <Fragment>
            <TableHead>
                <TableRow className={classes.head}>
                    <TableCell colSpan={12} align="left">
                        {useProductName(product)}
                    </TableCell>
                </TableRow>
            </TableHead>
            <OrderInnerTable product={product} prices={prices} {...props} />
        </Fragment>
    );
}


function OrderTable({ order }) {

    const byProducts = useOrdersLinesByProductAndPrice(order);

    const [total, setTotal] = useState(0);
    const addToTotal = (price) => setTotal(total => total + price);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="order products">
                        {
                            _.map(byProducts, (prices, product) => (
                                <OrderProductTable key={product} product={product} prices={prices} order={order} addToTotal={addToTotal} />
                            ))
                        }
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
                <TableContainer component={Paper}>
                    <Table aria-label="order total">
                        <TableBody>
                            <OrderTotalTable total={total} />
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid >

    )
}

export default OrderTable;
