import React from 'react';
import CenteredFullScreen from '../../common/component/centered-full-screen';
import { Typography } from '@material-ui/core';

const UnderReviewScreen = () => {
    return (
        <CenteredFullScreen>
            <Typography>The company is being reviewed by an administrator. Please come back later.</Typography>
        </CenteredFullScreen>
    )
}

export default UnderReviewScreen;