import React, { useCallback } from 'react';
import _ from 'lodash';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { billingEmailSchema } from '../fields/company-billing-email-field';
import { taxIdSchema } from '../fields/company-tax-id-field';
import { useTranslation } from 'react-i18next';

export const defaultValue = {
    tax_id_type: '',
    tax_id_value: '',
    billing_email: '',
    currency: '',
    metadata: {},
}

export const schema = (t) => Yup.object().shape({
    ...billingEmailSchema(t),
    ...taxIdSchema(t),
    metadata: Yup.array().of(Yup.object().shape({
        key: Yup.string().required(t('metadata-key-validation')),
        value: Yup.string().required(t('metadata-value-validation'))
    })),
})

export default function BillingFormBaseCtrl(props) {
    const { component, onSubmit, company, ...innerProps } = props;
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.forms.billing-form.controllers.index' });
    
    const handleSubmit = useCallback((values, bag) => {
        if (onSubmit) {
            const copy = _.cloneDeep(values);
            copy.metadata = _.reduce(copy.metadata, (acc, { key, value }) => {
                acc[key] = value;
                return acc;
            }, {});
            return onSubmit(copy, bag);
        } else {
            return bag.setSubmitting(false);
        }
    }, [onSubmit]);

    if (company.payload) {
        return (
            <Formik
                initialValues={{
                    ...defaultValue,
                    ..._.pick(company.payload, ['currency', 'tax_id_type', 'tax_id_value', 'billing_email']),
                    metadata: _.map(company.payload.metadata || defaultValue.metadata, (value, key) => ({ key, value })),
                }}
                onSubmit={handleSubmit}
                validationSchema={schema(t)}
            >
                {(props) => React.createElement(component, { ...innerProps, company, ...props })}
            </Formik >
        );
    } else {
        return React.createElement(component, { ...innerProps, company, isSubmitting: true });
    }
}
