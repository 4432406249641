import React from 'react';
import { Typography } from '@material-ui/core';

export function orderLinePeriodEndString(line) {
    if (line.payload?.period.end) {
        const end = new Date(line.payload.period.end);
        return end.toLocaleString()
    } else {
        return '';
    }
}

function OrderLinePeriodEnd({ line, ...props }) {
    if (line.payload?.period.end) {
        return (
            <Typography {...props}>
                {orderLinePeriodEndString(line)}
            </Typography>
        )
    } else {
        return null;
    }
}

export default OrderLinePeriodEnd;
