import React, { useMemo } from 'react';
import _ from 'lodash';
import { TableController } from '../../../../common/component/table/table-controller';
import OrderStatus from '../../element/order-status';
import OrderLinesCount from '../../element/order-lines-count';
import OrderPaymentReceived from '../../element/order-payment-received';
import OrderName from '../../element/order-name';
import OrderCustomerName from '../../element/order-customer-name';
import { useViewingAsDistributor } from '../../../../customers/selectors';
import { useTranslation } from 'react-i18next';
import OrderNumber from '../../element/order-number';
import OrderPurchaseOrder from '../../element/order-purchase_order';
import OrderLabel from '../../element/order-label';

function OrderHistoryTableController({ pageSize = 8, orders, ...more }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.details.order-history.header' })
    const viewAsDistributor = useViewingAsDistributor();

    const columns = useMemo(() => _.compact([
        {
            title: t('number'), field: 'payload.order_number', editable: 'never',
            render: order => <OrderNumber order={order} />,
        },
        {
            title: t('date'), field: 'payload.created_at', editable: 'never',
            render: order => <OrderName order={order} />,
        },
        viewAsDistributor ? {
            title: t('customer'), field: 'payload.customer', editable: 'never',
            render: order => <OrderCustomerName order={order} />,
        } : undefined,
        {
            title: t('purchase_order'), field: 'payload.purchase_order', editable: 'never',
            render: order => <OrderPurchaseOrder order={order} />,
        },
        {
            title: t('label'), field: 'payload.label', editable: 'never',
            render: order => <OrderLabel order={order} />,
        },
        {
            title: t('references'), field: 'payload.lines', editable: 'never',
            render: order => <OrderLinesCount order={order} />,
        },
        {
            title: t('status'), field: 'payload.status', editable: 'never',
            render: order => <OrderStatus order={order} />,
        },
        viewAsDistributor ? {
            title: t('payment-received'), field: 'payload.distributor.receive_payment', editable: 'never',
            render: order => <OrderPaymentReceived order={order} />,
        } : undefined
    ]), []);

    const innerProps = {
        columns,
        data: orders,
        pageSize,
        ...more
    }

    return <TableController {...innerProps} />;
}



export function OrderHistoryTable({ onClick, ...props }) {
    return (
        <OrderHistoryTableController
            {...props}
            search refresh
            onRowClick={(ev, order, togglePanel) => {
                ev.preventDefault();
                ev.stopPropagation();
                onClick(order);
            }}
        />
    )
}

