import React, { } from 'react';
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    pending: {
        verticalAlign: 'middle',
    },
}));


export function ProceedPending(props) {
    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.order-lines.sumup.check' });
    return (
        <span>
            <Typography display='inline'>
                {t('pending')}
            </Typography>
            <HourglassEmptyOutlinedIcon className={classes.pending} />
        </span>
    );
}
