import React, { Fragment, forwardRef, useCallback, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { useAuth0 } from '../../../../lib/auth0-wrapper';
import { useNotif } from '../../../common/component/notification';
import { useImpersonateSdk } from '../../../../lib/licensing-sdk';
import { useDispatch } from 'react-redux';
import ActionOk from '../../../common/component/notification/action-ok';
import InteractiveLoadingButton from '../../../common/component/interactive-loading-button';
import { useCustomer } from '../../../customers/component/customer-provider';
import { useIsManager } from '../../../relations/selectors';
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core';
import { useReferenceById } from '../../../references/selector';
import { useReferenceName } from '../../../references/component/element/reference-name';
import { useOrderLineById } from '../../../order-lines/selector';
import SessionEndDateString from '../element/session-end-date-string';
import date from 'date-and-time';
import { useTranslation } from 'react-i18next';

const RECURRING = 'recurring';
const DAYS = 'days';
const MONTHS = 'months';
const YEARS = 'years';
const LIFETIME = 3153600000 * 1000 // 100 years

const useNewExpirationDate = (line, reference) => {
    return useMemo(() => {
        line = line.payload;
        reference = reference.payload;

        let startDate = Date.now();
        let endDate;
        if (line.type === RECURRING) {
            if (startDate < line.period.start) startDate = line.period.start; // cannot start a session before the order line subscription.
            endDate = new Date(line.period.end); // cannot end a session after the order line subcription.                
        } else {
            endDate = new Date(startDate + LIFETIME);
        }
        startDate = new Date(startDate);

        const duration = reference.sessions.duration;
        if (duration > 0) {
            let wantedEndDate = endDate;

            switch (reference.sessions.unit) {
                case DAYS:
                    wantedEndDate = date.addDays(startDate, duration)
                    break;
                case MONTHS:
                    wantedEndDate = date.addMonths(startDate, duration);
                    break;
                case YEARS:
                    wantedEndDate = date.addYears(startDate, duration);
                    break;
                default:
                    throw new Error('Database error');
            }

            if (wantedEndDate > endDate) {
                wantedEndDate = endDate; // the wantedEndDate cannot be higher than the period or lifetime
            }

            endDate = wantedEndDate;
        }

        return new Date(endDate);
    }, [line, reference]);
}

const useStyles = makeStyles(() => ({
    flex: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
}))

export function RenewSessionModal({ open, onClose, session, line, reference, onClick, ...props }) {

    const classes = useStyles();

    const { t } = useTranslation('translation', { keyPrefix: 'app.sessions.actions.renew.modal' })
    const referenceName = useReferenceName(reference);
    const newExpiration = useNewExpirationDate(line, reference);

    if (open) {
        return (
            <Dialog fullWidth maxWidth='md' open={open} onClose={onClose}>
                <DialogTitle>
                    <Typography display='inline'>{t('title', { referenceName })}</Typography>
                </DialogTitle>
                <DialogContent className={classes.flex}>
                    <Typography>
                        <SessionEndDateString session={session} />
                    </Typography>
                    <Typography>
                        {t('license-validation', { date: newExpiration.toLocaleString() })}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} >
                        {t('cancel')}
                    </Button>
                    <Button onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        onClick();
                        onClose();
                    }} color='secondary'>
                        {t('confirm')}
                    </Button>
                </DialogActions>
            </Dialog >
        );
    } else {
        return null;
    }

}


export const RenewSessionView = forwardRef(({ ...props }, ref) => {

    const { t } = useTranslation('translation', { keyPrefix: 'app.sessions.actions.renew' });
    const [open, setOpen] = useState(false);
    const handleOpen = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setOpen(true);
    }
    const handleClose = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(false);
    }

    return (
        <Fragment>
            <Button onClick={handleOpen} color='secondary' {...props} ref={ref}>
                {t('button-label')}
            </Button>
            <RenewSessionModal {...props} open={open} onClose={handleClose} />
        </Fragment>
    )
});

export function RenewSessionController({ className, component, ...props }) {
    const { session } = props;

    const dispatch = useDispatch();
    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const notif = useNotif();

    const { t } = useTranslation('translation', { keyPrefix: 'app.sessions.actions.renew.tooltip' });
    const customer = useCustomer();

    const line = useOrderLineById(session.payload.line);
    const reference = useReferenceById(line.payload.reference);

    const loading = session.status.includes('pending');
    const erred = session.status.includes('error');
    const disabled = loading || erred;
    const tooltip =
        loading ? t('loading') :
            erred ? t('error') :
                t('default');

    props.line = line;
    props.reference = reference;
    props.disabled = disabled;
    props.onClick = useCallback((ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        const payload = {
            user: session.payload.user,
            device: session.payload.device
        };

        dispatch(sdk.sessions.use(auth, payload, session.target, customer.target))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            });
    }, [sdk, auth, session]);

    if (!reference) {
        return null;
    }

    if (reference.payload.sessions.duration === 0) {
        return null;
    }

    return (
        <InteractiveLoadingButton className={className} tooltip={tooltip} loading={loading} >
            {React.createElement(component, props)}
        </InteractiveLoadingButton>
    )
}

export default function RenewSessionButton({ session, ...props }) {

    const isManager = useIsManager();
    if (!isManager) {
        return null;
    }

    return (
        <WaitingLoaded {...props} data={session} render={() =>
            <RenewSessionController {...props} session={session} component={RenewSessionView} />
        } />
    )
}

