
export const ALWAYS_INVOICE = 'always_invoice';
export const CREATE_PRORATIONS = 'create_prorations';
export const NONE = 'none';
export const PRORATION_BEHAVIOR = [ALWAYS_INVOICE, CREATE_PRORATIONS, NONE];

export const BILLING_SCHEME_PER_UNIT = 'per_unit';
export const BILLING_SCHEME_TIERED = 'tiered';
export const BILLING_SCHEME_LIST = [BILLING_SCHEME_PER_UNIT, BILLING_SCHEME_TIERED];

export const BILLING_TIERS_MODE_PER_UNIT = 'per_unit'; // Do not use tier mode
export const BILLING_TIERS_MODE_GRADUATED = 'graduated'; // Use all the tiers to define the price in a cumulative manner
export const BILLING_TIERS_MODE_VOLUME = 'volume'; // Use the greatest value to compute the price unit amount.
export const BILLING_TIERS_MODE_LIST = [BILLING_TIERS_MODE_PER_UNIT, BILLING_TIERS_MODE_GRADUATED, BILLING_TIERS_MODE_VOLUME];

export const ONE_TIME = 'one-time'; // The payment will occur only one time.
export const RECURRING = 'recurring'; // The payment will occur every {interval_in_month} month.
export const PRICE_TYPES = [ONE_TIME, RECURRING];

export const DAYS = 'days';
export const MONTHS = 'months';
export const YEARS = 'years';
export const RECURRING_UNITS = [DAYS, MONTHS, YEARS];