import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useField, FieldArray, Field } from 'formik';
import AddIcon from '@material-ui/icons/Add';
import TrashIcon from '@material-ui/icons/Delete';
import { TextField } from 'formik-material-ui';

const useStyles = makeStyles(theme => ({
    button: {
        textAlign: 'center'
    }
}));

const CompanyMetadataField = ({ namespace, disabled, isSubmitting }) => {

    const classes = useStyles();
    const name = `${namespace ? namespace + '.' : ''}metadata`;
    const [field, meta, helpers] = useField(name);

    return (
        <Grid container spacing={1}>
            <FieldArray
                name={name}
                render={arrayHelpers => (
                    <>
                        {field.value.map((key, index) => (
                            <React.Fragment key={index} >
                                <Grid item xs={5}>
                                    <Field
                                        component={TextField}
                                        fullWidth
                                        name={`${name}.${index}.key`}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <Field
                                        component={TextField}
                                        fullWidth
                                        name={`${name}.${index}.value`}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton
                                        disabled={disabled || isSubmitting}
                                        onClick={(ev) => {
                                            ev.stopPropagation();
                                            ev.preventDefault();
                                            arrayHelpers.remove(index)
                                        }}
                                    >
                                        <TrashIcon />
                                    </IconButton>
                                </Grid>
                            </React.Fragment>
                        ))}
                        <Grid item xs={12} className={classes.button} >
                            <IconButton
                                disabled={disabled || isSubmitting}
                                onClick={(ev) => {
                                    ev.stopPropagation();
                                    ev.preventDefault();
                                    arrayHelpers.insert(field.value.length, { key: '', value: '' })
                                }}>
                                <AddIcon />
                            </IconButton>
                        </Grid >
                    </>
                )} />
        </Grid >
    )
}

export default CompanyMetadataField;
