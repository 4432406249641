
export const UNTRUSTED = 'untrusted';
export const TRUSTED = 'trusted';
export const TRUST_POLICIES = [UNTRUSTED, TRUSTED];

export const INVOICE_FOR_EACH_PURCHASE = 'for_each_purchase';
export const INVOICE_MANUALLY = 'manually';
export const INVOICE_POLICIES = [INVOICE_FOR_EACH_PURCHASE, INVOICE_MANUALLY];


export const GRADE_CUSTOMER = 'customer';
export const GRADE_DISTRIBUTOR = 'distributor';
export const GRADE_VAYAN_DATA = 'vayan_data';
export const GRADES = [GRADE_CUSTOMER, GRADE_DISTRIBUTOR, GRADE_VAYAN_DATA];

export const ONBOARDING_PENDING = 'pending';
export const ONBOARDING_COMPLETED = 'completed';
export const ONBOARDING_STATUS = [ONBOARDING_PENDING, ONBOARDING_COMPLETED];
