import React, { useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { useImpersonateSdk } from '../../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../../lib/auth0-wrapper';
import ActionOk from '../../../../common/component/notification/action-ok';
import { useNotif } from '../../../../common/component/notification';
import { useCustomer } from '../../../../customers/component/customer-provider';
import { useViewingAsVayanData } from '../../../../customers/selectors';
import { useTranslation } from 'react-i18next';

export default function SendInvoice({ invoice, ...props }) {

    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const notif = useNotif();
    const dispatch = useDispatch();
    const { t } = useTranslation('translation', { keyPrefix: 'app.invoices.actions.vayandata.send' });
    const isAdmin = useViewingAsVayanData();

    const customer = useCustomer();
    const [loading, setLoading] = useState(false);

    const onClick = useCallback((ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setLoading(true);
        dispatch(sdk.invoices.send(auth, invoice.target, customer.target))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            })
            .finally(() => setLoading(false));

    }, [invoice, customer]);

    const cannotSend = ['draft', 'paid', 'uncollectible', 'void'].includes(invoice.payload.status);


    if (!isAdmin) return null;
    if (cannotSend) return null;

    return (
        <Button
            {...props}
            disabled={loading}
            color='primary'
            size='small'
            onClick={onClick}
        >{t('button-label')}</Button>
    )
}

