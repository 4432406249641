import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import CompanyTaxIdField from '../forms/billing-form/fields/company-tax-id-field';
import { useTranslation } from 'react-i18next';

export function taxIdInitialValues() {
    return {
        tax_id_type: 'fr_vat',
        tax_id_value: ''
    }
}

export default function TaxIdFormForCreation(props) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.create-company-modal.create-company-tax-id' })
    return (
        <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
                <Typography>{t('title')}</Typography>
            </Grid>
            <Grid item xs={9}>
                <CompanyTaxIdField {...props} />
            </Grid>
        </Grid>
    )
}
