
export const STATUS_IDLE = 'idle';
export const STATUS_AWAITING_PAYMENT = 'awaiting-payment';
export const STATUS_ACCEPTED_BY_DISTRIBUTOR = 'accepted-by-distributor';
export const STATUS_ACCEPTED_BY_ADMIN = 'accepted-by-admin';
export const STATUS_PAID = 'paid';
export const STATUS_CANCELLED = 'cancelled';
export const STATUS_STOPPED = 'stopped';
export const STATUS_UNPAID = 'unpaid';
export const STATUS_REVOKED = 'revoked';
export const STATUS_LIST = [STATUS_IDLE, STATUS_AWAITING_PAYMENT, STATUS_ACCEPTED_BY_DISTRIBUTOR, STATUS_ACCEPTED_BY_ADMIN, STATUS_PAID, STATUS_UNPAID, STATUS_STOPPED, STATUS_CANCELLED, STATUS_REVOKED];

