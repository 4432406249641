import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { getLabel } from '../../../devices/component/element/device-label';
import { getHostId } from '../../../devices/component/element/device-winid';

const useStyles = makeStyles({
    label: {
        fontSize: 12,
    }
});


export function DeviceRowHead({ device }) {
    const classes = useStyles();
    return (
        <Box>
            <Typography>
                {getHostId(device)}
            </Typography>
            <Typography className={classes.label}>
                {getLabel(device)}
            </Typography>
        </Box>
    )
}
