import React from 'react';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, makeStyles, Typography } from '@material-ui/core';
import { useIsViewingMainCustomer } from '../../../customers/selectors';
import { useProductListForCustomerAndCompany } from '../../selector';
import _ from 'lodash';
import ProductConfigurationCard from './product-configuration-card';
import { useTranslation } from 'react-i18next';

function ProductConfigurationList({ ...props }) {

    const products = useProductListForCustomerAndCompany();

    return (
        <Grid container spacing={2}>
            {_.map(products, product => (
                <Grid key={product.target} item xs={12}>
                    <ProductConfigurationCard {...props} product={product} />
                </Grid>
            ))}
        </Grid>
    )

}

const useStyles = makeStyles(theme => ({
    noBackground: {
        '&.MuiPaper-root': {
            backgroundColor: 'inherit'
        }
    }
}))

export default function ProductConfigurationPage({ ...props }) {

    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.products.configuration.page' });
    const isMainView = useIsViewingMainCustomer();

    if (isMainView) return null;

    return (
        <Grid item xs={12} >
            <ExpansionPanel className={classes.noBackground} elevation={0} defaultExpanded={true}>
                <ExpansionPanelSummary>
                    <Typography variant='h4'>{t('title')}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <ProductConfigurationList {...props} />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </Grid>
    )
}
