import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import CustomersOrders from '../../customers/component/customer-orders';
import { useCustomerIsDistributor, useCustomerIsVayanData } from '../selectors';
import AwaitingValidationCustomersListPage from './awaiting-validation/list-page';
import AwaitingValidationCustomersDetailPage from './awaiting-validation/customer-details';

function CustomerLayout(props) {

    let { url } = useRouteMatch();
    const isDistributor = useCustomerIsDistributor();
    const isVayanData = useCustomerIsVayanData();

    if (isDistributor) {
        return (
            <Switch>
                <Route path={`${url}/orders`} render={() => <CustomersOrders />} />
            </Switch>
        )
    } else if (isVayanData) {
        return (
            <Switch>
                <Route path={`${url}/awaiting-validation/:vid`} render={() => <AwaitingValidationCustomersDetailPage />} />
                <Route exact path={`${url}/awaiting-validation`} render={() => <AwaitingValidationCustomersListPage />} />
            </Switch>
        )
    } else {
        return null;
    }
}

export default CustomerLayout;
