import React from 'react';
import {
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import { Memory as DeviceIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useSidebarSelected } from '../../common/sidebar';

function DeviceSidebarButton({ onClick, selected, ...props }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.devices.sidebar-button' })
    const sidebarClasses = useSidebarSelected(selected);
    return (
        <div className={sidebarClasses.sidebarItem}>
            <ListItem  {...props} button onClick={onClick}>
                <ListItemIcon><DeviceIcon className={sidebarClasses.icon} /></ListItemIcon>
                <ListItemText
                    primary={t('label')} />
            </ListItem >
        </div>
    )

}

export default DeviceSidebarButton;
