import Resource from '../resource';
import _ from 'lodash';
import axios from 'axios';

export default class InvoiceResource extends Resource {

    constructor(resource, uri, pathInState) {
        super(resource, uri, pathInState);
        this.post = undefined;
        this.put = undefined;
        this.remove = undefined;
        this.count = undefined;
    }

    list(auth, customer, params = {}, force = false) {

        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.invoices`);

            const pending = state.allIds.status && state.allIds.status.includes('pending');

            // dont do anything if operation is pending.
            if (!force && pending) {
                return null;
            }

            dispatch(this._makePendingPayload('list', null));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'get',
                    baseURL: this.uri,
                    url: `c/${customer}/invoices`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params
                });
                dispatch(this._makeSuccessPayload('list', res.data.data, null));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('list', error, null, flag));
                throw error;
            }
        }
    }

    listForOrderline(auth, orderline, customer, params = {}, force = false) {

        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.invoices`);

            const pending = state.allIds.status && state.allIds.status.includes('pending');

            // dont do anything if operation is pending.
            if (!force && pending) {
                return null;
            }

            dispatch(this._makePendingPayload('list', null));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'get',
                    baseURL: this.uri,
                    url: `c/${customer}/invoices/orderlines/${orderline}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params
                });
                dispatch(this._makeSuccessPayload('list', res.data.data, null));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('list', error, null, flag));
                throw error;
            }
        }
    }

    get(auth, identifier, customer, force = false) {
        return async (dispatch, getState) => {

            const state = _.get(getState(), `${this.path}.invoices`);

            const existing = state.byId[identifier];
            const pending = existing && existing.status.endsWith('pending');
            const hasErred = existing && existing.status.endsWith('error');

            // dont do anything if operation is pending or already exists.
            // The update can be forced if its existing
            if ((!force && (existing && !hasErred)) || pending) {
                if (existing && existing.payload) return existing.payload;
                return null;
            }

            dispatch(this._makePendingPayload('get', identifier));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'get',
                    baseURL: this.uri,
                    url: `c/${customer}/invoices/${identifier}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: {},
                });
                dispatch(this._makeSuccessPayload('get', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('get', error, identifier, flag));
                throw error;
            }
        }
    }


    finalize(auth, identifier, customer) {

        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.${this.resource}`);

            const existing = !!state.byId[identifier];
            const pending = existing && state.byId[identifier].status.includes('pending');

            // dont do anything if operation is pending.
            if (pending) {
                return null;
            }

            dispatch(this._makePendingPayload('post', identifier));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `/c/${customer}/invoices/${identifier}/finalize`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, identifier, flag));
                throw error;
            }
        }
    }

    pay(auth, identifier, customer) {

        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.${this.resource}`);

            const existing = !!state.byId[identifier];
            const pending = existing && state.byId[identifier].status.includes('pending');

            // dont do anything if operation is pending.
            if (pending) {
                return null;
            }

            dispatch(this._makePendingPayload('post', identifier));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `/c/${customer}/invoices/${identifier}/pay`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, identifier, flag));
                throw error;
            }
        }
    }

    send(auth, identifier, customer) {

        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.${this.resource}`);

            const existing = !!state.byId[identifier];
            const pending = existing && state.byId[identifier].status.includes('pending');

            // dont do anything if operation is pending.
            if (pending) {
                return null;
            }

            dispatch(this._makePendingPayload('post', identifier));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `/c/${customer}/invoices/${identifier}/send`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, identifier, flag));
                throw error;
            }
        }
    }

    paid(auth, identifier, customer) {

        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.${this.resource}`);

            const existing = !!state.byId[identifier];
            const pending = existing && state.byId[identifier].status.includes('pending');

            // dont do anything if operation is pending.
            if (pending) {
                return null;
            }

            dispatch(this._makePendingPayload('post', identifier));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `/c/${customer}/invoices/${identifier}/paid`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, identifier, flag));
                throw error;
            }
        }
    }

    generate(auth, customer) {

        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.${this.resource}`);
            const pending = state.insert.status.includes('pending');

            if (pending) {
                return null;
            }

            dispatch(this._makePendingPayload('put', null));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'put',
                    baseURL: this.uri,
                    url: `/c/${customer}/invoices/generate`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                dispatch(this._makeSuccessPayload('put', res.data.data, null));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('put', error, null, flag));
                throw error;
            }
        }
    }

    renewSubscriptions(auth) {
        return async (dispatch, getState) => {
            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'put',
                    baseURL: this.uri,
                    url: `/invoices/renew-subscriptions`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                return res.data.data || null;
            } catch (error) {
                this._catchUnpredictedError(error, dispatch);
                throw error;
            }
        }
    }

}