import React, { useCallback, useState } from 'react';
import { FormControlLabel, List, ListItem, ListItemSecondaryAction, ListItemText, Switch } from '@material-ui/core';
import _ from 'lodash';
import { useSdk } from '../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../lib/auth0-wrapper';
import { useDispatch } from 'react-redux';
import { useNotif } from '../../../common/component/notification';
import { usePricesByReferenceForCompany, usePriceForCustomer } from '../../../prices/selector';
import { useMainCompany } from '../../../relations/component/user-relation-provider';
import ActionOk from '../../../common/component/notification/action-ok';
import { useCustomer } from '../../../customers/component/customer-provider';
import { useViewingAsVayanData } from '../../../customers/selectors';
import { usePriceName } from '../../../prices/prices-utilities';
import { RECURRING } from '../../../prices/price-constants';
import { usePriceReccuringInterval } from '../../../prices/component/element/price-recurring-interval';

const useHandleChange = () => {
    const sdk = useSdk();
    const auth = useAuth0();
    const dispatch = useDispatch();
    const notif = useNotif();

    const isVayanData = useViewingAsVayanData();
    const company = useMainCompany();
    const customer = useCustomer();

    const [loading, setLoading] = useState(false);
    const handleChange = useCallback((ev, price) => {
        setLoading(true);

        if (ev.target.checked) {
            const imp = isVayanData ?
                sdk.impersonateByVayanData(company.target) :
                sdk.impersonateByDistributor(company.target);

            dispatch(imp.customers.putPrice(auth, price.target, customer.target))
                .catch((e) => {
                    notif.error(e, { persist: true, action: (key) => <ActionOk notif={notif} handle={key} /> })
                })
                .finally(() => setLoading(false));
        } else {
            const imp = isVayanData ?
                sdk.impersonateByVayanData(company.target) :
                sdk.impersonateByDistributor(company.target);

            dispatch(imp.customers.removePrice(auth, price.target, customer.target))
                .catch((e) => {
                    notif.error(e, { persist: true, action: (key) => <ActionOk notif={notif} handle={key} /> })
                })
                .finally(() => setLoading(false));
        }

    }, []);

    return [loading, handleChange];
}

export function ProductConfigurationPriceListItem({ price, ...props }) {

    const customerPrice = usePriceForCustomer(price.target);
    const name = usePriceName(price);
    const recurring = price.payload.type === RECURRING;
    const message = usePriceReccuringInterval(price);

    //const [loading, handleChange] = useHandleChange();

    const checked = customerPrice !== undefined; // checked if the customer has the price.

    return (
        <ListItem>
            <ListItemText inset primary={name} secondary={recurring ? `Recurrent - ${message}`: `One time payment`} />
            <ListItemSecondaryAction>
                <FormControlLabel
                    control={
                        <Switch
                            edge="end"
                            checked={checked}
                            //onChange={(ev) => handleChange(ev, price)}
                        />
                    }
                />
            </ListItemSecondaryAction>
        </ListItem>
    )
}

export default function ProductConfigurationPricesList({ reference, ...props }) {

    const prices = usePricesByReferenceForCompany(reference.target);

    return _.map(prices, price => <ProductConfigurationPriceListItem
        key={price.target} price={price} />);
}
