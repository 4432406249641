import React from 'react';
import { Grid } from '@material-ui/core';
import CompanyBillingFormPanel from '../../companies/component/forms/billing-form/controllers/change';
import CompanyPaymentMethodFormPanel from '../../companies/component/forms/payment-method-form';
import InvoicesListInPanel from '../../invoices/component';
import { useCustomerIsVayanData } from '../../customers/selectors';


const AccountBilling = () => {
    const isVayanData = useCustomerIsVayanData();

    if (isVayanData) return null

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <CompanyBillingFormPanel defaultExpanded />
            </Grid>
            <Grid item xs={12}>
                <CompanyPaymentMethodFormPanel defaultExpanded />
            </Grid>
            <Grid item xs={12}>
                <InvoicesListInPanel defaultExpanded />
            </Grid>
        </Grid>
    );
}

export default AccountBilling;