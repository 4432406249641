import Resource from '../resource';
import axios from 'axios';

export default class PaymentMethodResource extends Resource {

    constructor(resource, uri, pathInState) {
        super(resource, uri, pathInState);
        this.get = undefined;
        this.post = undefined;
        this.count = undefined;
    }

    createSetupIntent(auth, query) {
        return async () => {
            const token = await auth.getTokenSilently();
            const res = await axios({
                method: 'get',
                baseURL: this.uri,
                url: `/${this.resource}/setupintent`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                params: query,
            });
            return res.data.data || null;
        }
    }

    makeDefault(auth, identifier, query) {
        return async (dispatch) => {

            dispatch(this._makePendingPayload('post', identifier));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `/${this.resource}/${identifier}/default`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: query,
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, identifier, flag));
                throw error;
            }
        }
    }

}