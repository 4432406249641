import React from 'react';
import { Typography } from '@material-ui/core';
import { useCustomerInDb } from '../../selectors';
import { useTranslation } from 'react-i18next';

function CustomerGrade({ customer, capitalize, noCoefficient = false, ...props }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.elements.customer-grade' });
    customer = useCustomerInDb(customer);
    const grade = t(customer?.payload?.grade);
    const coefficient = customer?.payload?.coefficient;
    
    return (
        <Typography
            {...props}
            style={{ textTransform: capitalize ? 'capitalize' : 'inherit' }}
        >
            {grade} {!noCoefficient && `(${coefficient})`}
        </Typography>

    )
}

export default CustomerGrade;
