
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { CardContent, Card, CardHeader, CardActions, Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { OrderDateTime } from '../element/order-created-at';
import OrderTable from './table/order-table';
import OrderActions from './orders-actions';
import { useImpersonateSdk } from '../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../lib/auth0-wrapper';
import { useDispatch } from 'react-redux';
import { useNotif } from '../../../common/component/notification';
import ActionOk from '../../../common/component/notification/action-ok';
import OrderStatus from '../element/order-status';
import Skeleton from '@material-ui/lab/Skeleton/Skeleton';
import OrderNumber from '../element/order-number';
import ChangePurchaseOrder from '../actions/customer/change-purchase-order';
import ChangeOrderLabel from '../actions/customer/change-label';

const useStyles = makeStyles(theme => ({
    fullwidth: {
        width: '100%',
    },
    statusBadge: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    cardActions: {
        '& > :not(:first-child)': {
            marginLeft: 'initial'
        },
        '& > :not(:last-child)': {
            borderRight: `1px solid grey`
        },
        justifyContent: 'flex-end',
    }
}));

function useLoadLines(order) {
    const auth = useAuth0();
    const sdk = useImpersonateSdk();
    const dispatch = useDispatch();
    const notif = useNotif();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        Promise.all(order.payload.lines.map(line =>
            dispatch(sdk.orderlines.get(auth, line, order.payload.customer, true))
        ))
            .finally(() => setTimeout(() => setLoading(false), 1000))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            });
    }, [order.target]);

    return [loading];
}

function OrderCardLoading() {
    const classes = useStyles();

    return (
        <Card className={classes.fullwidth} >
            <CardHeader
                title={<Skeleton />}
            />
            <CardContent>
                <Skeleton height={300} />
            </CardContent>
            <CardActions>
                <Skeleton />
            </CardActions>
        </Card>
    )
}


function OrderCard({ order, onFinalize }) {
    const classes = useStyles();

    const [loading] = useLoadLines(order);

    if (!order.payload || loading) {
        return <OrderCardLoading />
    }

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid alignItems='center' container spacing={2}>
                            <Grid item container xs={3} spacing={1}>
                                <Grid item xs={12}><OrderNumber order={order} /></Grid>
                                <Grid item xs={12}><OrderDateTime order={order} /></Grid>
                                <Grid item xs={12}><OrderStatus variant='default' order={order} /></Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <ChangePurchaseOrder fullWidth order={order} />
                            </Grid>
                            <Grid item xs={5}>
                                <ChangeOrderLabel fullWidth order={order} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <OrderTable order={order} />
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Box display="flex" flexDirection="row" justifyContent="center">
                            <OrderActions order={order} onFinalize={onFinalize} />
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default OrderCard;
