import React, { useState, useCallback, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import {
    AppBar, Avatar, Toolbar, Link,
    Menu, MenuItem, Box, ButtonBase, MenuList, Divider
} from '@material-ui/core';
import { push } from 'connected-react-router';
import { useAuth0 } from '../../../lib/auth0-wrapper';
import { useSdk } from '../../../lib/licensing-sdk';
import { makeStyles } from '@material-ui/styles';
import { useNotif } from '../../common/component/notification';
import ActionDismiss from '../../common/component/notification/action-dismiss';
import { useChangeRelation } from '../../relations/component/user-relation-provider';
import CompanyName from '../../companies/component/elements/company-name';
import _ from 'lodash';
import { useUserRelationsList } from '../../relations/selectors';
import CompanyOnboarding from '../../companies/component/elements/company-onboarding';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    grow: {
        flexGrow: 1,
    },
    title: {
        display: 'block',
        textTransform: 'uppercase'
    },
    avatar: {
        color: theme.palette.getContrastText(theme.palette.background.default),
        backgroundColor: theme.palette.background.default
    },
    review: {
        fontSize: theme.typography.overline.fontSize,
        color: theme.palette.warning.main,
    }
}));


function OrganisationsMenu({ user, setAnchorEl }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const relations = useUserRelationsList(user.sub);
    const changeRelation = useChangeRelation();

    const handleClick = (relation) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        setAnchorEl(null);
        changeRelation(relation);
    }

    return (
        <MenuList dense>
            <MenuItem disabled>{t('app.application.app-bar-header.organisations')}</MenuItem>
            {_.map(relations, (relation) => {
                return (
                    <MenuItem key={relation.target} onClick={handleClick(relation.target)} >
                        <div>
                            <CompanyName company={relation.payload.target} />
                            <CompanyOnboarding className={classes.review} company={relation.payload.target} />
                        </div>
                    </MenuItem>
                )
            })}
        </MenuList>
    )
}

function ProfileActionsMenu({ auth, logout, setAnchorEl }) {

    const { t } = useTranslation();
    const sdk = useSdk();
    const dispatch = useDispatch();
    const notif = useNotif();
    const renewPassword = useCallback(() => {
        const redirect = window.location.origin + window.location.pathname;
        dispatch(sdk.users.renewPassword(auth, { redirect }))
            .then(url => {
                if (url) {
                    window.location.href = url;
                }
            })
            .catch(e => notif.error(e, { persist: true, action: (key) => <ActionDismiss notif={notif} handle={key} /> }));
    }, [sdk]);

    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb();
    }

    return (
        <MenuList dense>
            <MenuItem disabled>{t('app.application.app-bar-header.profile')}</MenuItem>
            <MenuItem onClick={handleClick(() => { setAnchorEl(null); renewPassword(); })}>{t('app.application.app-bar-header.renew-password')}</MenuItem>
            <MenuItem onClick={handleClick(() => { setAnchorEl(null); logout(); })}>{t('app.application.app-bar-header.logout')}</MenuItem>
        </MenuList>
    )
}

const UserMenu = ({ anchorEl, setAnchorEl, ...props }) => {

    const isMenuOpen = Boolean(anchorEl);

    return (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={() => setAnchorEl(null)}
        >
            <div>
                <OrganisationsMenu setAnchorEl={setAnchorEl} {...props} />
                <Divider />
                <ProfileActionsMenu setAnchorEl={setAnchorEl} {...props} />
            </div>
        </Menu>
    );
}

const UserAvatarInAppBar = ({ user }) => {
    const classes = useStyles();
    const letters = user ? user.nickname.slice(0, 2) : 'VD'

    return (
        <Avatar
            className={classes.avatar}>
            {letters.toUpperCase()}
        </Avatar>
    )
}

const UserInAppBar = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const auth = useAuth0();
    const { user, logout } = auth;

    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb(ev);
    }


    if (!user) {
        return null;
    } else {
        return (
            <Fragment>
                <ButtonBase onClick={handleClick((evt) => setAnchorEl(evt.currentTarget))}>
                    <Box>
                        <UserAvatarInAppBar user={user} />
                    </Box>
                </ButtonBase>
                <UserMenu auth={auth} user={user} logout={logout} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
            </Fragment >
        )
    }
}

const Header = (props) => {

    const { title } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    const goHome = useCallback((ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        dispatch(push('/'));
    }, []);

    return (
        <div className={classes.root}>
            <AppBar position='fixed' className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Link variant='h6' color='inherit' noWrap className={classes.title} onClick={goHome}>
                        {title}
                    </Link>
                    <div className={classes.grow} />
                    <UserInAppBar />
                </Toolbar>
            </AppBar>

        </div>
    );
}

export default Header;