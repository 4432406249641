import React from 'react';
import { Field } from 'formik';
import { Select } from 'formik-material-ui';
import { makeStyles } from '@material-ui/styles';
import regions from 'country-region-data';
import { MenuItem, FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const countries = _.map(regions, 'countryName');

const useStyles = makeStyles(theme => ({
    field: {
        width: '100%'
    }
}));

const CompanyCountryField = ({ namespace, errors, isSubmitting, ...props }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.forms.address-form.fields.country' });

    const classes = useStyles();
    const name = `${namespace ? namespace + '.' : ''}country`;
    const error = _.get(errors, name);

    return (
        <FormControl className={classes.field} error={Boolean(error)}>
            <InputLabel id="company-country-field">{t('label')}</InputLabel>
            <Field
                labelId="company-country-field"
                component={Select}
                name={name}
                disabled={isSubmitting}
                validate={value => countries.includes(value) ? undefined : t('validation')}
            >
                {countries.map(c => <MenuItem key={c} value={c}>{c}</MenuItem>)}
            </Field>
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    );
}

export default CompanyCountryField;
