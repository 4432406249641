import React from 'react';
import { Typography, Button, Grid } from '@material-ui/core';
import CenteredFullScreen from '../../common/component/centered-full-screen';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    spaced: {
        marginTop: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(2),
    },
}));

function Unauthorized(props) {

    const { goHome, disable } = props;
    const classes = useStyles();

    return (
        <CenteredFullScreen textAlign='center'>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant='h5' component='h3'>You are not authorized to access the licensing platform</Typography>
                    <Typography>Please contact the support for a detailed explanation.</Typography>
                </Grid>
                <Grid className={classes.spaced} item xs={12}>
                    <Button className={classes.button} disable={disable} variant='outlined' onClick={goHome}>Go Home</Button>
                </Grid>
            </Grid>
        </CenteredFullScreen>
    );

}


export default Unauthorized;