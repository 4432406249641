import React from 'react';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { Typography } from '@material-ui/core';
import { ResourceGetter } from '../../../../lib/licensing-sdk/getter';

function ProductDescription({ product, ...props }) {
    return (
        <ResourceGetter id={product} resource='products' render={({ product }) =>
            <WaitingLoaded data={product} render={() => (
                <Typography {...props}>
                    {product.payload.description.description ? product.payload.description.description : null}
                </Typography>)
            } />
        } />
    )
}

export default ProductDescription;
