import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const COLORS = {
    'draft': 'warning',
    'open': 'warning',
    'void': 'success',
    'paid': 'success',
    'uncollectible': 'error',
}

const useStyles = makeStyles((theme) => ({
    success: {
        color: theme.palette.success.main,
    },
    warning: {
        color: theme.palette.warning.main,
    },
    error: {
        color: theme.palette.error.main,
    }
}))

function Status({ invoice, ...props }) {
    const { status } = invoice.payload;
    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.invoices.elements.status' });
    const color = _.get(COLORS, status, 'error');
    return <Typography {...props} variant='overline' className={classes[color]}>{t(status)}</Typography>
}

export default function InvoiceStatus({ invoice, ...props }) {
    return (
        <Status invoice={invoice} {...props} />
    )
}

export function InvoiceStatusLabel({ }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.invoices.elements.status' });
    return (
        <Typography>{t('label')}</Typography>
    )
}
