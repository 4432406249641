import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid, CardContent, TableCell, TableRow, TableBody, Table, Divider } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

import PriceUnitPrice from '../../../prices/component/element/price-unit-price';
import { usePriceReccuringInterval } from '../../../prices/component/element/price-recurring-interval';
import { RECURRING } from '../../../prices/price-constants';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    dividerRight: {
        borderRight: `2px solid ${theme.palette.divider}`,
    },
}));

function ReferenceTypeContent({ reference, price }) {
    return (
        <Grid container>
            <Grid item xs={12}>
                { }
            </Grid>
        </Grid>

    )
}

function ReferencePrice({ price }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.references.card-content' })
    return (
        <TableRow>
            <TableCell>
                <Typography display='inline' variant='body2'>{t('price')}</Typography>
            </TableCell>
            <TableCell>
                <PriceUnitPrice variant='body2' price={price.target} />
            </TableCell>
        </TableRow>
    )
}

function ReferenceBilling({ price }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.references.card-content' })
    const interval = usePriceReccuringInterval(price)
    if (price.payload.type === RECURRING) {
        return (
            <TableRow>
                <TableCell></TableCell>
                <TableCell>
                    <Typography variant='body2'>
                        {t('billing-recurrent', { interval })}
                    </Typography>
                </TableCell>
            </TableRow>
        )
    } else {
        return (
            <TableRow>
                <TableCell></TableCell>
                <TableCell>
                    <Typography display='inline' variant='body2'>
                        {t('billing-one-time')}
                    </Typography>
                </TableCell>
            </TableRow>
        );
    }
}

function ReferencePriceContent({ price }) {

    return (
        <Table aria-label="reference price table">
            <TableBody>
                <ReferencePrice price={price} />
                <ReferenceBilling price={price} />
            </TableBody>
        </Table>
    )
}

export default function ReferenceContent({ ...props }) {

    const classes = useStyles();

    return (
        <CardContent>
            <Grid container spacing={2}>
                <Grid item xs={12} className={classes.dividerRight}>
                    <ReferenceTypeContent {...props} />
                </Grid>
                <Grid item xs={12}>
                    <ReferencePriceContent {...props} />
                </Grid>
            </Grid>
        </CardContent>
    )

}
