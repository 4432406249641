import React, { useMemo } from 'react';
import _ from 'lodash';
import { MTableBodyRow } from 'material-table';
import { TableRow, TableCell } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

export default function BodyRow(props) {

    let { data, ...innerProps } = props;
    let { actions, detailPanel, options } = innerProps;

    const haveActions = useMemo(() => _.find(actions, (a) => _.isFunction(a) || a.position !== 'toolbar'), [actions]);

    if (!data || !data.payload) {
        return <TableRow>
            {options.selection && <TableCell key={'expand'}></TableCell>}
            {detailPanel && <TableCell key={'expand'}></TableCell>}
            {props.columns.map((c, i) =>
                <TableCell key={i}>
                    <Skeleton />
                </TableCell>)
            }
            {haveActions && <TableCell key={'actions'}></TableCell>}
        </TableRow>
    } else if (data.status && data.status.endsWith('error')) {
        return (
            <TableRow>
                {options.selection && <TableCell key={'expand'}></TableCell>}
                {detailPanel && <TableCell key={'expand'}></TableCell>}
                {props.columns.map((c, i) =>
                    <TableCell key={i}>
                        An error occured, please refresh
                    </TableCell>)
                }
                {haveActions && <TableCell key={'actions'}></TableCell>}
            </TableRow>
        )
    } else {
        return <MTableBodyRow {...innerProps} disabled data={data} />
    }

}