import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

// const config = {
//     order: ['navigator', 'localStorage'],
//     caches: ['localStorage'],
//     fallbackLng: "en",
//     interpolation: {
//         escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
//     },
//     backend: {
//         loadPath: function (lng) {
//             return `${process.env.REACT_APP_TRANSLATION_BUCKET}/${lng}.json`
//         },
//         crossDomain: true
//     }
// }

const config = {
    resources: {
        en: {
            translation: require('./translations/en.json')
        },
        fr: {
            translation: require('./translations/fr.json')
        }
    },
    order: ['navigator', 'localStorage'],
    caches: ['localStorage'],
    fallbackLng: "en",
    interpolation: {
        escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
}

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init(config);
