import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            main: "#333333",
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: "#B10352"
        },
        common: {
            black: "#333333",
            white: "#FFFFFF",
        },
        text: {
            primary: "#333333",
            secondary: "#B10352",
            disabled: "#AEAEAE",
        },
        background: {
            default: "#EFEFEF",
            paper: "#FFFFFF"
        }
    },
    typography: {
        fontSize: 12,
        fontFamily: [
            'montserrat',
            'Roboto',
            'sans-serif'
        ].join(','),
    },
});