import React from 'react';
import { Typography } from '@material-ui/core';

function OrderPurchaseOrder({ order, ...props }) {
    const purchase_order = order.payload.purchase_order || '';

    return (
        <Typography {...props}>
            {purchase_order}
        </Typography>
    )
}

export default OrderPurchaseOrder;
