import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function CreationDate({ invoice, ...props }) {

    const { created } = invoice.payload;
    const date = useMemo(() => new Date(created * 1000), [created]);

    return (
        <Typography {...props}>
            {date.toLocaleString()}
        </Typography>
    )
}

export default function InvoiceCreationDate({ invoice, ...props }) {

    return (
        <CreationDate invoice={invoice} {...props} />
    )
}

export function InvoiceCreationDateLabel({ }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.invoices.elements.creation-date' });
    return (
        <Typography>{t('label')}</Typography>
    )
}