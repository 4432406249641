import _ from 'lodash';
import { combineReducers } from 'redux';
import Actions from '../actions';

const UserReducer = () => {

    const actions = new Actions('users');

    const get = actions['get'];
    const clear = actions['clear'];

    return combineReducers({
        byId: (state = {}, { type, payload, meta }) => {

            state = _.cloneDeep(state);

            switch (type) {
                case clear:
                    state = {};
                    break;
                case get.pending:
                    state = _.merge(state, { [meta.target]: { target: meta.target, status: 'get-pending', lastUpdate: meta.timestamp } });
                    break;
                case get.success:
                    state = _.merge(state, { [meta.target]: { error: null, payload, status: 'get-success', lastUpdate: meta.timestamp } });
                    break;
                case get.error:
                    state = _.merge(state, { [meta.target]: { error: payload.error, status: 'get-error', lastUpdate: meta.timestamp } });
                    break;
                default:
                    break;
            }

            return state;
        },
        allIds: (state = { status: 'idle', ids: [], error: null, count: 0 }, { type, payload, meta }) => {

            state = _.cloneDeep(state);

            switch (type) {
                case clear:
                    state = { status: 'idle', ids: [], error: null, count: 0 };
                    break;
                case get.success:
                    state = _.merge(state, { ids: _.union(state.ids, [meta.target]), error: null, status: 'get-success', lastUpdate: meta.timestamp });
                    break;
                default:
                    break;
            }

            return state;
        }
    });
}

export default UserReducer;