import React from 'react';
import Card from '@material-ui/core/Card';
import ReferenceContent from './reference-content';
import ReferenceCardHeader from './reference-card-header';

export default function ReferenceCard({ price, reference, actions }) {

    return (
        <Card>
            <ReferenceCardHeader reference={reference} price={price.target} />
            <ReferenceContent price={price} reference={reference} />
            {actions}
        </Card>
    );

}