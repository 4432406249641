import React from 'react';
import { Grid } from '@material-ui/core';
import ReferencesGrid from '../../../references/component/references-grid';

function ProductContent({ product }) {

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ReferencesGrid product={product} />
            </Grid>
        </Grid>
    )

}

export default ProductContent;
