import React, { useCallback, useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import CenteredFullScreen from '../../../common/component/centered-full-screen';
import { useNotif } from '../../../common/component/notification';
import { useSdk } from '../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../lib/auth0-wrapper';
import ActionOk from '../../../common/component/notification/action-ok';
import { useTranslation } from 'react-i18next';


function useHandleSubmit(onClose, vayandata) {
    const notif = useNotif();
    const sdk = useSdk();
    const auth = useAuth0();
    const dispatch = useDispatch();

    return useCallback((setFinish) => {

        const imp = sdk.impersonateByVayanData(vayandata.target);

        setFinish(false);
        dispatch(imp.invoices.renewSubscriptions(auth))
            .then(() => {
                onClose();
            })
            .catch(err => {
                const message = err.response?.data?.message || err.message;
                notif.error(message, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            })
            .finally(() => {
                setFinish(true);
            })

    }, []);
}

export default function RenewSubscriptionsModal({ open, onClose, customer, vayandata, ...props }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.renew-subscriptions.modal' })
    const [finish, setFinish] = useState(true);
    const handleSubmit = useHandleSubmit(onClose, vayandata);

    if (open) {
        return (
            <Dialog maxWidth='lg' open={open} onClose={onClose}>
                <DialogTitle>
                    <Typography display='inline'>{t('title')}</Typography>
                </DialogTitle>
                <DialogContent>
                    <CenteredFullScreen>
                        <Button disabled={!finish} onClick={onClose}>
                            {t('cancel')}
                        </Button>
                        <Button disabled={!finish} onClick={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();
                            handleSubmit(setFinish);
                        }} color='secondary' >
                            {t('confirm')}
                        </Button>
                    </CenteredFullScreen>
                </DialogContent>
            </Dialog >
        );
    } else {
        return null;
    }

}