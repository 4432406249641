import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { CreateCompanyByCustomerModal } from './create-company-by-customer';
import { useTranslation } from 'react-i18next';

function CreateCompanyByCustomerButton({ onSuccess, ...props }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.create-company-modal.customer.create-company-by-customer-button' });
    const [open, setOpen] = useState(false);
    const onClose = () => setOpen(false);
    const onOpen = () => setOpen(true);

    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb(ev);
    }

    return (
        <React.Fragment>
            <Button {...props} onClick={handleClick(onOpen)}>{t('register')}</Button>
            <CreateCompanyByCustomerModal
                open={open} onSuccess={(relation) => {
                    onClose();
                    onSuccess(relation);
                }} onClose={handleClick(onClose)} />
        </React.Fragment>
    );
}

export default CreateCompanyByCustomerButton;