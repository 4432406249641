import React from 'react';
import { Typography } from '@material-ui/core';
import { getProductDeviceName } from '../../../../products/component/element/product-device-name';
import { useReferenceName } from '../../../../references/component/element/reference-name';
import { useTranslation } from 'react-i18next';

export function AlreadySubscribed({ product, reference }) {
    const deviceName = getProductDeviceName(product);
    const referenceName = useReferenceName(reference);
    const { t } = useTranslation('translation', { keyPrefix: 'app.order-lines.rules' })
    return (
        <span>
            <Typography>{t('already-subscribed', { deviceName, reference: referenceName })}</Typography>
        </span>
    )
}
