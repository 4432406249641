import React, { useMemo, useEffect } from "react";
import _ from "lodash";
import { useImpersonateSdk, useSdk } from "./context";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "../auth0-wrapper";
import ActionOk from "../../app/common/component/notification/action-ok";
import { useNotif } from "../../app/common/component/notification";
import { useCustomer } from "../../app/customers/component/customer-provider";

export const ResourceGetter = ({ render, component, resource, id, noImpersonate = false, customer = undefined, defaultSdk = null, ...props }) => {

    const dispatch = useDispatch();
    const sdk = useSdk();
    const imp = useImpersonateSdk();
    const auth = useAuth0();
    const notif = useNotif();
    const currentCustomer = useCustomer();

    const name = useMemo(() => resource.endsWith('s') ? resource.slice(0, resource.length - 1) : resource, [resource]);

    if (_.isObject(id)) console.error(new Error('id must not be an object'));
    if (id === undefined) console.error(new Error('id must be undefined'));

    const dataSelector = useMemo(() => sdk[resource].selectors.makeGet(), [resource]);
    const data = useSelector(state => dataSelector(state, id));

    useEffect(() => {
        if (!data) {

            let use = imp;
            if(noImpersonate) use = sdk;
            if(defaultSdk) use = defaultSdk;
            
            dispatch(use[resource].get(auth, id, customer || currentCustomer.target))
                .catch(e => {
                    notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
                });
        }
    }, [resource, id]);

    const innerProps = props;
    innerProps[name] = data;

    if (render) {
        return render(innerProps);
    } else if (component) {
        return React.createElement(component, innerProps);
    } else return null;
};
