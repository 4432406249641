import React from 'react';
import { useIsViewingMainCustomer } from '../../selectors';
import { useCompanyIsDistributor, useCompanyIsVayanData } from '../../../companies/selector';
import CustomerOverviewByVayanData from './overview-by-vayandata';
import CustomerOverviewByDistributor from './overview-by-distributor';
import CustomerOverviewForDistributor from './overview-for-distributor';
import CustomerOverviewForVayanData from './overview-for-vayandata';
import CustomerOverviewForClient from './overview-for-client';

export default function CustomerOverview(props) {

    const isVayanData = useCompanyIsVayanData();
    const isDistributor = useCompanyIsDistributor();
    const isMainView = useIsViewingMainCustomer();

    if (isMainView) {
        if (isVayanData) {
            return (
                <CustomerOverviewForVayanData {...props} />
            )
        } else if (isDistributor) {
            return (
                <CustomerOverviewForDistributor {...props} />
            )
        } else {
            return (
                <CustomerOverviewForClient {...props} />
            )
        }

    } else {
        if (isVayanData) {
            return (
                <CustomerOverviewByVayanData {...props} />
            )
        } else if (isDistributor) {
            return (
                <CustomerOverviewByDistributor {...props} />
            )
        } else {
            return null;
        }
    }
}