import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';
import CURRENCIES from '../../../common/currencies';
import { useTranslation } from 'react-i18next';


function Total({ invoice, ...props }) {

    const { total, currency } = invoice.payload;
    const currencySymbol = useMemo(() => CURRENCIES[currency] || currency.toUpperCase(), [currency]);

    return (
        <Typography {...props}>
            {`${currencySymbol} ${(total / 100).toFixed(2)}`}
        </Typography>
    )
}

export default function InvoiceTotal({ invoice, ...props }) {

    return (
        <Total invoice={invoice} {...props} />
    )
}

export function InvoiceTotalLabel({ }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.invoices.elements.total' });
    return (
        <Typography>{t('label')}</Typography>
    )
}