import React, { useState, useCallback, Fragment } from 'react';
import { TableCell, TableRow } from '@material-ui/core';

import { ItemCheckCell } from './check/item-check-cell';
import Skeleton from '@material-ui/lab/Skeleton';
import { DeviceRowHead } from './device-row-head';
import RulesEngine from '../rules/rule-engine';
import { ItemRowExplanation } from './item-row-explanation';
import { ResourceGetter } from '../../../../lib/licensing-sdk/getter';


export function DeviceSumupRowReady({ onProceed, device, product, reference, ...props }) {

    const [decision, setDecision] = useState({ element: null, allow: null, target: null });

    const takeDecision = useCallback((payload) => {
        setDecision(payload);
        onProceed(payload);
    }, [setDecision, onProceed]);

    return (
        <Fragment>
            <RulesEngine product={product} reference={reference} device={device} takeDecision={takeDecision} />
            <TableRow>
                <TableCell>
                    <DeviceRowHead device={device} />
                </TableCell>
                <TableCell align="center">
                    <ItemRowExplanation decision={decision.element} />
                </TableCell>
                <TableCell align="right"><ItemCheckCell decision={decision.allow} /></TableCell>
            </TableRow>
        </Fragment>
    );
}


export function DeviceSumupPendingRow() {
    return (
        <TableRow>
            <TableCell><Skeleton height={200} /></TableCell>
            <TableCell><Skeleton height={200} /></TableCell>
            <TableCell><Skeleton height={200} /></TableCell>
        </TableRow>
    );
}

export function DeviceSumupRowController({ device, ...props }) {
    return (
        <ResourceGetter resource='devices' id={device} render={({ device }) => {
            if (!(device && device.payload)) return <DeviceSumupPendingRow />
            else return <DeviceSumupRowReady {...props} device={device} />
        }
        } />
    )
}

export default function DeviceSumupRow(props) {
    return (<DeviceSumupRowController {...props} />);
}