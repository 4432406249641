import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeApplicationLoaderSelector } from '../selector';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoaderCounter } from '../action';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '2px',
        zIndex: theme.zIndex.drawer + 2,
        position: 'fixed',
        top: 0,
        left: 0
    },
    linearColorPrimary: {
        backgroundColor: theme.palette.primary[theme.palette.type]
    },
    linearBarColorPrimary: {
        backgroundColor: theme.palette.common.white
    },
}));

function ApplicationLoadingBar() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const applicationLoaderSelector = useMemo(() => makeApplicationLoaderSelector(), []);
    const { pending } = useSelector(applicationLoaderSelector);

    const [max, setMax] = useState(0);

    useEffect(() => {
        if (pending > 0) {
            if(pending > max) {
                setMax(pending);
            }
        } else {
            const timeout = setTimeout(() => {
                setMax(0);
                dispatch(resetLoaderCounter());
            }, 1000);

            return () => clearTimeout(timeout);
        }
    }, [pending]);

    const completed = max - pending;
    const percentage = completed / max * 100;

    if (max > 0) {
        return (<LinearProgress
            className={classes.root}
            variant="determinate"
            classes={{
                colorPrimary: classes.linearColorPrimary,
                barColorPrimary: classes.linearBarColorPrimary,
            }}
            value={percentage} />)
    } else {
        return null;
    }
}

export default ApplicationLoadingBar;
