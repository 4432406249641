import React, { Fragment } from 'react';
import { List, ListItem, ListItemText, ListSubheader } from '@material-ui/core';
import _ from 'lodash';
import { useReferencesListByProductId } from '../../../references/selector';
import { useReferenceName } from '../../../references/component/element/reference-name';
import ProductConfigurationPricesList from './product-configuration-price-list';

export function ProductConfigurationReferenceListItem({ reference, ...props }) {

    const name = useReferenceName(reference);

    return (
        <List dense subheader={
            <ListSubheader component="div" id="reference-configuration-subheader">
                {name}
            </ListSubheader>
        }>
            <ProductConfigurationPricesList reference={reference} />
        </List>
    )
}

export default function ProductConfigurationReferencesList({ product, ...props }) {

    const references = useReferencesListByProductId(product.target);

    return _.map(references, reference => <ProductConfigurationReferenceListItem
        key={reference.target} reference={reference} />);
}
