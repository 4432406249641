import React from 'react';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { Link } from '@material-ui/core';
import { ResourceGetter } from '../../../../lib/licensing-sdk/getter';

function ProductLink({ product }) {
    return (
        <ResourceGetter id={product} resource='products' render={({ product }) =>
            <WaitingLoaded data={product} render={() => {
                if (product.payload.description.link) {
                    return (
                        <Link color='inherit' target='_blank' href={product.payload.description.link.href || ''}>{product.payload.description.link.text}</Link>
                    )
                } else {
                    return null;
                }
            }} />
        } />
    )
}

export default ProductLink;
