import React from 'react';
import { FormControl, FormHelperText, InputLabel, makeStyles, MenuItem } from '@material-ui/core';
import CustomerName from '../elements/customer-name';
import { Field } from 'formik';
import { Select } from 'formik-material-ui';
import _ from 'lodash';
import { useDistributorsList } from '../../selectors';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
}));

function DistributorSelectField({ namespace, errors, disabled, isSubmitting }) {
    const classes = useStyles();

    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.fields.distributor-select' });
    const distributors = useDistributorsList();

    const name = `${namespace ? namespace + '.' : ''}distributor_id`;
    const error = _.get(errors, name);

    return (
        <FormControl className={classes.root} error={Boolean(error)}>
            <InputLabel id="company-distributor-field">{t('label')}</InputLabel>
            <Field
                labelId="company-distributor-field"
                component={Select}
                name={name}
                disabled={disabled || isSubmitting}
            >
                <MenuItem value='null'>
                    <em>{t('none-value')}</em>
                </MenuItem>
                {distributors.map(distributor => {
                    return (
                        <MenuItem key={distributor} value={distributor} >
                            <CustomerName customer={distributor} />
                        </MenuItem>
                    )
                })}
            </Field>
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    )
}

export default DistributorSelectField;