import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useImpersonateSdk } from '../../../lib/licensing-sdk';
import { useAuth0 } from '../../../lib/auth0-wrapper';
import InvoiceListRow, { InvoiceListHead } from './invoice-list-item';
import ActionOk from '../../common/component/notification/action-ok';
import { useNotif } from '../../common/component/notification';
import { Box, Button, CircularProgress, Table, TableBody } from '@material-ui/core';
import { useCustomer } from '../../customers/component/customer-provider';
import { useTranslation } from 'react-i18next';

export const useInvoicesPagesController = (callback) => {
    const limit = 10;
    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [invoices, setInvoices] = useState([]);

    const loadMore = () => {
        setLoading(true);
        return callback(offset, limit)()
            .then(received => {
                if (received.length < limit) {
                    setDisabled(true);
                }
                setOffset(offset + received.length);
                setInvoices([
                    ...invoices,
                    ...received.map(i => ({ target: i.id, payload: i, status: 'success' }))
                ]);
            })
            .finally(() => setLoading(false));
    };

    return { loading, offset, loadMore, disabled, invoices }
}

export function InvoiceList({ invoices, disabled, loadMore, loading }) {

    const notif = useNotif();
    const { t } = useTranslation('translation', { keyPrefix: 'app.invoices.list' });

    return (
        <Box display='flex' flexDirection='column' >
            <Table aria-label="invoice table">
                <InvoiceListHead />
                <TableBody>
                    {invoices.map((invoice) => {
                        return (
                            <React.Fragment key={invoice.target}>
                                <InvoiceListRow invoice={invoice} />
                            </React.Fragment>
                        )
                    })}
                </TableBody>
            </Table>
            <Box mt={2} display='flex' width='100%' justifyContent='center' alignItems='center'>
            {loading ? <CircularProgress /> : <Button disabled={disabled} onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                loadMore()
                    .catch(e => {
                        notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> })
                    });
            }}>{t('load-more-button')}</Button>}
            </Box>
        </Box>
    )
}

export function InvoiceListCtrl({ component, ...props }) {

    const dispatch = useDispatch();
    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const notif = useNotif();

    const customer = useCustomer();

    const { invoices, loadMore, disabled, loading } = useInvoicesPagesController((offset, limit) => async () => {
        return await dispatch(sdk.invoices.list(auth, customer.target, { offset, limit }));
    });

    useEffect(() => {
        loadMore()
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> })
            });
    }, []);

    const innerProps = {
        invoices,
        disabled,
        loading,
        loadMore,
        ...props,
    }

    return React.createElement(component, innerProps);
}

export default function DefaultInvoiceList({ ...props }) {
    return <InvoiceListCtrl component={InvoiceList} {...props} />
}