import React from 'react';
import { useTranslation } from 'react-i18next';

function SessionEndDateString({ session, ...props }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.sessions.elements.end-dateg' });
    if (new Date(session.payload.end_date) > Date.now()) {
        return t('valid-until', { date: new Date(session.payload.end_date).toLocaleString() });
    } else {
        return t('expired');
    }
}

export default SessionEndDateString;
