import React from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    field: {
        width: '100%'
    }
}));

const CompanyCityField = ({ namespace, isSubmitting, ...props }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.forms.address-form.fields.city' });
    const classes = useStyles();

    const name = `${namespace ? namespace + '.' : ''}city`;

    return (
        <Field
            className={classes.field}
            component={TextField}
            name={name}
            type="text"
            label={t('label')}
            validate={value => value !== '' ? undefined : t('validation')}
            disabled={isSubmitting}
        />
    );
}


export default CompanyCityField;
