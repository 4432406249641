import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(2),
    },
}));

function GoHomeButton(props) {
    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.error.home-button' })

    return (
        <Button className={classes.button} {...props} onClick={(ev) => {
            if (ev) {
                ev.preventDefault();
                ev.stopPropagation();
            }
            window.location.replace('/');
        }}>{t('label')}</Button>
    );
}


export default GoHomeButton;