import React, { Fragment, forwardRef, useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import InteractiveLoadingButton from '../../../../common/component/interactive-loading-button';
import { useTranslation } from 'react-i18next';
import { useCompanyIsVayanData } from '../../../../companies/selector.js';
import TransferLicenseModal from './modal.js';

const useStyles = makeStyles(theme => ({
    button: {
        marginRight: theme.spacing(1),
    },
}));

export const TransferLicenceView = forwardRef((props, ref) => {

    const { t } = useTranslation('translation', { keyPrefix: 'app.devices.actions.transfer' });
    const classes = useStyles();

    return (
        <Button
            color='secondary'
            className={classes.button}
            size='small'
            {...props}
            ref={ref}
        >{t('button-label')}</Button>
    )
})

export default function TransferLicenseButton({ device, ...innerProps }) {
    const [open, setOpen] = useState(false);
    const openDialog = useCallback((ev) => {
        if (ev) {
            console.log('prevent')
            ev.stopPropagation();
            ev.preventDefault();
        }

        setOpen(true)
    }, []);
    const closeDialog = useCallback((ev) => {
        if (ev) {
            ev.stopPropagation();
            ev.preventDefault();
        }
        setOpen(false);
    }, []);

    const isVD = useCompanyIsVayanData();

    if (!isVD) {
        return null;
    }

    if (device.payload.lines.length == 0) {
        return null
    }

    return (
        <Fragment>
            <TransferLicenceView disabled={open} onClick={openDialog} {...innerProps} />
            <TransferLicenseModal device={device} open={open} onClose={closeDialog} />
        </Fragment>

    )
}
