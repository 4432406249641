import React from 'react';
import InteractiveLoadingButton from '../../../../common/component/interactive-loading-button';
import PayInvoiceInStripe from './pay-in-stripe';
import PayInvoiceNow from './pay-now';
import { useCustomer } from '../../../../customers/component/customer-provider';
import { useTranslation } from 'react-i18next';

export function PayInvoiceController({ ...props }) {

    const { invoice } = props;
    const { t } = useTranslation('translation', { keyPrefix: 'app.invoices.actions.customer.pay-invoice' });
    const loading = invoice.status.includes('pending');
    const erred = invoice.status.includes('error');
    const disabled = loading || erred;
    const tooltip =
        loading ? t('tooltip.loading') :
            erred ? t('tooltip.error') :
                t('tooltip.default');

    const { status, payment_intent } = invoice.payload;

    const customer = useCustomer();
    const hasDefaultPaymentMethod = customer.payload.customer.invoice_settings.default_payment_method;
    const component = hasDefaultPaymentMethod ? PayInvoiceNow : PayInvoiceInStripe;

    const cannotPaid = ['draft', 'paid', 'uncollectible', 'void'].includes(status);
    const paymentInProgress = payment_intent &&
        ['requires_confirmation', 'requires_action', 'processing'].includes(payment_intent.status);

    props.disabled = disabled;

    if (paymentInProgress) return null;
    if (cannotPaid) return null;

    return (
        <InteractiveLoadingButton tooltip={tooltip} loading={loading} >
            {React.createElement(component, props)}
        </InteractiveLoadingButton>
    )
}

export default function PayInvoiceButton({ invoice }) {
    return (
        <PayInvoiceController
            invoice={invoice}
        />
    )
}
