import React, { useCallback, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

export const WINID_REGEX = /^(WIN|QNX|LNX)(-([A-Z]{4,5}))?(-([A-Z0-9]{4})){3,4}$/i;

export default function DeviceWinidEditable(props) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.devices.elements.host-id-edit' })
    const [error, setError] = useState(false)

    const onChange = useCallback((ev) => {

        const value = ev.target.value;
        if (!WINID_REGEX.test(value)) {
            setError(t('validation'));
        } else {
            setError(false);
        }

        props.onChange(value);
    }, []);


    return (
        <TextField label={t('label')} name="winid" value={props.value || ''}
            onBlur={onChange} onChange={onChange} helperText={error} error={!!error}
        />
    );
}

