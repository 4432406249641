import React from 'react';
import { Typography } from '@material-ui/core';

function OrderLabel({ order, ...props }) {
    const label = order.payload.label || '';

    return (
        <Typography {...props}>
            {label}
        </Typography>
    )
}

export default OrderLabel;
