import React from 'react';
import { Button } from '@material-ui/core';
import { useAuth0 } from '../../../../lib/auth0-wrapper';
import { useTranslation } from 'react-i18next';


function LogoutButton(props) {
    const { logout } = useAuth0();
    const { t } = useTranslation('translation', { keyPrefix: 'app.users.authentication' });

    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb(ev);
    }


    return (
        <Button {...props} onClick={handleClick(() => {
            logout();
        })}>{t('logout')}</Button>
    );
}

export default LogoutButton;
