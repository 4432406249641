import _, { each } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useSdk } from '../../lib/licensing-sdk';
import { useCustomer } from '../customers/component/customer-provider';
import { DEVICE } from '../products/product-constants';
import { useMainCompany } from '../relations/component/user-relation-provider';

export function makePricesListForCompanyAndService(sdk) {
    return createSelector(
        sdk.references.selectors.getDb,
        sdk.prices.selectors.getDb,
        (state, { company, product }) => ({ company: company.payload ? company.payload.prices : [], product }),
        (references, prices, { company, product }) => {
            prices = _.compact(_.map(company, reference => prices[reference])); // Get the plans policies for the company.
            prices = _.filter(prices, 'payload');
            prices = _.compact(_.map(prices, price => ({ reference: references[price.payload.reference], price })));
            prices = _.filter(prices, p => p.reference.payload && p.reference.payload.product === product);
            return _.map(prices, p => p.price);
        }
    )
}

function usePricesByReferenceForPricesList(prices, reference) {
    const sdk = useSdk();
    const selector = useMemo(() => createSelector(
        sdk.prices.selectors.getDb,
        (pricesDb) => {
            const list = _.chain(prices)
                .map(price => pricesDb[price])
                .compact()
                .filter(price => price.payload.reference === reference)
                .value();
            return list;
        }
    ), [prices, reference]);
    return useSelector(selector);
}

export function useAssociatedPricesByReferences(references) {
    const sdk = useSdk();
    const selector = useMemo(() => {
        references = _.map(references, 'target');
        return createSelector(
            sdk.prices.selectors.getDb,
            (pricesDb) => {
                const list = _.chain(pricesDb)
                    .mapValues()
                    .filter(price => references.indexOf(price.payload.reference) !== -1)
                    .map(price => price.payload.associatedFreePrices)
                    .flatten()
                    .uniq()
                    .value();
                    
                return list;
            }
        )
    }, [references]);
    return useSelector(selector);
}

export function usePricesByReferences(references) {
    const sdk = useSdk();
    const selector = useMemo(() => {
        references = _.map(references, 'target');
        return createSelector(
            sdk.prices.selectors.getDb,
            (pricesDb) => {
                const list = _.chain(pricesDb)
                    .mapValues()
                    .filter(price => references.indexOf(price.payload.reference) !== -1)
                    .map(price => price.target)
                    .value();
                return list;
            }
        )
    }, [references]);
    return useSelector(selector);
}

export function usePricesForCustomer() {
    const customer = useCustomer();
    return customer.payload.prices;
}

export function usePricesByReferenceForCompany(reference) {
    const company = useMainCompany();
    const prices = useMemo(() => company.payload.prices, [company]);
    return usePricesByReferenceForPricesList(prices, reference);
}

export function usePriceForCustomer(price) {
    const customer = useCustomer();
    const prices = useMemo(() => customer.payload.prices, [customer]);
    const priceInDb = usePriceById(price);
    if (prices.indexOf(price) === -1) {
        return undefined;
    } else {
        return priceInDb;
    }
}

export function usePriceById(id) {
    const sdk = useSdk();
    const selector = useMemo(() => {
        return createSelector(
            sdk.prices.selectors.getDb,
            (prices) => {
                return prices[id]
            }
        )
    }, [id]);
    return useSelector(selector);
}

export function useByProductPriceListForDevices(customer) {
    const sdk = useSdk();
    const selector = useMemo(() => createSelector(
        sdk.prices.selectors.getDb,
        sdk.references.selectors.getDb,
        sdk.products.selectors.getDb,
        (pricesDb, referencesDb, productsDb) => {
            const productsForDevices = _.chain(productsDb)
                .filter(product => product.payload.works_for === DEVICE)
                .keyBy(product => product.target)
                .value();
            const referencesForDevices = _.chain(referencesDb)
                .filter(reference => Boolean(productsForDevices[reference.payload.product]))
                .keyBy(reference => reference.target)
                .value();

            const list = _.chain(customer.payload.prices)
                .map(price => _.cloneDeep(pricesDb[price]))
                .filter(price => price && price.payload)
                .filter(price => Boolean(referencesForDevices[price.payload.reference]))
                .groupBy(price => {
                    let reference = referencesForDevices[price.payload.reference];
                    return reference.payload.product;
                })
                .value();
            return list;
        }
    ), [customer]);
    return useSelector(selector);
}
