import React, { useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import CenteredFullScreen from '../../../common/component/centered-full-screen';
import { useNotif } from '../../../common/component/notification';
import { useSdk } from '../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../lib/auth0-wrapper';
import ActionOk from '../../../common/component/notification/action-ok';
import CustomerDaysUntilDueField from '../fields/invoice-due-field';
import { useTranslation } from 'react-i18next';


function useHandleSubmit(onClose, customer, vayandata) {
    const notif = useNotif();
    const sdk = useSdk();
    const auth = useAuth0();
    const dispatch = useDispatch();

    return useCallback((values, { setSubmitting }) => {

        const payload = {
            days_until_due: Math.round(values.days_until_due),
        };

        const imp = sdk.impersonateByVayanData(vayandata.target);
        
        dispatch(imp.customers.changeDaysUntilDue(auth, payload, customer.target))
            .then(() => {
                setSubmitting(false);
                onClose();
            })
            .catch(err => {
                const message = err.response?.data?.message || err.message;
                notif.error(message, { action: (key) => <ActionOk notif={notif} handle={key} /> });
                setSubmitting(false);
            });
    }, []);
}

export default function ChangeDaysUntilDueByModal({ open, onClose, customer, vayandata, ...props }) {

    const initialValues = { days_until_due: customer.payload.days_until_due };
    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.change-days-until-due.modal' })
    const handleSubmit = useHandleSubmit(onClose, customer, vayandata);

    if (open) {
        return (
            <Dialog maxWidth='lg' fullWidth open={open} onClose={onClose}>
                <DialogTitle>
                    <Typography display='inline'>{t('title')}</Typography>
                </DialogTitle>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {(props) => (
                        <Form>
                            <DialogContent>
                                <CenteredFullScreen>
                                    <CustomerDaysUntilDueField {...props}/>
                                </CenteredFullScreen>
                            </DialogContent>
                            <DialogActions>
                                <Button disabled={props.isSubmitting} onClick={onClose}>
                                    {t('cancel')}
                                </Button>
                                <Button disabled={props.isSubmitting} onClick={props.handleReset}>
                                    {t('reset')}
                                </Button>
                                <Button disabled={props.isSubmitting} onClick={props.handleSubmit} color='secondary' >
                                    {t('proceed')}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik >
            </Dialog >
        );
    } else {
        return null;
    }

}