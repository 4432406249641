import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { useCustomerIsDistributed, useCustomerIsVayanData } from '../../customers/selectors';
import UsersLayout from '../../users/component';
import AccountAddress from './account-address';
import AccountBilling from './account-billing';
import AccountSubscriptions from './account-subscriptions';


const Account = (props) => {

    const { url } = useRouteMatch();

    const isDistributed = useCustomerIsDistributed();
    const isVayanData = useCustomerIsVayanData();

    return (
        <Switch>
            {!isVayanData && !isDistributed &&
                <Route path={`${url}/address`} component={AccountAddress} />}
            {!isVayanData && !isDistributed && <Route path={`${url}/billing`} component={AccountBilling} />}
            <Route path={`${url}/subscriptions`} component={AccountSubscriptions} />
            <Route path={`${url}/users`} component={UsersLayout} />
            <Route path={'*'} render={() => <Redirect to={url} />} />
        </Switch>
    );
}

export default Account;
