import React from 'react';
import { Field } from 'formik';
import { Select } from 'formik-material-ui';
import { makeStyles } from '@material-ui/styles';
import { MenuItem, FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    field: {
        width: '100%'
    }
}));

export const INVOICE_POLICY_FOR_EACH_PURCHASE = 'for_each_purchase';
export const INVOICE_POLICY_MANUALLY = 'manually';
export const INVOICE_POLICIES = [INVOICE_POLICY_FOR_EACH_PURCHASE, INVOICE_POLICY_MANUALLY];

const CompanyInvoicePolicyField = ({ namespace, errors, disabled, isSubmitting }) => {

    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.forms.invoice-policy-field' });

    const name = `${namespace ? namespace + '.' : ''}invoice_policy`;
    const error = _.get(errors, name);

    return (
        <FormControl className={classes.field} error={Boolean(error)}>
            <InputLabel id="company-invoice-policy-field">{t('label')}</InputLabel>
            <Field
                labelId="company-invoice-policy-field"
                component={Select}
                name={name}
                disabled={disabled || isSubmitting}
                validate={value => INVOICE_POLICIES.includes(value) ? undefined : t('validation')}
            >
                {INVOICE_POLICIES.map(c => <MenuItem key={c} value={c}>{c.toUpperCase().replace(/\_/g, ' ')}</MenuItem>)}
            </Field>
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    )
}


export default CompanyInvoicePolicyField;
