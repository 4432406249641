import React from 'react';
import _ from 'lodash';
import { useNonOpenOrdersForCustomerSelector } from '../../../selectors';
import { useCustomer } from '../../../../customers/component/customer-provider';
import { OrderHistoryTable } from './order-history-table';

function OrderHistoryPanel({ push, ...props }) {
    const customer = useCustomer();
    const orders = useNonOpenOrdersForCustomerSelector(customer.target);
    return (
        <OrderHistoryTable {...props} onClick={push} title={''} orders={orders} />
    )
}

export default OrderHistoryPanel;
