import React, { useState } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import DoubleArrow from '@material-ui/icons/DoubleArrow';
import { useCustomer } from '../customer-provider';
import { useMainCompany } from '../../../relations/component/user-relation-provider';
import ChangeDistributorModal from './change-distributor-modal';
import { useTranslation } from 'react-i18next';

export default function ChangeDistributorSidebarButton({ defaultCustomer, ...props }) {

    const [open, setOpen] = useState(false);
    const onClose = () => setOpen(false);
    const onOpen = () => setOpen(true);

    const company = useMainCompany();
    const currentCustomer = useCustomer();
    const customer = defaultCustomer || currentCustomer;
    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.change-distributor.sidebar-button' });

    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb(ev);
    }

    return (
        <React.Fragment>
            <ListItem button onClick={handleClick(onOpen)}>
                <ListItemIcon><DoubleArrow /></ListItemIcon>
                <ListItemText primary={t('label', { name: customer.payload.name })} />
            </ListItem >
            <ChangeDistributorModal
                vayandata={company}
                customer={customer}
                open={open} onClose={handleClick(onClose)} />
        </React.Fragment>
    );
}
