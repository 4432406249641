import React from 'react';
import {
    Typography, ExpansionPanelSummary,
    ExpansionPanelDetails, ExpansionPanelActions, Divider, ExpansionPanel
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddressFormGrid from './grid';
import { useTranslation } from 'react-i18next';

export default function AddressFormPanel({ defaultExpanded, actions, title = 'Address', ...props }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.forms.address-form.views.expansion-panel' })
    return (
        <ExpansionPanel defaultExpanded={defaultExpanded}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h4'>{title || t('default-title')}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <AddressFormGrid {...props} />
            </ExpansionPanelDetails>
            {actions && <Divider />}
            {actions && <ExpansionPanelActions>
                {actions}
            </ExpansionPanelActions>}
        </ExpansionPanel>
    );
}
