import React, { useState } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { useCustomer } from '../customer-provider';
import { useMainCompany } from '../../../relations/component/user-relation-provider';
import ChangeCoefficientByVayanDataModal from './change-coefficient-modal-by-vayandata';
import { useTranslation } from 'react-i18next';
import { companyName } from '../../../companies/component/elements/company-name';

export default function ChangeCoefficientByVayanDataSidebarButton({ defaultCustomer, ...props }) {

    const [open, setOpen] = useState(false);
    const onClose = () => setOpen(false);
    const onOpen = () => setOpen(true);

    const company = useMainCompany();
    const currentCustomer = useCustomer();
    const customer = defaultCustomer || currentCustomer;
    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.change-coefficient.vayandata-sidebar-button' })

    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb(ev);
    }

    return (
        <React.Fragment>
            <ListItem button onClick={handleClick(onOpen)}>
                <ListItemIcon><TrendingUpIcon /></ListItemIcon>
                <ListItemText primary={t('label', { name: companyName(customer) })} />
            </ListItem >
            <ChangeCoefficientByVayanDataModal
                vayandata={company}
                customer={customer}
                open={open} onClose={handleClick(onClose)} />
        </React.Fragment>
    );
}
