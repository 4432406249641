import React, { useState } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { useCustomer } from '../customer-provider';
import { useMainCompany } from '../../../relations/component/user-relation-provider';
import GenerateInvoiceModal from './generate-invoice-modal';
import { useViewingAsVayanData } from '../../selectors';
import { INVOICE_MANUALLY } from '../../../companies/constants';
import { useTranslation } from 'react-i18next';

export default function GenerateInvoiceListButton({ ...props }) {

    const [open, setOpen] = useState(false);
    const onClose = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(false);
    };

    const onOpen = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(true);
    }

    const company = useMainCompany();
    const customer = useCustomer();
    const isAdmin = useViewingAsVayanData();
    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.generate-invoice.list-button' });
    const isPolicyManually = customer.payload.invoice_policy === INVOICE_MANUALLY;

    if (!isAdmin) return null;
    if (!isPolicyManually) return null;

    return (
        <React.Fragment>
            <ListItem button onClick={onOpen}>
                <ListItemIcon><ListAltIcon /></ListItemIcon>
                <ListItemText primary={t('label', { name: customer.payload.name })} />
            </ListItem>
            <GenerateInvoiceModal
                vayandata={company}
                customer={customer}
                open={open} onClose={onClose} />
        </React.Fragment>
    );
}
