import React from 'react';
import { Typography } from '@material-ui/core';
import { useCustomerInDb } from '../../selectors';
import { useTranslation } from 'react-i18next';

function CustomerTrustPolicy({ customer, capitalize, ...props }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.elements.customer-trust-policy' })
    customer = useCustomerInDb(customer);
    const trust = t(customer.payload.trust_policy);
    
    return (
        <Typography
            {...props}
            style={{ textTransform: capitalize ? 'capitalize' : 'inherit' }}
        >
            {trust}
        </Typography>

    )
}

export default CustomerTrustPolicy;
