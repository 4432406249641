import _ from "lodash";
import { useCustomer } from "../customers/component/customer-provider";
import { useIsViewingMainCustomer, useViewingAsVayanData } from "../customers/selectors";
import { useCallback } from "react";
import { useSdk } from "../../lib/licensing-sdk";
import { useAuth0 } from "../../lib/auth0-wrapper";
import { useDispatch } from "react-redux";
import { useMainCompany } from "../relations/component/user-relation-provider";


export const useLoadReferencesAndProducts = () => {
    const sdk = useSdk();
    const auth = useAuth0();
    const dispatch = useDispatch();

    const company = useMainCompany();
    const isVayanData = useViewingAsVayanData();
    const isMainCustomer = useIsViewingMainCustomer();
    const customer = useCustomer();

    return useCallback(() => {
        const imp = isVayanData ?
            sdk.impersonateByVayanData(company.target) :
            sdk.impersonateByDistributor(company.target);

        return Promise.all(
            [
                dispatch(sdk.references.list(auth, company.target)),
                dispatch(sdk.products.list(auth, company.target)),
                isMainCustomer ? Promise.resolve() : dispatch(imp.references.list(auth, customer.target)),
                isMainCustomer ? Promise.resolve() : dispatch(imp.products.list(auth, customer.target)),
            ]
        );
    }, [customer]); // eslint-disable-line react-hooks/exhaustive-deps
}