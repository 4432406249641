import React from 'react';
import { Chip, makeStyles } from '@material-ui/core';
import {
    STATUS_IDLE,
    STATUS_AWAITING_PAYMENT,
    STATUS_ACCEPTED_BY_DISTRIBUTOR,
    STATUS_ACCEPTED_BY_ADMIN,
    STATUS_PAID,
    STATUS_UNPAID,
    STATUS_STOPPED,
    STATUS_CANCELLED,
    STATUS_REVOKED
} from '../../constants';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const COLORS = {
    [STATUS_IDLE]: 'warning',
    [STATUS_AWAITING_PAYMENT]: 'warning',
    [STATUS_ACCEPTED_BY_DISTRIBUTOR]: 'success',
    [STATUS_ACCEPTED_BY_ADMIN]: 'success',
    [STATUS_PAID]: 'success',
    [STATUS_UNPAID]: 'error',
    [STATUS_STOPPED]: 'error',
    [STATUS_CANCELLED]: 'warning',
    [STATUS_REVOKED]: 'error',
}

const useStyles = makeStyles((theme) => ({
    success: {
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main,
    },
    warning: {
        color: theme.palette.warning.main,
        borderColor: theme.palette.warning.main,
    },
    error: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    }
}))


function OrderLineStatus({ line, ...props }) {
    const status = line.payload.status;
    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.order-lines.elements.status' });
    const color = t(`colors.${status}`)
    return <Chip {...props} label={t(status)} variant='outlined' className={classes[color]} />
}

export default OrderLineStatus;
