import React from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import CustomerOverviewByVayanData from '../overview/overview-by-vayandata';
import { Grid } from '@material-ui/core';
import { useCustomerInDb } from '../../selectors';
import CompanyBillingFormPanel from '../../../companies/component/forms/billing-form/controllers/change';
import ChangeCompanyAddressPanel from '../../../companies/component/forms/address-form/controllers/change';
import { ONBOARDING_PENDING } from '../../../companies/constants';
import DeleteCompanyButton from './delete-company-button';
import ValidationButton from './validate-company-button';

export default function AwaitingValidationCustomersDetailPage({ }) {
    const { vid } = useParams();
    const customer = useCustomerInDb(vid);

    if (customer === undefined) {
        return null;
    }

    const changesEnabled = customer?.payload?.onboarding_status === ONBOARDING_PENDING
    const isDistributed = !!customer?.payload?.distributor_id;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <CustomerOverviewByVayanData
                    disableGenerateInvoiceButton
                    enableChangeDistributor={changesEnabled}
                    defaultCustomer={customer} />
            </Grid>
            <Grid item xs={12}>
                <ChangeCompanyAddressPanel forceVD
                    enableActions={changesEnabled && !isDistributed}
                    defaultExpanded defaultCompany={customer} />
            </Grid>
            {!isDistributed && <Grid item xs={12}>
                <CompanyBillingFormPanel forceVD
                    enableActions={changesEnabled}
                    taxIdFieldEnabled
                    currencyFieldEnabled
                    billingEmailFieldEnabled
                    metadataFieldEnabled
                    defaultExpanded defaultCompany={customer} />
            </Grid>}
            {changesEnabled && <Grid item xs={12} container spacing={2} justify='center'>
                <Grid alignContent='center' item>
                    <DeleteCompanyButton customer={customer} />
                </Grid>
                <Grid alignContent='center' item>
                    <ValidationButton customer={customer} />
                </Grid>
            </Grid>}
        </Grid>
    )
}