import React from 'react';
import { Grid } from '@material-ui/core';
import ChangeCompanyAddressPanel from '../../companies/component/forms/address-form/controllers/change';

const AccountAddress = (props) => {

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ChangeCompanyAddressPanel enableActions defaultExpanded/>
            </Grid>
        </Grid>
    );

}

export default AccountAddress;