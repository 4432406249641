import React from 'react';

import { makeStyles } from '@material-ui/core';
import BCardMedia from '../../../common/component/card/card-media';
import { ResourceGetter } from '../../../../lib/licensing-sdk/getter';
import WaitingLoaded from '../../../common/component/waiting-loaded';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundSize: 'initial',
        backgroundRepeat: 'repeat',
    }
}));

function ReferenceMedia({ reference, children, ...props }) {

    const classes = useStyles();

    return (
        <ResourceGetter resource='references' id={reference} render={({ reference }) =>
            <WaitingLoaded data={reference} render={() => (
                <BCardMedia className={classes.root} {...props}
                    title={(reference.payload.description.title || reference.payload.name)}
                >
                    {children}
                </BCardMedia>
            )} />
        } />

    )
}

export default ReferenceMedia;
