import React, { useEffect, useMemo, useState } from 'react';
import EditRow from './edit-row';
import BodyRow from './body-row';
import MaterialTable from 'material-table';
import _ from 'lodash';
import Toolbar from './toolbar';
import Action from './action';

export function TableController(props) {

    const { component, onAdd, onUpdate, onRefresh, onDelete, actions = [], search, exportFileName, pageSize = 5, refresh, onSelect, selected = [], data, ...more } = props;

    const innerActions = useMemo(() => {
        const a = _.cloneDeep(actions);

        if (onDelete)
            a.push({
                tooltip: 'Remove',
                icon: 'delete',
                onClick: onDelete,
            });

        if (onRefresh) a.push({
            icon: 'refresh',
            tooltip: 'Refresh',
            isFreeAction: true,
            onClick: onRefresh,
        });

        return a;
    }, [actions]);

    const innerProps = {
        options: {
            actionsColumnIndex: -1,
            exportFileName: exportFileName,
            //showTextRowsSelected: false,
            pageSize,
            pageSizeOptions: [pageSize, 2 * pageSize, 4 * pageSize]
        },
        editable: {},
        actions: innerActions,
        components: {
            Action: Action,
            EditRow: EditRow,
            Row: BodyRow,
            Toolbar: Toolbar,
        },
        data,
        ...more
    }

    if (onAdd) innerProps.editable.onRowAdd = onAdd;
    if (onUpdate) innerProps.editable.onRowUpdate = onUpdate;

    if (onSelect) {
        innerProps.options.selection = true;
        innerProps.onSelectionChange = onSelect;
    }
    if (exportFileName) innerProps.options.exportButton = true;
    if (!search) innerProps.options.search = false;

    return <MaterialTable {...innerProps} />;
}

