import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import CenteredFullScreen from '../common/component/centered-full-screen';
import {
    CircularProgress,
    Grid,
    List, ListItem, ListItemSecondaryAction, ListItemText, Paper, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useCustomer } from '../customers/component/customer-provider';
import { useCustomerIsVayanData } from '../customers/selectors';
import { useImpersonateSdk, useSdk } from '../../lib/licensing-sdk';
import { useAuth0 } from '../../lib/auth0-wrapper';
import { useNotif } from '../common/component/notification';
import ActionOk from '../common/component/notification/action-ok';
import AcceptRequestButton from './actions/vayandata/accept-request';
import RejectRequestButton from './actions/vayandata/reject-request';
import DeviceWinid from '../devices/component/element/device-winid';
import CompanyName from '../companies/component/elements/company-name';
import { useMainCompany } from '../relations/component/user-relation-provider';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    fullwidth: {
        width: "100%",
    }
}));

export const useOfficeDemoRequestsControllerForVD = () => {
    const [loading, setLoading] = useState(true);
    const [requests, setRequests] = useState([]);
    const customer = useCustomer();
    const auth = useAuth0();
    const sdk = useImpersonateSdk();
    const notif = useNotif();

    const remove = useCallback((request) => {
        _.remove(requests, r => r._id === request._id);
        setRequests([...requests]);
    }, [requests]);

    useEffect(() => {
        sdk.devices.listOfficeDemoRequestsForVD(auth, customer.target)
            .then((requests) => {
                setRequests(requests);
            }).catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            }).finally(() => {
                setLoading(false);
            })
    }, []);

    return [requests, remove, loading];
}

export const LoadingRequests = ({ }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'app.office-demo-requests.list' })
    return (
        <CenteredFullScreen>
            <Typography>
                {t('loading')}
            </Typography>
            <CircularProgress />
        </CenteredFullScreen>
    )
}

export const NoRequests = ({ }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'app.office-demo-requests.list' })
    return (
        <CenteredFullScreen>
            <Typography>
                {t('no-requests')}
            </Typography>
        </CenteredFullScreen>
    )
}

const OfficeDemoRequestItem = ({ request, remove, ...props }) => {

    const classes = useStyles();
    const company = useMainCompany();
    const sdk = useSdk();
    const defaultSdk = useMemo(() => sdk.impersonateByVayanData(company.target), [sdk]);

    return (
        <Grid item xs={12}>
            <Paper>
                <List>
                    <ListItem className={classes.fullwidth}>
                        <ListItemText primary={<DeviceWinid device={request.device} customer={request.created_by} defaultSdk={defaultSdk} />} />
                        <ListItemText primary={<CompanyName company={request.created_by} defaultSdk={defaultSdk} />} />
                        <ListItemSecondaryAction>
                            <AcceptRequestButton request={request} onDone={remove} />
                            <RejectRequestButton request={request} onDone={remove} />
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Paper>
        </Grid>
    )
}

const OfficeDemoRequestList = ({ requests, remove, ...props }) => {

    if (requests.length === 0) {
        return <NoRequests />
    }

    return (
        <Grid container spacing={2}>
            {_.map(requests, (request) => (
                <OfficeDemoRequestItem key={request._id} request={request} remove={remove} />
            ))}
        </Grid>
    )
}

function OfficeDemoRequestsInternal({ ...props }) {
    const [requests, remove, loading] = useOfficeDemoRequestsControllerForVD();

    if (loading) {
        return <LoadingRequests />
    }

    return <OfficeDemoRequestList requests={requests} remove={remove} />
}

export default function OfficeDemoRequests({ ...props }) {
    const viewByAdmin = useCustomerIsVayanData();

    if (viewByAdmin) {
        return <OfficeDemoRequestsInternal {...props} />
    } else {
        return null;
    }
}