import React from 'react';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { Typography } from '@material-ui/core';
import { ResourceGetter } from '../../../../lib/licensing-sdk/getter';

export const useProductName = (product) => {
    if (product) {
        const payload = product.payload;
        if (payload) {
            return payload.description.title ? payload.description.title : payload.name;
        }
    } else {
        return null;
    }
}


function ProductTitle({ product, ...props }) {
    return (
        <ResourceGetter id={product} resource='products' render={({ product }) =>
            <WaitingLoaded data={product} render={() => (
                <Typography {...props} >
                    {product.payload.description.title ? product.payload.description.title : product.payload.name}
                </Typography>)
            } />
        } />
    )
}

export default ProductTitle;
