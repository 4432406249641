import _ from 'lodash';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useSdk } from '../../lib/licensing-sdk';
import { useCustomer } from '../customers/component/customer-provider';
import { useMainCompany } from '../relations/component/user-relation-provider';
import { OPEN, FINALIZED } from './constants';

export function useIsBuyer(order) {
    const company = useMainCompany();
    return order.payload.buyer === company.target;
}

export function useOrderById(order) {
    const sdk = useSdk();
    const selector = useMemo(() => createSelector(
        sdk.orders.selectors.getDb,
        (orders) => orders[order]
    ), [order]);
    return useSelector(selector);
}

export function useNonOpenOrdersForCustomerSelector(customer) {
    const sdk = useSdk();
    const selector = useMemo(() => createSelector(
        sdk.orders.selectors.getDb,
        (orders) => {
            const opens = _.chain(orders)
                .filter(order => order.payload)
                .filter(order => order.payload.customer === customer)
                .filter(order => order.payload.status !== OPEN)
                .orderBy(['payload.date'], ['desc'])
                .value();
            return opens;
        }
    ), [customer]);
    return useSelector(selector);
}

export function useOpenOrdersForCustomerSelector(customer) {
    const sdk = useSdk();
    const selector = useMemo(() => createSelector(
        sdk.orders.selectors.getDb,
        (orders) => {
            const opens = _.chain(orders)
                .filter(order => order.payload)
                .filter(order => order.payload.customer === customer)
                .filter(order => order.payload.status === OPEN)
                .orderBy(['payload.date'], ['desc'])
                .value();
            return opens;
        }
    ), [customer]);
    return useSelector(selector);
}

export function useOrdersForBuyerSelector(buyer) {
    const sdk = useSdk();
    const selector = useMemo(() => createSelector(
        sdk.orders.selectors.getDb,
        (orders) => {
            const opens = _.chain(orders)
                .filter(order => order.payload)
                .filter(order => order.payload.buyer === buyer)
                .filter(order => order.payload.customer !== buyer)
                .orderBy(['payload.created_at'], ['desc'])
                .value();
            return opens;
        }
    ), [buyer]);
    return useSelector(selector);
}

export function useOpenOrdersForBuyerSelector(buyer) {
    const sdk = useSdk();
    const selector = useMemo(() => createSelector(
        sdk.orders.selectors.getDb,
        (orders) => {
            const opens = _.chain(orders)
                .filter(order => order.payload)
                .filter(order => order.payload.buyer === buyer)
                .filter(order => order.payload.customer !== buyer)
                .filter(order => order.payload.status !== FINALIZED)
                .value();
            return opens;
        }
    ), [buyer]);
    return useSelector(selector);
}

export function useOrdersLinesByProductAndPrice(order) {

    const sdk = useSdk();

    const selector = useMemo(() => createSelector(
        sdk.orderlines.selectors.getDb,
        (orderlines) => {
            const lines = _.chain(order.payload.lines)
                .map(line => orderlines[line])
                .filter(line => line && line.payload)
                .groupBy(line => line.payload.product) // group by products
                .mapValues((lines) => _.groupBy(lines, line => line.payload.price)) // group by prices
                .cloneDeep()
                .value();
            return lines;
        }
    ), [order]);
    return useSelector(selector);
}

export function useOpenOrdersCount() {
    const sdk = useSdk();
    const customer = useCustomer();

    const selector = useMemo(() => createSelector(
        sdk.orders.selectors.makePopulateList(),
        (orders) => _.chain(orders)
            .filter(o => o.payload)
            .filter(o => o.payload.status === OPEN && o.payload.customer === customer.target)
            .size()
            .value()
    ), []);

    return useSelector(selector);
}
