import React from 'react';
import { useParams } from 'react-router-dom';
import ProductOverview from './overview';
import { useFindProductById } from '../selector';
import ProductNotFound from './product-not-found';

export default function ProductController({ props }) {

    let { productId } = useParams();
   
    const product = useFindProductById(productId);

    if (!product) {
        return <ProductNotFound />;
    } else {
        return <ProductOverview product={product} />
    }

}
