import React from 'react';
import { Typography } from '@material-ui/core';

function OrderName({ order, ...props }) {
    const date = new Date(order.payload.created_at);

    return (
        <Typography {...props}>
            {date.toLocaleDateString()} - {date.toLocaleTimeString()}
        </Typography>
    )
}

export default OrderName;
