import React, { Fragment, useCallback, useState } from 'react';
import { Button, Tooltip, } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '../../../../lib/auth0-wrapper';
import { useNotif } from '../../../common/component/notification';
import ActionOk from '../../../common/component/notification/action-ok';
import { useCustomer } from '../../../customers/component/customer-provider';
import { useImpersonateSdk } from '../../../../lib/licensing-sdk';
import { useTranslation } from 'react-i18next';

function useAssociateDeviceCallback(line, device, onClose) {
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const notif = useNotif();

    const customer = useCustomer();

    const onConfirm = useCallback((ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setLoading(true);
        dispatch(sdk.orderlines.associateDevice(auth, line.target, device, customer.target))
            .then(() => {
                onClose();
            })
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
                setLoading(false);
            });
    }, [line, device]);

    return [loading, onConfirm];
}

export default function AssociateActions({ device, disabled, line, onClose }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.devices.available-licenses.modal' });
    const [loading, onConfirm] = useAssociateDeviceCallback(line, device, onClose);

    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb(ev);
    }

    const tooltip = disabled ? t('tooltip-disabled') : loading ? t('tooltip-progress') : t('tooltip');

    return (
        <Fragment>
            <Tooltip title={tooltip}>
                <Button disabled={disabled || loading} onClick={onConfirm} color='secondary'>
                    {t('confirm')}
                </Button>
            </Tooltip>
            <Button onClick={handleClick(onClose)} >
                {t('cancel')}
            </Button>
        </Fragment>
    )
}
