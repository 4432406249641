import React, { forwardRef } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { useImpersonateSdk } from '../../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../../lib/auth0-wrapper';
import ActionOk from '../../../../common/component/notification/action-ok';
import { useNotif } from '../../../../common/component/notification';
import { useStripe } from '@stripe/react-stripe-js';
import InteractiveLoadingButton from '../../../../common/component/interactive-loading-button';
import { useCustomer } from '../../../../customers/component/customer-provider';
import { useTranslation } from 'react-i18next';

const HandleCardConfirm = forwardRef((props, ref) => {
    const { t } = useTranslation('translation', { keyPrefix: 'app.invoices.actions.customer.handle-card-confirm' });

    return (
        <Button
            {...props}
            ref={ref}
            color='secondary'
            size='small'
        >{t('button-label')}</Button>
    )
})

function HandleCardConfirmController({ ...props }) {

    const { invoice } = props;
    const { t } = useTranslation('translation', { keyPrefix: 'app.invoices.actions.customer.handle-card-confirm' });
    const stripe = useStripe();
    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const notif = useNotif();
    const dispatch = useDispatch();

    const customer = useCustomer();

    const loading = invoice.status.includes('pending');
    const erred = invoice.status.includes('error');
    const hasDefaultPaymentMethod = customer && customer.payload && customer.payload.customer.invoice_settings.default_payment_method;
    const disabled = loading || erred || !hasDefaultPaymentMethod;
    const tooltip =
        loading ? t('tooltip.loading') :
            erred ? t('tooltip.error') :
                !hasDefaultPaymentMethod ? t('tooltip.no-default-payment-method') :
                    t('tooltip.default');

    const { status, payment_intent } = invoice.payload;

    const isConfirmation = payment_intent && payment_intent.status === 'requires_confirmation';

    if (status !== 'open') return null;
    if (!isConfirmation) return null;

    props.disabled = disabled;
    props.onClick = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        stripe.confirmCardPayment(payment_intent.client_secret)
            .then((result) => {
                if (result.error) throw result.error;
            })
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            })
            .finally(() => dispatch(sdk.invoices.get(auth, invoice.target, customer.target, true)));
    }

    return (
        <InteractiveLoadingButton tooltip={tooltip} loading={loading} >
            {React.createElement(HandleCardConfirm, props)}
        </InteractiveLoadingButton>
    )
}


export default function HandleCardConfirmButton({ invoice }) {
    return (
        <HandleCardConfirmController
            invoice={invoice}
        />
    )
}
