import React from 'react';
import ReferenceName, { useReferenceName } from '../../../references/component/element/reference-name';
import { useReferenceById } from '../../../references/selector';

export function useOrderLineReferenceName(line) {
    const reference = useReferenceById(line?.payload?.reference);
    const name = useReferenceName(reference);
    return name;
}

function OrderLineReferenceName({ line, ...props }) {

    if (line.payload) {
        return (
            <ReferenceName {...props} reference={line.payload.reference} />
        )
    } else {
        return null;
    }

}

export default OrderLineReferenceName;
