import React from 'react';
import _ from 'lodash';
import CustomerName from '../../../customers/component/elements/customer-name';

function OrderCustomerName({ order }) {

    return <CustomerName customer={order.payload.customer} />
}

export default OrderCustomerName;
