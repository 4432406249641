import React from 'react';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import OrderHistoryPanel from '../details/order-history/order-history-panel';
import PageHeader from '../../../common/component/page-header';
import { useTranslation } from 'react-i18next';

function OrderHistoryPage({ push }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.layout.history' });
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <PageHeader title={t('title')} />
            </Grid>
            <Grid item xs={12}>
                <OrderHistoryPanel push={push} />
            </Grid>
        </Grid>
    )
}

export default OrderHistoryPage;
