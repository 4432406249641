import React from 'react';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { ResourceGetter } from '../../../../lib/licensing-sdk/getter';

function DeviceLastUpdate({ device }) {
    return (
        <ResourceGetter resource='devices' id={device} render={({ device }) =>
            <WaitingLoaded data={device} render={() =>
                new Date(device.payload.updated_at).toLocaleDateString()
            } />
        } />
    )
}

export default DeviceLastUpdate;
