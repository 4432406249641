import React from 'react';
import { Button } from '@material-ui/core';

const ActionOk = ({ notif, handle }) => {
    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb(ev);
    }
    return (
        <Button onClick={handleClick(() => { notif.close(handle) })}>
            Ok
        </Button>
    );
}

export default ActionOk;
