import _ from 'lodash';

export default function builder(sdk) {

    return {

        clear: () => {
            return async (dispatch) => {
                await Promise.all([
                    dispatch(sdk.devices.clear()),
                    dispatch(sdk.sessions.clear()),
                    dispatch(sdk.orders.clear()),
                    dispatch(sdk.orderlines.clear()),
                    dispatch(sdk.parameters.clear()),
                    dispatch(sdk.users.clear()),
                    dispatch(sdk.customers.clear()),
                    dispatch(sdk.paymentmethods.clear()),
                    dispatch(sdk.invoices.clear()),
                ]);
            }
        },

        loadDevices: (auth, customer) => {
            return async (dispatch) => {
                await dispatch(sdk.devices.count(auth, customer, true));
                await dispatch(sdk.devices.list(auth, customer, true));
                await dispatch(sdk.orderlines.list(auth, customer));
            }
        },

        loadSessions: (auth, customer) => {
            return async (dispatch) => {
                await dispatch(sdk.sessions.count(auth, customer, true));
                await dispatch(sdk.sessions.list(auth, customer, true));
                await dispatch(sdk.orderlines.list(auth, customer));
            }
        },

        loadRelations: (auth, customer) => {
            return async (dispatch) => {
                await dispatch(sdk.relations.count(auth, customer));
                let relations = await dispatch(sdk.relations.list(auth, customer));
                /*if (relations) {
                    relations = await Promise.all(relations.map(r =>
                        dispatch(sdk.relations.get(auth, r, query))
                    ));
                }*/
            }
        },

        loadReferencesAndProducts: (auth, query) => {
            return async (dispatch) => {
                await Promise.all([
                    dispatch(sdk.prices.count(auth, query)),
                    dispatch(sdk.prices.list(auth, query, true)),
                    dispatch(sdk.references.list(auth, query, true)),
                    dispatch(sdk.products.list(auth, query, true))
                ]);
            }
        },


        loadOrders: (auth, customer) => {
            return async (dispatch) => {
                await dispatch(sdk.orders.list(auth, customer, true));
                await dispatch(sdk.orderlines.list(auth, customer, true));
            }
        },

        setPaymentMethodAsDefault: (auth, id, query) => {
            return async (dispatch) => {
                await dispatch(sdk.paymentmethods.makeDefault(auth, id, query));
                dispatch(sdk.companies.get(auth, query.company, { ...query, company: undefined }, true));
            }
        },

    }

}