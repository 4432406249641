import React, { useMemo, useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import _ from 'lodash';

import { useDispatch } from 'react-redux';
import { useSdk } from '../../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../../lib/auth0-wrapper';
import { useNotif } from '../../../../common/component/notification';
import ActionOk from '../../../../common/component/notification/action-ok';
import OrderFinishingView from './order-finished';
import { useCustomer } from '../../../../customers/component/customer-provider';
import { useMainCompany } from '../../../../relations/component/user-relation-provider';
import { useViewingAsDistributor, useViewingAsVayanData } from '../../../../customers/selectors';
import { useTranslation } from 'react-i18next';

export default function MakeOrderForDevices({ price, memories, activeStep, ...props }) {

    const [done, setDone] = useState(false);
    const [error, setError] = useState(false);

    const auth = useAuth0();
    const dispatch = useDispatch();
    const sdk = useSdk();
    const notif = useNotif();
    const devices = useMemo(() => _.cloneDeep(memories[activeStep - 1]), [memories, activeStep]);
    const customer = useCustomer();

    const isVayanData = useViewingAsVayanData();
    const isDistributor = useViewingAsDistributor();
    const company = useMainCompany();
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.modal.done' });

    useEffect(() => {
        const imp = isVayanData ? sdk.impersonateByVayanData(company.target) :
            isDistributor ? sdk.impersonateByDistributor(company.target) :
                sdk;

        dispatch(imp.orderlines.putForDevices(auth, devices, price.target, customer.target))
            .then((data) => {
                notif.info(t('process-done'), { action: (key) => <ActionOk notif={notif} handle={key} /> });
                return Promise.resolve(data);
            })
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
                setError(e);
            })
            .finally(() => {
                setDone(true);
            });
    }, []);

    return (
        <OrderFinishingView {...props} done={done} error={error} />
    );
}

export function MakeOrderForDevicesLabel() {
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.modal.done' })

    return (
        <span>
            <Typography display='inline'>
                {t('label')}
            </Typography>
        </span>
    )
}