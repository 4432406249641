import React from 'react';
import {
    Chip,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useOpenOrdersCount } from '../../selectors';
import { useSidebarSelected } from '../../../common/sidebar';

function OrderCartSidebarButton({ onClick, selected, ...props }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.sidebar-button' })
    const sidebarClasses = useSidebarSelected(selected)
    const openOrdersCount = useOpenOrdersCount();


    return (
        <ListItem {...props} button onClick={onClick}>
            <ListItemText
                primaryTypographyProps={{ className: sidebarClasses.text }}
                primary={t('cart-label')} />
            {openOrdersCount > 0 && <ListItemSecondaryAction>
                <Chip size='small' max={99} label={openOrdersCount} color='secondary' />
            </ListItemSecondaryAction>}
        </ListItem >
    )

}

export default OrderCartSidebarButton;
