import React from 'react';
import ProductHeader from './product-header';
import ProductContent from './product-content';
import ProductContentLoading from './product-content-loading';
import { Grid } from '@material-ui/core';
import PageHeader from '../../../common/component/page-header';

export default function ProductOverview({ product }) {

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <PageHeader>
                    <ProductHeader product={product.target} />
                </PageHeader>
            </Grid>
            <Grid item xs={12}>
                {product.payload
                    ? <ProductContent product={product.target} />
                    : <ProductContentLoading />
                }
            </Grid>
        </Grid>
    )

}
