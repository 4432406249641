import _ from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useSdk } from '../../lib/licensing-sdk';
import { useCustomer } from '../customers/component/customer-provider';
import { DEVICE } from '../products/product-constants';
import { STATUS_REVOKED, STATUS_STOPPED, STATUS_UNPAID } from './constants';

export function useLinesUnassociatedWithDevice() {
    const sdk = useSdk();
    const customer = useCustomer();
    const selector = useMemo(() => createSelector(
        sdk.orderlines.selectors.getDb,
        (orderlines) => {
            const list = _.chain(orderlines)
                .mapValues()
                .filter(line => line.payload && line.payload.customer === customer.target)
                .filter(line => line.payload.for === DEVICE && line.payload.device === null)
                .filter(line => ![STATUS_UNPAID, STATUS_REVOKED, STATUS_STOPPED].includes(line.payload.status))
                .value();
            return list;
        }
    ), [customer]);
    return useSelector(selector);
}

export function useOrderLineById(id) {
    const sdk = useSdk();
    const selector = useMemo(() => createSelector(
        sdk.orderlines.selectors.getDb,
        (orderlines) => orderlines[id]
    ), []);
    return useSelector(selector);
}

export function useListOrderLinesForDevice(device) {
    const sdk = useSdk();
    const selector = useMemo(() => createSelector(
        sdk.orderlines.selectors.getDb,
        (orderlines) => {
            const list = _.chain(device.payload.lines)
                .map(line => orderlines[line])
                .compact()
                .value();
            return list;
        }
    ), [device.target]);
    return useSelector(selector);
}

export function makeGetOrderItemsListForService(sdk) {
    return createSelector(
        sdk.orderitems.selectors.getDb,
        (state, service) => service,
        (db, service) => {
            return _.filter(db, l => l && l.payload && l.payload.service === service);
        }
    );
}

export function makeOrderItemsPopulatedList(sdk) {
    return createSelector(
        sdk.orderitems.selectors.getDb,
        (state, list) => list || [],
        (orderItems, list) => {
            let items = _.map(list, o => orderItems[o]);
            items = _.compact(items);
            return items;
        }
    )
}

export function makeGetOrderlinesFromTarget(sdk, target) {
    return createSelector(
        sdk.orderlines.selectors.getDb,
        db => {
            let items = target.payload.lines;
            items = items.map(item => db[item]);
            items = _.compact(items);
            return items;
        }
    );
}