import React, { Fragment } from 'react';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, List, ListItem, ListSubheader, Typography } from "@material-ui/core";
import ChangeTrustPolicySidebarButton from '../change-trust-policy/change-trust-policy-sidebar-button';
import ChangeInvoicePolicySidebarButton from '../change-invoice-policy/change-invoice-policy-sidebar-button';
import ChangeCoefficientByVayanDataSidebarButton from '../change-coefficient/change-coefficient-by-vayandata-sidebar-button';
import { useCustomer } from '../customer-provider';
import { companyName } from '../../../companies/component/elements/company-name';
import { useCustomerIsDistributor } from '../../selectors';
import { CustomersListInternal } from '../customers-list';
import CreateCompanyByDistributorSidebarButton from '../../../companies/component/create-company-modal/distributor/create-company-by-distributor-sidebar-button';
import CustomerTrustPolicy from '../elements/customer-trust-policy';
import CustomerInvoicePolicy from '../elements/customer-invoice-policy';
import CustomerCoefficient from '../elements/customer-coefficient';
import ChangeDaysUntilDueSidebarButton from '../change-days-until-due/change-days-until-due-sidebar-button';
import CustomerDaysUntilDue from '../elements/customer-days-until-due';
import GenerateInvoiceListButton from '../generate-invoice/generate-invoice-list-button';
import { CustomersOrderSidebarButton } from '../customer-orders';
import CustomerName from '../elements/customer-name';
import ChangeDistributorSidebarButton from '../change-distributor/change-distributor-sidebar-button';
import CustomerGrade from '../elements/customer-grade';
import { useTranslation } from 'react-i18next';

export default function CustomerOverviewByVayanData({ defaultCustomer, disableGenerateInvoiceButton, enableChangeDistributor, ...props }) {

    const currentCustomer = useCustomer();
    const customer = defaultCustomer || currentCustomer;
    const isDistributor = useCustomerIsDistributor();
    const isDistributed = customer?.payload?.distributor_id;
    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.overview.by-vayandata' })

    return (
        <ExpansionPanel expanded>
            <ExpansionPanelSummary>
                <Typography>{t('title', { name: companyName(customer) })}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <List subheader={
                            <ListSubheader component="div">
                                {t('status', { name: companyName(customer) })}
                            </ListSubheader>
                        }>
                            <ListItem>
                                <Typography display='inline'>{t('grade')}</Typography>
                                <CustomerGrade display='inline' noCoefficient customer={customer.target} />
                            </ListItem>
                            <ListItem>
                                <Typography display='inline'>{t('coefficient')} </Typography>
                                <CustomerCoefficient display='inline' customer={customer.target} />
                            </ListItem>
                            {!isDistributed && <ListItem>
                                <Typography display='inline'>{t('trust-policy')}</Typography>
                                <CustomerTrustPolicy display='inline' customer={customer.target} />
                            </ListItem>}
                            {!isDistributed && <ListItem>
                                <Typography display='inline'>{t('invoice-policy')}</Typography>
                                <CustomerInvoicePolicy display='inline' customer={customer.target} />
                            </ListItem>}
                            {!isDistributed && <ListItem>
                                <Typography display='inline'>{t('days-until-due')}</Typography>
                                <CustomerDaysUntilDue display='inline' customer={customer.target} />
                            </ListItem>}
                            <ListItem>
                                <Typography display='inline'>{t('distributed-by')}</Typography>
                                {isDistributed ? (
                                    <CustomerName display='inline' customer={customer?.payload?.distributor_id} />
                                ) : (<Typography display='inline'>{t('no-one')}</Typography>)}
                            </ListItem>
                        </List>
                    </Grid>

                    <Grid item xs={6}>
                        <List subheader={
                            <ListSubheader component="div">
                                {t('admin-actions', { name: companyName(customer) })}
                            </ListSubheader>
                        }>
                            <ChangeCoefficientByVayanDataSidebarButton defaultCustomer={customer} />
                            {!isDistributed && <ChangeTrustPolicySidebarButton defaultCustomer={customer} />}
                            {!isDistributed && <ChangeInvoicePolicySidebarButton defaultCustomer={customer} />}
                            {!isDistributed && <ChangeDaysUntilDueSidebarButton defaultCustomer={customer} />}
                            {!isDistributed && !disableGenerateInvoiceButton &&
                                <GenerateInvoiceListButton defaultCustomer={customer} />}
                            {enableChangeDistributor &&
                                <ChangeDistributorSidebarButton defaultCustomer={customer} />
                            }
                        </List>
                    </Grid>
                    {isDistributor && (
                        <Fragment>
                            <Grid item xs={6}>
                                <List subheader={
                                    <ListSubheader component="div" id="nested-list-subheader">
                                        {t('actions', { name: companyName(customer) })}
                                    </ListSubheader>
                                }>
                                    <CreateCompanyByDistributorSidebarButton distributor={customer} />
                                    <CustomersOrderSidebarButton buyer={customer} />
                                </List>
                            </Grid>
                            <Grid item xs={6}>
                                <CustomersListInternal subheader={
                                    <ListSubheader component="div" id="nested-list-subheader">
                                        {t('customer-label', { name: companyName(customer) })}
                                    </ListSubheader>
                                } />
                            </Grid>
                        </Fragment>
                    )}
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>

    )
}
