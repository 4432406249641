import React, { Fragment, useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { useImpersonateSdk } from '../../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../../lib/auth0-wrapper';
import ActionOk from '../../../../common/component/notification/action-ok';
import { useNotif } from '../../../../common/component/notification';
import { useCustomer } from '../../../../customers/component/customer-provider';
import { useViewingAsVayanData } from '../../../../customers/selectors';
import { Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function MarkPaidButton({ onClick, ...props }) {

    const [open, setOpen] = useState(false);
    const { t } = useTranslation('translation', { keyPrefix: 'app.invoices.actions.vayandata.mark-as-paid' });
    const handleClose = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(false);
    }
    const handleOpen = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(true);
    }
    const handleConfirm = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        onClick();
        setOpen(false);
    }

    return (
        <Fragment>
            <Button
                onClick={handleOpen}
                {...props}
            >{t('button-label')}</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('title')}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        {t('cancel')}
                    </Button>
                    <Button onClick={handleConfirm} color="primary">
                        {t('confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default function MarkPaidInvoice({ invoice, ...props }) {

    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const notif = useNotif();
    const dispatch = useDispatch();

    const isAdmin = useViewingAsVayanData();

    const customer = useCustomer();
    const [loading, setLoading] = useState(false);

    const onClick = useCallback((ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setLoading(true);
        dispatch(sdk.invoices.paid(auth, invoice.target, customer.target))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            })
            .finally(() => setLoading(false));

        // eslint-disable-next-line
    }, [invoice, customer]);

    const cannotMarkPaid = ['draft', 'paid', 'uncollectible', 'void'].includes(invoice.payload.status);


    if (!isAdmin) return null;
    if (cannotMarkPaid) return null;

    return (
        <MarkPaidButton
            {...props}
            disabled={loading}
            color='primary'
            size='small'
            onClick={onClick}
        />
    )
}

