import React from 'react';
import { makeStyles, Select, MenuItem } from '@material-ui/core';
import { useCustomersList } from '../selectors';
import { useChangeCustomer, useCustomer } from './customer-provider';
import { useMainCompany } from '../../relations/component/user-relation-provider';
import CustomerName from './elements/customer-name';
import CustomerGrade from './elements/customer-grade';
import CompanyGrade from '../../companies/component/elements/company-grade';
import CompanyName from '../../companies/component/elements/company-name';
import DomainIcon from '@material-ui/icons/Domain';
import CustomerOnboarding from './elements/customer-onboarding';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    align: {
        verticalAlign: 'middle',
        display: 'inline-block',
    },
    icon: {
        margin: `0 ${theme.spacing(2)}px 0 ${theme.spacing(1)}px`
    },
    secondary: {
        fontSize: theme.typography.overline.fontSize,
        color: theme.palette.text.secondary,
    },
    review: {
        fontSize: theme.typography.overline.fontSize,
        color: theme.palette.warning.main,
    }
}));

export function MainCompanySelectItem({ company, ...props }) {
    const classes = useStyles();

    return (
        <div>
            <span className={classes.align}>
                <DomainIcon className={classes.icon} />
            </span>
            <span className={classes.align}>
                <CompanyName {...props} company={company} />
                <CompanyGrade  {...props} className={classes.secondary} capitalize company={company} />
            </span>
        </div>
    )
}

function CustomerSelectItem({ customer }) {
    const classes = useStyles();

    return (
        <span>
            <CustomerName customer={customer} />
            <CustomerGrade className={classes.secondary}
                capitalize customer={customer} />
            <CustomerOnboarding className={classes.review}
                capitalize customer={customer} />
        </span>
    )
}

function CustomerSelect({ ...props }) {
    const classes = useStyles();

    const company = useMainCompany();
    const customers = useCustomersList();
    const customer = useCustomer();
    const change = useChangeCustomer();

    const selectCb = (ev) => {
        change(ev.target.value);
    };

    return (
        <Select className={classes.root} value={customer ? customer.target : ''} onChange={selectCb} >
            <MenuItem value={company.target} >
                <MainCompanySelectItem company={company.target} />
            </MenuItem>
            {customers.map(customer => {
                return (
                    <MenuItem key={customer} value={customer} >
                        <CustomerSelectItem customer={customer} />
                    </MenuItem>
                )
            }
            )}
        </Select>
    );
}

export default CustomerSelect;