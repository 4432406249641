import React, { useMemo, useCallback } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSdk } from '../../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../../lib/auth0-wrapper';
import { useDispatch } from 'react-redux';
import CreditCardContent, { CreditCardAvatar } from './credit-card-content';
import { useNotif } from '../../../../common/component/notification';
import ActionOk from '../../../../common/component/notification/action-ok';
import { useCustomer, useCustomerAPIAuthorization } from '../../../../customers/component/customer-provider';
import { useTranslation } from 'react-i18next';

export default function PaymentMethodItem({ paymentMethod }) {

    const { status, payload, target } = paymentMethod;
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.forms.payment-method-form.payment-method-item' })
    const customer = useCustomer();
    const { customer: stripeInfos, } = customer.payload;
    const { distributor, vayanData } = useCustomerAPIAuthorization();

    const dispatch = useDispatch();
    const sdk = useSdk();
    const auth = useAuth0();

    const notif = useNotif();

    const onDelete = useCallback((ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        dispatch(sdk.paymentmethods.remove(auth, target, { company: customer.target, distributor, vayanData }))
            .then((data) => {
                notif.info(t('removed'), { action: (key) => <ActionOk notif={notif} handle={key} /> });
                return Promise.resolve(data);
            })
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> })
            });
    }, [target]);

    const onMakeDefault = useCallback((ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        dispatch(sdk.helpers.setPaymentMethodAsDefault(auth, target, { company: customer.target, distributor, vayanData }))
            .then((data) => {
                notif.info(t('default-card-changed'), { action: (key) => <ActionOk notif={notif} handle={key} /> });
                return Promise.resolve(data);
            })
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> })
            });
    }, [target]);

    const isCard = useMemo(() => payload.type === 'card', [payload]);
    const disabled = useMemo(() => !customer.status.endsWith('success'), [customer.status]);
    const isDefault = useMemo(() => stripeInfos && target === stripeInfos.invoice_settings.default_payment_method, [stripeInfos, target]);
    const isLoading = useMemo(() => !status.endsWith('success'), [status]);

    return (
        <ListItem>
            <ListItemAvatar>
                {isCard ? <CreditCardAvatar /> : null}
            </ListItemAvatar>
            <ListItemText
                primary={isCard ? <CreditCardContent card={payload.card} /> : null}
                secondary={isDefault ? t('default-payment-method') : null}
            />
            <ListItemSecondaryAction>
                {!isDefault &&
                    <Button onClick={onMakeDefault} disabled={disabled || isLoading} aria-label="make default" color='secondary'>
                        {t('make-default')}
                    </Button>
                }
                <IconButton onClick={onDelete} disabled={isLoading} aria-label="delete" >
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
}
