import React from 'react';
import { makeStyles } from '@material-ui/styles';
import CardContent from '@material-ui/core/CardContent';
import ProductDescription from '../element/product-description';
import ProductTitle from '../element/product-title';
import ProductLink from '../element/product-link';

const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: theme.spacing(2),
    },
    description: {
        width: '100%',
    },
}));

function ProductHeader(props) {
    const { product } = props;
    const classes = useStyles();

    return (
        <CardContent>
            <ProductTitle product={product} className={classes.title} gutterBottom variant="h4" />
            <ProductDescription product={product} className={classes.description} variant="body2" />
            <ProductLink product={product} />
        </CardContent>
    )
}

export default ProductHeader;
