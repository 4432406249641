import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { useSdk } from '../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../lib/auth0-wrapper';
import { useDispatch } from 'react-redux';
import { useNotif } from '../../../common/component/notification';
import { useMainCompany } from '../../../relations/component/user-relation-provider';
import ActionOk from '../../../common/component/notification/action-ok';
import { useCustomer } from '../../../customers/component/customer-provider';
import { useViewingAsVayanData } from '../../../customers/selectors';
import { useAssociatedPricesByReferences, usePricesByReferences, usePricesForCustomer } from '../../../prices/selector';
import { useReferencesListByProductId } from '../../../references/selector';
import { FormControlLabel, makeStyles, Switch } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    padded: {
        padding: theme.spacing(2)
    }
}))

const useHandleChange = () => {
    const sdk = useSdk();
    const auth = useAuth0();
    const dispatch = useDispatch();
    const notif = useNotif();

    const isVayanData = useViewingAsVayanData();
    const company = useMainCompany();
    const customer = useCustomer();

    const [loading, setLoading] = useState(false);
    const handleChange = useCallback((ev, prices, associatedPrices) => {
        ev.preventDefault();
        ev.stopPropagation();

        setLoading(true);

        if (ev.target.checked) {
            const imp = isVayanData ?
                sdk.impersonateByVayanData(company.target) :
                sdk.impersonateByDistributor(company.target);

            const pricesToAdd = _.uniq(_.concat(prices, associatedPrices));
            dispatch(imp.customers.putPrices(auth, pricesToAdd, customer.target))
                .catch((e) => {
                    notif.error(e, { persist: true, action: (key) => <ActionOk notif={notif} handle={key} /> })
                })
                .finally(() => setLoading(false));
        } else {
            const imp = isVayanData ?
                sdk.impersonateByVayanData(company.target) :
                sdk.impersonateByDistributor(company.target);

            dispatch(imp.customers.removePrices(auth, prices, customer.target))
                .catch((e) => {
                    notif.error(e, { persist: true, action: (key) => <ActionOk notif={notif} handle={key} /> })
                })
                .finally(() => setLoading(false));
        }
        // eslint-disable-next-line
    }, []);

    return [loading, handleChange];
}


export function ShareProductSwitch({ product, ...props }) {

    const classes = useStyles();
    const references = useReferencesListByProductId(product.target);
    const prices = usePricesByReferences(references);
    const associatedPrices = useAssociatedPricesByReferences(references);
    const customerPrices = usePricesForCustomer();
    const [loading, handleChange] = useHandleChange();
    const checked = _.reduce(prices, (checked, price) => checked && customerPrices.indexOf(price) !== -1, true);

    return (
        <FormControlLabel
            className={classes.padded}
            control={
                <Switch
                    disabled={loading}
                    edge="end"
                    checked={checked}
                    onChange={(ev) => handleChange(ev, prices, associatedPrices)}
                />
            }
            label="Share"
            labelPlacement="start"
        />
    )
}