import ChooseDevices, { ChooseDevicesLabel } from './choose-devices';
import CheckDevices, { CheckDevicesLabel } from './check-devices';
import MakeOrderForDevices, { MakeOrderForDevicesLabel } from './make-order-for-devices';
import { SESSION } from '../../../../products/product-constants';
import SetSessionQuantity, { SetSessionQuantityLabel } from './set-session-quantity';
import SetDeviceQuantity, { SetDeviceQuantityLabel } from './set-device-quantity';
import MakeOrderForSessions, { MakeOrderForSessionsLabel } from './make-order-for-sessions';
import CheckSessions, { CheckSessionsLabel } from './check-sessions';
import CheckDevicesQuantity, { CheckDevicesQuantityLabel } from './check-devices-quantity';

export default function stepGenerator(product) {

    if (product.payload.works_for === SESSION) {
        return [
            {
                label: SetSessionQuantityLabel,
                component: SetSessionQuantity,
            },
            {
                label: MakeOrderForSessionsLabel,
                component: MakeOrderForSessions,
            },
        ]
    } else {
        return [
            {
                label: SetDeviceQuantityLabel,
                component: SetDeviceQuantity,
            },
            {
                label: MakeOrderForDevicesLabel,
                component: MakeOrderForDevices,
            },
        ]
    }
}
