import React from 'react';

import { RelationsOverviewTable } from '../../relations/component/table';
import { Card, CardHeader, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../common/component/page-header';

function UserList(props) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.users.list' })

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <PageHeader title={t('title')} />
            </Grid>
            <Grid item xs={12}>
                <RelationsOverviewTable {...props} />
            </Grid>
        </Grid>
    )

}

export default UserList;
