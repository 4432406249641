import React from 'react';
import { Chip, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function Status({ order, ...props }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.elements.status' });

    let statusStr = t(order.payload.status);
    let tooltip = null;
    if (order.payload.admin.accepted_at) {
        statusStr = t('accepted');
        tooltip = t('tooltip.admin-accepted');
    }

    if (order.payload.distributor.accepted_at) {
        statusStr = t('accepted');
        tooltip = t('tooltip.distributor-accepted');
    }

    const chip = <Chip variant='outlined' color='primary' {...props} label={statusStr} />;

    if (tooltip) {
        return (
            <Tooltip title={tooltip} >
                {chip}
            </Tooltip>
        )
    } else {
        return chip;
    }
}

function OrderStatus({ order, ...props }) {
    return (
        <Status order={order} {...props} />
    )
}

export default OrderStatus;
