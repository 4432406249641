import React from 'react';
import { useAvailableSessionsByReferenceForCustomer } from '../../../selector';
import _ from 'lodash';
import CenteredFullScreen from '../../../../common/component/centered-full-screen';
import {
    ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary,
    List, ListItem, ListItemSecondaryAction, ListItemText, Typography
} from '@material-ui/core';
import AssociateButton from '../../actions/associate';
import { makeStyles } from '@material-ui/styles';
import { useCustomer } from '../../../../customers/component/customer-provider';
import { useReferenceName } from '../../../../references/component/element/reference-name';
import { useReferenceById } from '../../../../references/selector';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    fullwidth: {
        width: "100%",
    }
}));

export const NoAvailableSessions = ({ }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'app.sessions.pages.available-sessions' })

    return (
        <CenteredFullScreen>
            <Typography>
                {t('no-sessions')}
            </Typography>
        </CenteredFullScreen>
    )
}

const ReferenceListItem = ({ reference, sessions }) => {

    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.sessions.pages.available-sessions' })
    reference = useReferenceById(reference);

    return (
        <ListItem className={classes.fullwidth}>
            <ListItemText
                primary={useReferenceName(reference)}
                secondary={t('available-licences', { count: sessions.length })}
            />
            <ListItemSecondaryAction><AssociateButton session={sessions[0]} /></ListItemSecondaryAction>
        </ListItem>
    )
}

const AvailableSessionsByReference = ({ byReference }) => {

    const classes = useStyles();

    return (
        <List className={classes.fullwidth}>
            {_.map(byReference, (sessions, reference) => (
                <ReferenceListItem key={reference} reference={reference} sessions={sessions} />
            ))}
        </List>
    )
}

export default function AvailableSessions({ title, ...props }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.sessions.pages.available-sessions' })
    title = title || t('title');
    const customer = useCustomer();
    const byReference = useAvailableSessionsByReferenceForCustomer(customer.target);

    return (
        <ExpansionPanel defaultExpanded={true}>
            <ExpansionPanelSummary>
                <Typography variant='h4'>{title}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                {_.keys(byReference).length === 0
                    ? <NoAvailableSessions {...props} />
                    : <AvailableSessionsByReference {...props} byReference={byReference} />
                }
            </ExpansionPanelDetails>
        </ExpansionPanel>
    )
}