import React from 'react';
import {
    Grid, Typography,
} from '@material-ui/core';
import CompanyStreetField from '../fields/company-street-field';
import CompanyStreetOptField from '../fields/company-street-opt-field';
import CompanyPostCodeField from '../fields/company-post-code-field';
import CompanyCityField from '../fields/company-city-field';
import CompanyCountyField from '../fields/company-county-field';
import CompanyCountryField from '../fields/company-country-field';
import { useTranslation } from 'react-i18next';

export default function AddressFormGrid(props) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.forms.address-form.views.grid' })
    return (
        <Grid container spacing={2} alignItems='center'>

            <Grid item xs={3}><Typography>{t('street')}</Typography></Grid>
            <Grid item xs={9}>
                <CompanyStreetField {...props} />
            </Grid>

            <Grid item xs={3}><Typography>{t('street-2nd')}</Typography></Grid>
            <Grid item xs={9}>
                <CompanyStreetOptField {...props} />
            </Grid>

            <Grid item xs={3}><Typography>{t('post-code')}</Typography></Grid>
            <Grid item xs={9}>
                <CompanyPostCodeField {...props} />
            </Grid>

            <Grid item xs={3}><Typography>{t('city')}</Typography></Grid>
            <Grid item xs={9}>
                <CompanyCityField {...props} />
            </Grid>

            <Grid item xs={3}><Typography>{t('country')}</Typography></Grid>
            <Grid item xs={9}>
                <CompanyCountryField {...props} />
            </Grid>

            <Grid item xs={3}><Typography>{t('county')}</Typography></Grid>
            <Grid item xs={9}>
                <CompanyCountyField {...props} />
            </Grid>

        </Grid>
    )
}
