import React from 'react';
import { Field } from 'formik';
import { Select } from 'formik-material-ui';
import { makeStyles } from '@material-ui/styles';
import { MenuItem, FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import _ from 'lodash';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    field: {
        width: '100%'
    }
}));

export const EUR = 'EUR';
export const CURRENCIES = [EUR];

export const currencySchema = {
    currency: Yup.string().oneOf(CURRENCIES).required('Please fill in the billing email'),
}

const CompanyCurrencyField = ({ namespace, errors, disabled, isSubmitting }) => {

    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.forms.billing-form.fields.company-currency-field' });

    const name = `${namespace ? namespace + '.' : ''}currency`;
    const error = _.get(errors, name);

    return (
        <FormControl className={classes.field} error={Boolean(error)}>
            <InputLabel id="company-currency-field">{t('label')}</InputLabel>
            <Field
                labelId="company-currency-field"
                component={Select}
                name={name}
                disabled={disabled || isSubmitting}
                validate={value => CURRENCIES.includes(value) ? undefined : t('validation')}
            >
                {CURRENCIES.map(c => <MenuItem key={c} value={c}>{c}</MenuItem>)}
            </Field>
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    )
}


export default CompanyCurrencyField;
