import { Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import CompanyCityField from '../forms/address-form/fields/company-city-field';
import CompanyCountryField from '../forms/address-form/fields/company-country-field';
import CompanyCountyField from '../forms/address-form/fields/company-county-field';
import CompanyPostCodeField from '../forms/address-form/fields/company-post-code-field';
import CompanyStreetField from '../forms/address-form/fields/company-street-field';
import CompanyStreetOptField from '../forms/address-form/fields/company-street-opt-field';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    gutter: {
        marginLeft: theme.spacing(2),
        borderLeft: '1px solid #ccc',
    }
}));


export function addressInitialValues() {
    return {
        address: {
            street: '',
            street_opt: '',
            city: '',
            post_code: '',
            county: '',
            country: 'France',
        }
    }
}

export default function AddressFormForCreation(props) {
    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.create-company-modal.create-company-address' });

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography>{t('title')}</Typography>
            </Grid>
            <Grid className={classes.gutter} container alignItems="center" spacing={2} item xs={12}>
                <Grid item xs={3}>
                    <Typography>{t('street')}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <CompanyStreetField {...props} />
                </Grid>
                <Grid item xs={3}>
                    <Typography>{t('street-2nd')}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <CompanyStreetOptField {...props} />
                </Grid>
                <Grid item xs={3}>
                    <Typography>{t('post-code')}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <CompanyPostCodeField {...props} />
                </Grid>
                <Grid item xs={3}>
                    <Typography>{t('city')}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <CompanyCityField {...props} />
                </Grid>
                <Grid item xs={3}>
                    <Typography>{t('country')}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <CompanyCountryField {...props} />
                </Grid>
                <Grid item xs={3}>
                    <Typography>{t('county')}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <CompanyCountyField {...props} />
                </Grid>
            </Grid>
        </Grid>
    )
}
