import React, { Fragment } from 'react';
import AcceptOrderButton from '../actions/distributor/accept-order';
import PaymentReceivedButton from '../actions/distributor/payment-received';
import ReopenOrderButton from '../actions/distributor/reopen';
import VoidOrderButton from '../actions/customer/void';
import FinalizeOrderButton from '../actions/customer/finalize';
import ReviewOrderButton from '../actions/customer/review';
import RevokeOrderButton from '../actions/vayandata/revoke-order';
import ValidateOrderButton from '../actions/vayandata/validate-order';
import { useViewingAsDistributor, useViewingAsVayanData } from '../../../customers/selectors';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    actionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    namespace: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }
}));

const AdminActions = ({ order, onFinalize, ...props }) => {
    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.details.actions' })

    return (
        <Box className={classes.namespace}>
            <Typography align='center'>
                {t('admin-actions-title')}
            </Typography>
            <Box className={classes.actionsContainer}>
                <ValidateOrderButton {...props} order={order} />
                <RevokeOrderButton {...props} order={order} />
            </Box>
        </Box>
    )
}

const DistributorActions = ({ order, onFinalize, ...props }) => {
    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.details.actions' })

    return (
        <Box className={classes.namespace}>
            <Typography align='center'>
                {t('distributor-actions-title')}
            </Typography>
            <Box className={classes.actionsContainer}>
                <PaymentReceivedButton {...props} order={order} />
                <ReopenOrderButton {...props} order={order} />
                <AcceptOrderButton {...props} order={order} />
            </Box>
        </Box>
    )
}

const CustomerActions = ({ noHeading = false, onFinalize, order, ...props }) => {
    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.details.actions' })

    return (
        <Box className={classes.namespace}>
            {!noHeading && (
                <Typography align='center' >
                    {t('customer-actions-title')}
                </Typography>
            )}
            <Box className={classes.actionsContainer}>
                <VoidOrderButton {...props} order={order} />
                <FinalizeOrderButton {...props} onFinalize={onFinalize} order={order} />
                <ReviewOrderButton {...props} onFinalize={onFinalize} order={order} />
            </Box>
        </Box>
    )
}

function OrderActions(props) {

    const viewByAdmin = useViewingAsVayanData();
    const viewByDistributor = useViewingAsDistributor();

    if (viewByAdmin) {
        return (
            <Fragment>
                <AdminActions {...props} />
                <DistributorActions {...props} />
                <CustomerActions {...props} />
            </Fragment>
        )
    } else if (viewByDistributor) {
        return (
            <Fragment>
                <DistributorActions {...props} />
                <CustomerActions {...props} />
            </Fragment>
        )
    } else {
        return (
            <CustomerActions noHeading {...props} />
        )
    }
}

export default OrderActions;
