import React from 'react';
import {
    ListItem,
    ListItemText,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSidebarSelected } from '../../../common/sidebar';

function OrderHistorySidebarButton({ onClick, selected, ...props }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.sidebar-button' })
    const sidebarClasses = useSidebarSelected(selected)

    return (
        <ListItem {...props} button onClick={onClick}>
            <ListItemText
                primaryTypographyProps={{ className: sidebarClasses.text }}
                primary={t('history-label')} />
        </ListItem >
    )

}

export default OrderHistorySidebarButton;
