import React from 'react';
import { getProductDeviceName } from '../../../../products/component/element/product-device-name';
import { Typography } from '@material-ui/core';
import { useReferenceName } from '../../../../references/component/element/reference-name';
import { useTranslation } from 'react-i18next';

export function NoOrderFound({ product, reference }) {
    const deviceName = getProductDeviceName(product);
    const referenceName = useReferenceName(reference);
    const { t } = useTranslation('translation', { keyPrefix: 'app.order-lines.rules' });

    return (
        <span>
            <Typography>
                {t('no-order-found', { deviceName, reference: referenceName })}
            </Typography>
        </span>
    )
}
