import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    content: {
        width: '100%',
        left: 0,
        bottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
}));

function BCardMediaContent({ background, title, className, children, ...props }) {

    const classes = useStyles();

    return (
        <div className={classnames(classes.content, className)} {...props}>
            {children}
        </div>
    )
}

export default BCardMediaContent;
