import React, { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import PaymentMethodItem from './payment-method-item';
import { useDispatch } from 'react-redux';
import { useSdk } from '../../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../../lib/auth0-wrapper';
import { useNotif } from '../../../../common/component/notification';
import ActionOk from '../../../../common/component/notification/action-ok';
import CenteredFullScreen from '../../../../common/component/centered-full-screen';
import { Button, CircularProgress } from '@material-ui/core';
import { useCustomer, useCustomerAPIAuthorization } from '../../../../customers/component/customer-provider';
import { usePaymentMethodsFor } from '../../../selector';
import { useTranslation } from 'react-i18next';


function PaymentMethodList({ paymentMethods, loading, action, ...props }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.forms.payment-method-form.payment-method-list' });
    const customer = useCustomer();

    if (loading) {
        return <CircularProgress />
    }

    if (paymentMethods.length === 0) {
        return (
            <CenteredFullScreen>
                <Button size="small" variant='outlined' disabled={!customer.status.endsWith('success')} color='secondary' onClick={action}>
                    {t('add-card')}
                </Button>
            </CenteredFullScreen>
        )
    }

    return (
        <List aria-label="payment methods list">
            {paymentMethods.map((pm, i) => {
                if (!pm) {
                    return null;
                }
                return (
                    <div key={i}>
                        <PaymentMethodItem paymentMethod={pm} />
                        {i + 1 < paymentMethods.length && < Divider />}
                    </div>
                )
            })}
        </List>
    )
}

export default function PaymentMethodCtrl({ ...props }) {

    const dispatch = useDispatch();
    const sdk = useSdk();
    const auth = useAuth0();
    const notif = useNotif();
    const [loading, setLoading] = useState(true);

    const customer = useCustomer();
    const { distributor, vayanData } = useCustomerAPIAuthorization();
    const paymentMethods = usePaymentMethodsFor();

    useEffect(() => {
        dispatch(sdk.paymentmethods.list(auth, { company: customer.target, distributor, vayanData }))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> })
            }).finally(() => {
                setLoading(false);
            })
        // eslint-disable-next-line
    }, []);

    const innerProps = {
        paymentMethods,
        loading,
        ...props,
    }

    return React.createElement(PaymentMethodList, innerProps);
}
