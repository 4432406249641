import React from 'react';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { ResourceGetter } from '../../../../lib/licensing-sdk/getter';

function RelationLastUpdate({ relation }) {
    return (
        <ResourceGetter id={relation} resource='relations' render={({ relation }) =>
            <WaitingLoaded data={relation} render={() =>
                new Date(relation.payload.updated_at).toLocaleDateString()
            } />
        } />
    )
}

export default RelationLastUpdate;
