import React, { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '../../../lib/auth0-wrapper';

import EmailVerified from './email-verified';
import UserBlocked from './user-blocked';
import Unauthorized from './unauthorized';
import ServiceLocked from './service-locked';

function LoginFailed() {
    const { catchError, logout } = useAuth0();
    const [disable, setDisabled] = useState(false);

    const goHome = useCallback(() => {
        window.location.href = process.env.REACT_APP_PUBLIC_URL;
    }, []);

    const goActiveHome = useCallback(() => {
        setDisabled(true);
        logout();
    }, []);

    useEffect(() => {
        if (catchError === false || catchError.code === 'invalid') {
            goHome();
        }
    }, [catchError]);

    if (catchError && catchError.details) {
        switch (catchError.details.reason) {
            case 'email-verified':
                return <EmailVerified goHome={goActiveHome} disable={disable} id={catchError.details.id} email={catchError.details.email} />
            case 'user-blocked':
                return <UserBlocked goHome={goActiveHome} disable={disable}/>
            case 'service-locked':
                return <ServiceLocked goHome={goActiveHome} disable={disable}/>
            default:
                return <Unauthorized goHome={goActiveHome} disable={disable}/>
        }
    } else {
        return null; // This case is prevented with the useEffect above.
    }

}

export default LoginFailed;