import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import CompanyInvoicePolicyField, { INVOICE_POLICY_FOR_EACH_PURCHASE } from '../forms/company-invoice-policy-field';
import { useTranslation } from 'react-i18next';

export function invoicePolicyInitialValues() {
    return {
        invoice_policy: INVOICE_POLICY_FOR_EACH_PURCHASE,
    }
}

export default function InvoicePolicyFormForCreation(props) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.create-company-modal.create-company-invoice-policy' })
    return (
        <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
                <Typography>{t('title')}</Typography>
            </Grid>
            <Grid item xs={9}>
                <CompanyInvoicePolicyField {...props} />
            </Grid>
        </Grid>
    )
}
