import React from 'react';
import {
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import { People as PeopleIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

function SessionSidebarButton({ onClick, ...props }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.sessions.sidebar-button' })
    return (
        <ListItem {...props} button onClick={onClick}>
            <ListItemIcon><PeopleIcon /></ListItemIcon>
            <ListItemText primary={t('label')} />
        </ListItem >
    )

}

export default SessionSidebarButton;
