import React, { forwardRef, useCallback } from 'react';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useImpersonateSdk } from '../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../lib/auth0-wrapper';

import InteractiveLoadingButton from '../../../common/component/interactive-loading-button';
import { IconButton } from '@material-ui/core';
import ActionOk from '../../../common/component/notification/action-ok';
import { useNotif } from '../../../common/component/notification';
import { useCustomer } from '../../../customers/component/customer-provider';
import { useTranslation } from 'react-i18next';
import { useCompanyIsVayanData } from '../../../companies/selector';

const useStyles = makeStyles(theme => ({
    button: {
        marginRight: theme.spacing(1),
    },
}));

export const MarkFreeView = forwardRef(({ free, ...props }, ref) => {

    const classes = useStyles();

    return (
        <IconButton className={classes.button} size='small' {...props} ref={ref}>
            {
                free ?
                    <AttachMoneyIcon />
                    : <MoneyOffIcon />
            }
        </IconButton>
    )
});

export function MarkFreeController(props) {

    const { component, line, ...innerProps } = props;

    const free = line.payload.free;
    const prefix = free ? 'revert-free' : 'mark-free'
    const { t } = useTranslation('translation', { keyPrefix: `app.order-lines.actions.${prefix}` });
    const dispatch = useDispatch();
    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const notif = useNotif();

    const customer = useCustomer();

    const loading = line.status.includes('pending');
    const erred = line.status.includes('error');
    const disabled = loading || erred;
    const tooltip =
        loading ? t('tooltip.loading') :
            erred ? t('tooltip.error') :
                t('tooltip.default');

    innerProps.disabled = disabled;
    innerProps.free = free
    innerProps.onClick = useCallback(() => {
        if (free) {
            dispatch(sdk.orderlines.revertFree(auth, line.target, customer.target))
                .catch(e => {
                    notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
                });
        } else {
            dispatch(sdk.orderlines.markFree(auth, line.target, customer.target))
                .catch(e => {
                    notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
                });
        }
        // eslint-disable-next-line   
    }, [sdk, auth, free, line.target, dispatch, customer.target]);

    return (
        <InteractiveLoadingButton tooltip={tooltip} loading={loading} >
            {React.createElement(component, innerProps)}
        </InteractiveLoadingButton>
    )
}



export default function MarkFreeButton({ line, ...props }) {
    const isVD = useCompanyIsVayanData()

    if (!isVD) {
        return null;
    }

    return (
        <MarkFreeController
            {...props}
            line={line}
            component={MarkFreeView}
        />
    )
}
