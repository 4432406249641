import React from 'react';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { Typography } from '@material-ui/core';
import { ResourceGetter } from '../../../../lib/licensing-sdk/getter';
import { useTranslation } from 'react-i18next';

function LastLogin({ user, ...props }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.users.elements.last-login' })
    const value = user.payload.last_login ? new Date(user.payload.last_login).toLocaleDateString() : t('never');
    return <Typography {...props}>{value}</Typography>
}

function UserLastLogin({ user, relation, ...props }) {
    return (
        <ResourceGetter resource='users' id={user} query={{ relation }} render={({ user }) =>
            <WaitingLoaded data={user} render={() => <LastLogin user={user} {...props} />} />
        } />
    )
}

export default UserLastLogin;
