import React from 'react';
import {
    Chip,
    Collapse,
    List,
    ListItem, ListItemIcon, ListItemText,
    makeStyles,
} from '@material-ui/core';
import { ShoppingCart } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useSidebarSelected } from '../../common/sidebar';
import OrderCartSidebarButton from './sidebar/cart';
import OrderHistorySidebarButton from './sidebar/history';

const useStyles = makeStyles(theme => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));


const OrderSidebarButton = ({ pushCallback, selected, contains, ...props }) => {

    const classes = useStyles()
    const sidebarClasses = useSidebarSelected(selected)
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.sidebar-button' })

    const handleClick = (route) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        pushCallback(route);
    }

    return (
        <div className={sidebarClasses.sidebarItem}>
            <ListItem>
                <ListItemIcon><ShoppingCart className={sidebarClasses.icon} /></ListItemIcon>
                <ListItemText
                    primary={t('label')} />
            </ListItem>
            <Collapse in timeout="auto" unmountOnExit>
                <List dense component="div" disablePadding>
                    <OrderCartSidebarButton className={classes.nested}
                        selected={contains('orders/cart')} onClick={handleClick('orders/cart')} />
                    <OrderHistorySidebarButton className={classes.nested}
                        selected={contains('orders/history')} onClick={handleClick('orders/history')} />
                </List>
            </Collapse>
        </div>
    )
}

export default OrderSidebarButton;
