import React from 'react';
import { Grid } from '@material-ui/core';
import OpenOrdersPanel from '../details/open-order/open-orders-panel';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../common/component/page-header';

function OpenOrdersPage({ }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.layout.cart' });

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <PageHeader title={t('title')} />
            </Grid>
            <Grid item xs={12}>
                <OpenOrdersPanel />
            </Grid>
        </Grid>
    )
}

export default OpenOrdersPage;
