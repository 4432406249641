import currencies from '../common/currencies';
import _ from 'lodash';

export const usePriceName = (price) => {
    return price.payload.name;
}

export const usePriceCurrency = (price) => {
    return price.payload.currency;
}

export function computeGraduatedTiersPublicPrice(price, quantity, initialQuantity = 0) {
    const tiers = _.sortBy(price.payload.tiers, ['last', 'up_to']);
    const quantityPerTier = [];

    let lastUpTo = 0;
    for (let tier of tiers) {
        if (tier.last) {
            quantityPerTier.push({ quantity, tier, price: tier.amount * quantity });
            break; // terminal state
        } else if (tier.up_to <= initialQuantity) {
            lastUpTo = tier.up_to;
            continue; // skip the tiers that are below or equals the initialQuantity.
        } else {
            let quantityForTier = tier.up_to - lastUpTo;
            const quantityAlreadyInTier = initialQuantity < lastUpTo ? 0 : initialQuantity - lastUpTo;
            quantityForTier -= quantityAlreadyInTier;

            const quantityInTier = quantityForTier >= quantity ? quantity : quantityForTier;
            quantityPerTier.push({ quantity: quantityInTier, tier, price: tier.amount * quantityInTier });
            quantity -= quantityInTier;
            lastUpTo = tier.up_to;
            if (quantity <= 0) break; // terminal state
            else continue;
        }
    }

    return quantityPerTier;
}

export function applyCoefficientOnPrice(amount, coefficient) {
    return amount * coefficient;
}

export function makePriceStringWithCurrency(amount, customer) {
    return { currency: currencies[customer?.payload?.currency] || '', amount: amount.toFixed(2) };
}
