import _ from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useSdk } from '../../lib/licensing-sdk';
import { useCustomer } from '../customers/component/customer-provider';
import { useMainCompany } from '../relations/component/user-relation-provider';

export function useReferenceById(id) {
    const sdk = useSdk();
    const selector = useMemo(() => {
        return createSelector(
            sdk.references.selectors.getDb,
            (references) => {
                return references[id]
            }
        )
    }, [id]); 
    return useSelector(selector);
}

function useReferencesIdListFromPrices(prices) {
    const sdk = useSdk();
    const selector = useMemo(() => {
        return createSelector(
            sdk.prices.selectors.getDb,
            (pricesDb) => {
                const references = _.chain(prices)
                    .map(price => pricesDb[price])
                    .compact()
                    .map('payload.reference')
                    .uniq()
                    .value();
                return references;
            }
        )
    }, [prices]); 
    return useSelector(selector);
}

export function useReferencesIdListFromCompany() {
    const company = useMainCompany();
    const prices = useMemo(() => company.payload.prices, [company.target]); // eslint-disable-line react-hooks/exhaustive-deps
    return useReferencesIdListFromPrices(prices);
}

export function useReferencesIdListFromCustomer() {
    const customer = useCustomer();
    const prices = useMemo(() => customer.payload.prices, [customer.target]); // eslint-disable-line react-hooks/exhaustive-deps
    return useReferencesIdListFromPrices(prices);
}

export function useReferencesListByProductId(product) {
    const sdk = useSdk();
    const selector = useMemo(() => {
        return createSelector(
            sdk.references.selectors.getDb,
            (referencesDb) => {
                const references = _.chain(referencesDb)
                    .filter(ref => ref.payload.product === product)
                    .value();
                return references;
            }
        )
    }, [product]); 
    return useSelector(selector);
}