import _ from 'lodash';
import { combineReducers } from 'redux';
import Actions from '../actions';

const PaymentMethodsReducer = () => {

    const actions = new Actions('paymentmethods');

    const list = actions['list'];
    const post = actions['post'];
    const put = actions['put'];
    const remove = actions['remove'];
    const clear = actions['clear'];

    return combineReducers({
        insert: (state = { error: null, status: 'idle', lastUpdate: null }, { type, payload, meta }) => {
            state = _.cloneDeep(state);
            switch (type) {
                case put.pending:
                    state = _.merge(state, { error: null, status: 'put-pending', lastUpdate: meta.timestamp });
                    break;
                case put.success:
                    state = _.merge(state, { error: null, status: 'put-success', lastUpdate: meta.timestamp });
                    break;
                case put.error:
                    state = _.merge(state, { error: payload.error, status: 'put-error', lastUpdate: meta.timestamp });
                    break;
                default:
                    break;
            }
            return state;
        },
        byId: (state = {}, { type, payload, meta }) => {

            state = _.cloneDeep(state);

            switch (type) {
                case clear:
                    state = {};
                    break;
                case list.success:
                    const newState = _.keyBy(payload, 'id');
                    state = _.mapValues(newState, payload => ({ error: null, target: payload.id, payload, status: 'list-success', lastUpdate: meta.timestamp }));
                    break;
                case post.pending:
                    state = _.merge(state, { [meta.target]: { error: null, status: 'default-pending', lastUpdate: meta.timestamp } });
                    break;
                case post.success:
                    state = _.merge(state, { [meta.target]: { error: null, status: 'default-success', lastUpdate: meta.timestamp } });
                    break;
                case post.error:
                    state = _.merge(state, { [meta.target]: { error: payload.error, status: 'default-error', lastUpdate: meta.timestamp } });
                    break;
                case put.success:
                    state = _.merge(state, { [payload.id]: { error: null, target: payload.id, payload, status: 'put-success', lastUpdate: meta.timestamp } });
                    break;
                case remove.pending:
                    state = _.merge(state, { [meta.target]: { error: null, status: 'remove-pending', lastUpdate: meta.timestamp } });
                    break;
                case remove.success:
                    delete state[meta.target];
                    break;
                case remove.error:
                    state = _.merge(state, { [meta.target]: { error: payload.error, status: 'remove-error', lastUpdate: meta.timestamp } });
                    break;
                default:
                    break;
            }

            return state;
        },
        allIds: (state = { status: 'idle', ids: [], error: null, count: 0 }, { type, payload, meta }) => {

            state = _.cloneDeep(state);

            switch (type) {
                case clear:
                    state = { status: 'idle', ids: [], error: null, count: 0 };
                    break;
                case list.pending:
                    state = _.merge(state, { error: null, status: 'list-pending', lastUpdate: meta.timestamp });
                    break;
                case list.success:
                    state = _.merge(state, { count: payload.length, error: null, status: 'list-success', lastUpdate: meta.timestamp });
                    state.ids = _.union(state.ids, _.map(payload, 'id'));
                    break;
                case list.error:
                    state = _.merge(state, { error: payload.error, status: 'list-error', lastUpdate: meta.timestamp });
                    break;
                case put.success:
                    state = _.merge(state, { count: state.count + 1, error: null, status: 'put-success', lastUpdate: meta.timestamp });
                    state.ids = _.union(state.ids, [payload.id]);
                    break;
                case remove.success:
                    state = _.merge(state, { count: state.count - 1, error: null, status: 'remove-success', lastUpdate: meta.timestamp });
                    state.ids = _.difference(state.ids, [meta.target]);
                    break;
                default:
                    break;
            }

            return state;
        }
    });
}

export default PaymentMethodsReducer;