import React from 'react';
import _ from 'lodash';
import {
    ListItem, ListItemText, List,
} from '@material-ui/core';
import { useChangeCustomer, useCustomer } from './customer-provider';
import CustomerName from './elements/customer-name';


export const CustomersListInternal = ({ onClick, ...props }) => {

    const customer = useCustomer();
    const change = useChangeCustomer();
    const handleChange = (customer) => change(customer);

    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb(ev);
    }

    return (
        <List {...props}>
            {_.map(customer.payload.customers, customer => (
                <ListItem key={customer} button onClick={handleClick(() => handleChange(customer))}>
                    <ListItemText primary={<CustomerName customer={customer} />} />
                </ListItem>
            ))}
        </List>
    );
}
