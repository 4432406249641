import React, { useState } from 'react';
import { DialogContent, DialogActions, Button, Typography, Grid } from '@material-ui/core';
import SessionQuantityEditable from '../../../../sessions/component/element/session-quantity-editable';
import { useTranslation } from 'react-i18next';

export default function SetSessionQuantity(props) {

    const { memory, nextStep, onClose } = props;

    const [value, setValue] = useState(memory || 1);

    const onNext = () => {
        nextStep(value);
    };

    const onChange = (value) => {
        setValue(() => value);
    }

    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb(ev);
    }

    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.modal.session-quantity' })

    return (
        <div>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SessionQuantityEditable onChange={onChange} value={value} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={false} onClick={handleClick(onClose)}>
                    {t('cancel')}
                </Button>
                <Button disabled={!value} onClick={handleClick(onNext)} color='secondary' >
                    {t('next')}
                </Button>
            </DialogActions>
        </div>
    );
}

export function SetSessionQuantityLabel({ }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.modal.session-quantity' })

    return (
        <span>
            <Typography display='inline'>
                {t('label')}
            </Typography>
        </span>
    )
}