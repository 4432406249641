import React from 'react';
import _ from 'lodash';
import { MTableAction } from 'material-table';

export default function Action(props) {
    
    if (_.isObject(props.action) && props.action.enhanced) {
        if(_.isArray(props.data)) {
            return null;
        } else {
            return props.action.action(props.data);
        }
    } else {
        return <MTableAction {...props} />
    }

}