import React, { Fragment } from 'react';
import { TableRow, TableCell, Typography, TableHead } from '@material-ui/core';
import PayInvoiceButton from './actions/customer/pay-invoice';
import InvoiceNumber, { InvoiceNumberLabel } from './element/invoice-number';
import InvoiceCreationDate, { InvoiceCreationDateLabel } from './element/invoice-creation-date';
import InvoiceTotal, { InvoiceTotalLabel } from './element/invoice-total';
import InvoiceStatus, { InvoiceStatusLabel } from './element/invoice-status';
import Reconcile3DSecureButton from './actions/customer/handle-3d-secure';
import HandleCardConfirmButton from './actions/customer/handle-card-confirm';
import InvoicePaymentIntentStatus, { PaymentIntentLabel } from './element/invoice-payment-intent-status';
import MarkPaidInvoice from './actions/vayandata/mark-paid';
import FinalizeInvoice from './actions/vayandata/finalize';
import { useViewingAsVayanData } from '../../customers/selectors';
import SendInvoice from './actions/vayandata/send';
import { useTranslation } from 'react-i18next';

export function InvoiceListHead() {
    const { t } = useTranslation('translation', { keyPrefix: 'app.invoices.list' });

    return (
        <TableHead>
            <TableRow>
                <TableCell><InvoiceNumberLabel /></TableCell>
                <TableCell align="center"><InvoiceCreationDateLabel /></TableCell>
                <TableCell align="center"><InvoiceTotalLabel /></TableCell>
                <TableCell align="center"><InvoiceStatusLabel /></TableCell>
                <TableCell align="center"><PaymentIntentLabel /></TableCell>
                <TableCell align="right"><Typography>{t('actions-title')}</Typography></TableCell>
            </TableRow>
        </TableHead>
    )
}

export function InvoiceActions({ invoice }) {
    const isAdmin = useViewingAsVayanData();

    if (isAdmin) {
        return (
            <Fragment>
                <div>
                    <PayInvoiceButton invoice={invoice} />
                    <Reconcile3DSecureButton invoice={invoice} />
                    <HandleCardConfirmButton invoice={invoice} />
                </div>
                <div>
                    <MarkPaidInvoice invoice={invoice} />
                    <FinalizeInvoice invoice={invoice} />
                    <SendInvoice invoice={invoice} />
                </div>
            </Fragment>
        )
    } else {
        return (
            <Fragment>
                <PayInvoiceButton invoice={invoice} />
                <Reconcile3DSecureButton invoice={invoice} />
                <HandleCardConfirmButton invoice={invoice} />
            </Fragment>
        )
    }
}

function InvoiceRow({ invoice }) {
    return (
        <TableRow >
            <TableCell align="left">
                <InvoiceNumber invoice={invoice} variant='body2' color='primary' />
            </TableCell>
            <TableCell align="center">
                <InvoiceCreationDate invoice={invoice} variant='body2' />
            </TableCell>
            <TableCell align="center">
                <InvoiceTotal invoice={invoice} variant='body2' />
            </TableCell>
            <TableCell align="center">
                <InvoiceStatus invoice={invoice} />
            </TableCell>
            <TableCell align="center">
                <InvoicePaymentIntentStatus invoice={invoice} />
            </TableCell>
            <TableCell align="right">
                <InvoiceActions invoice={invoice} />
            </TableCell>
        </TableRow>
    )
}

export default function InvoiceListRow({ invoice }) {

    return (
        <InvoiceRow invoice={invoice} />
    );
}
