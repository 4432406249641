import React from 'react';
import { Box, CircularProgress, TextField } from '@material-ui/core';
import _ from 'lodash';
import { useParseXlsxFile } from './csv-import-file-parser';
import { useTranslation } from 'react-i18next';

function CSVImportFileInputStep({ nextStep, addToContext, ...props }) {

    const { parser, parsing, error } = useParseXlsxFile();
    const { t } = useTranslation('translation', { keyPrefix: 'app.products.csv-import.file' })
    const handleChange = async (e) => {
        const file = e.target.files[0];
        parser(file)
            .then(results => {
                addToContext('CSVFileInput', results);
                nextStep();
            })
            .catch(e => console.error(e));
    }

    return (
        <Box minHeight='200px' display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
            {parsing ?
                (<CircularProgress />) :
                (
                    <TextField fullWidth
                        error={Boolean(error)}
                        helperText={error?.message}
                        variant='outlined'
                        label={t('label')}
                        {...props}
                        type='file' onChange={handleChange} />
                )
            }
        </Box>
    );
}

export default CSVImportFileInputStep;