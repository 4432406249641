import React, { useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import NameFormForCreation, { nameInitialValues } from '../create-company-name';
import OwnerFormForCreation, { ownerInitialValues } from '../create-company-owner';
import { useNotif } from '../../../../common/component/notification';
import { useSdk } from '../../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../../lib/auth0-wrapper';
import { useDispatch } from 'react-redux';
import ActionOk from '../../../../common/component/notification/action-ok';
import CoefficientFormForCreation, { coefficientInitialValues } from '../create-company-coefficient';
import { useMainCompany } from '../../../../relations/component/user-relation-provider';
import { useViewingAsVayanData } from '../../../../customers/selectors';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const useStyle = makeStyles(theme => ({
    dialogContent: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
}));

function useHandleSubmit(onClose, distributor) {
    const notif = useNotif();
    const sdk = useSdk();
    const auth = useAuth0();
    const dispatch = useDispatch();
    const company = useMainCompany();
    const isVayanData = useViewingAsVayanData();

    return useCallback((values, { setSubmitting }) => {
        const payload = {
            company: {
                name: values.name,
                coefficient: values.coefficient,
            },
            user: {
                email: values.email,
                redirect: window.location.origin + window.location.pathname,
            }
        };

        const imp = isVayanData ?
            sdk.impersonateByVayanData(company.target).impersonateByDistributor(distributor.target) :
            sdk.impersonateByDistributor(distributor.target);

        dispatch(imp.companies.put(auth, payload))
            .then(() => {
                setSubmitting(false);
                onClose();
            })
            .catch(err => {
                const message = err.response?.data?.message || err.message;
                notif.error(message, { action: (key) => <ActionOk notif={notif} handle={key} /> });
                setSubmitting(false);
            });
    }, []);
}

export function CreateCompanyByDistributorModal({ open, onClose, distributor, ...props }) {
    const classes = useStyle();
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.create-company-modal.distributor.create-company-by-distributor' });
    const initialValues = _.merge({},
        nameInitialValues(),
        ownerInitialValues(),
        coefficientInitialValues(),
    );

    const schema = Yup.object().shape({
    }).unknown(true);

    const handleSubmit = useHandleSubmit(onClose, distributor);

    if (open) {
        return (
            <Dialog scroll='paper' fullWidth maxWidth='lg' open={open} onClose={onClose}>
                <DialogTitle>
                    {t('title')}
                </DialogTitle>
                <Formik
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={handleSubmit}
                >
                    {(props) => (
                        <Form>
                            <DialogContent className={classes.dialogContent}>
                                <Grid container spacing={4}>
                                    <Grid item xs={6} container spacing={2} >
                                        <Grid item xs={12}>
                                            <NameFormForCreation  {...props} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <OwnerFormForCreation  {...props} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CoefficientFormForCreation
                                                min={distributor.payload.coefficient}
                                                {...props} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button disabled={props.isSubmitting} onClick={onClose}>
                                    {t('cancel')}
                                </Button>
                                <Button disabled={props.isSubmitting} onClick={props.handleReset}>
                                    {t('reset')}
                                </Button>
                                <Button disabled={props.isSubmitting} onClick={props.handleSubmit} color='secondary' >
                                    {t('proceed')}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik >
            </Dialog >
        );
    } else {
        return null;
    }

}