import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '../../lib/auth0-wrapper';
import { useSdk } from '../../lib/licensing-sdk';

export const useLoadPrimaryRelationsCallback = () => {
    const sdk = useSdk();
    const dispatch = useDispatch();
    const auth = useAuth0();

    return () => dispatch(sdk.relations.getUsersPrimaryRelations(auth))
        .then((relations) => {
            return Promise.all(relations.map(r => dispatch(sdk.companies.get(auth, r.target))))
        })
}
export const useLoadPrimaryRelations = (onSuccess, onError, anyway) => {
    const callback = useLoadPrimaryRelationsCallback();

    useEffect(() => {
        const nop = () => { };
        callback()
            .then(onSuccess || nop)
            .catch(onError || nop)
            .finally(anyway || nop);
    }, []);
}
