import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import UserNameWithAvatar from './user-email-with-avatar';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function UserSelect(props) {
    const classes = useStyles();

    const { value, onChange } = props;
    let { users } = props;

    return (
        <div>
            <FormControl className={classes.formControl}>
                <Select value={value} onChange={ev => onChange(ev.target.value)}>
                    {users.map((user) => {
                        return (
                            <MenuItem key={user} value={user}>
                                <UserNameWithAvatar small user={user} />
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </div>
    );
}
