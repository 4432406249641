
import _ from 'lodash';
import axios from 'axios';

export default class CsvImport {

    async validate(auth, data, vd) {
        try {
            const token = await auth.getTokenSilently();
            const res = await axios({
                method: 'post',
                baseURL: process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_BASEPATH,
                url: `/v/${vd}/products/import`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                data,
            });
            return res.data;
        } catch (error) {
            throw error;
        }
    }

    async apply(auth, data, vd) {
        try {
            const token = await auth.getTokenSilently();
            const res = await axios({
                method: 'put',
                baseURL: process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_BASEPATH,
                url: `/v/${vd}/products/import`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                data,
            });
            return res.data;
        } catch (error) {
            throw error;
        }
    }
}
