import _ from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useSdk } from '../../lib/licensing-sdk';
import { RECURRING } from '../prices/price-constants';


export function useSubscriptionsList(customer) {
    const sdk = useSdk();

    const selector = useMemo(() => createSelector(
        sdk.orderlines.selectors.makePopulateList(),
        (orderlines) => _.chain(orderlines)
            .filter(orderline => orderline.payload?.buyer === customer.target)
            .filter(orderline => orderline.payload?.type === RECURRING)
            .orderBy('payload.created', 'desc')
            .value()
    ), []);

    return useSelector(selector);
}


