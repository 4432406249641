import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getHostId } from '../../element/device-winid';
import AssociateDevice from '../../../../sessions/component/associate-modal/associate-device';
import { useDispatch } from 'react-redux';
import { useImpersonateSdk } from '../../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../../lib/auth0-wrapper';
import { useNotif } from '../../../../common/component/notification';
import { useCustomer } from '../../../../customers/component/customer-provider';
import ActionOk from '../../../../common/component/notification/action-ok';

export default function TransferLicenseModal({ open, ...props }) {

    const { onClose, device } = props;
    const { t } = useTranslation('translation', { keyPrefix: 'app.devices.actions.transfer.modal' })
    const [loading, setLoading] = useState(false)
    const [targetDevice, setTargetDevice] = useState(null);
    const dispatch = useDispatch();
    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const notif = useNotif();

    const customer = useCustomer();

    const onComplete = async (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setLoading(true)

        try {
            await dispatch(sdk.devices.transferLicenses(auth, device.target, targetDevice, customer.target))
            setLoading(false)
            onClose();
        } catch (e) {
            notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            setLoading(false)
        }
    };

    const hostId = getHostId(device);

    if (open) {
        return (
            <Dialog fullWidth maxWidth='sm' disableBackdropClick open={open} onClose={onClose}>
                <DialogTitle>
                    <Typography>{t('title', { hostId })}</Typography>
                </DialogTitle>
                <DialogContent>
                    <AssociateDevice device={targetDevice} setDevice={setTargetDevice} />
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={onClose}>{t('close')}</Button>
                    <Button disabled={loading} color='secondary' onClick={onComplete}>{t('proceed')}</Button>
                </DialogActions>
            </Dialog >
        );
    } else {
        return null;
    }

}
