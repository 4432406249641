import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import ReferenceGridItem from './reference-grid-item';
import { useSelector } from 'react-redux';
import { useSdk } from '../../../lib/licensing-sdk';
import { makePricesListForCompanyAndService } from '../../prices/selector';
import { useCustomer } from '../../customers/component/customer-provider';

function ReferencesGridController({ product, component }) {

    const sdk = useSdk();

    const pricesSelector = useMemo(() => makePricesListForCompanyAndService(sdk), [sdk]);

    const company = useCustomer();
    const prices = useSelector(state => pricesSelector(state, { product, company })); 
    
    return React.createElement(component, { company, prices, product });
}

function ReferencesGridView({ product, prices }) {

    return (
        <Grid container spacing={2}>
            {prices.map((p, i) => (
                <Grid key={i} item xs={12} md={6} >
                    <ReferenceGridItem key={i} price={p} product={product} />
                </Grid>
            ))}
        </Grid >
    )
}

export default function ReferencesGrid({ product }) {
    return <ReferencesGridController product={product} component={ReferencesGridView} />
};
