import React, { useCallback } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

export default function StepperController({ steps, ...props }) {

    const [activeStep, setActiveStep] = React.useState(0);
    const [stepsMemory, setStepMemory] = React.useState([]);

    const nextStep = (payload) => {
        stepsMemory.splice(activeStep, 1, payload);
        setStepMemory(stepsMemory);
        if (activeStep + 1 < steps.length) {
            setActiveStep(a => a + 1);
        }
    };

    const previousStep = () => {
        if (activeStep > 0) {
            setActiveStep(a => a - 1);
        }
    };

    const reset = useCallback(() => {
        setStepMemory([]);
        setActiveStep(0);
    }, []);

    const innerProps = {
        ...props,
        nextStep,
        previousStep,
        reset,
        activeStep,
        memories: stepsMemory,
        memory: stepsMemory[activeStep],
    };

    return (
        <React.Fragment>
            <Stepper activeStep={activeStep}>
                {steps.map((step, index) => {
                    return (
                        <Step key={index}>
                            <StepLabel>
                                {React.createElement(step.label, innerProps)}
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {React.createElement(steps[activeStep].component, innerProps)}
        </React.Fragment>
    );
}
