import React, { useState, useCallback } from 'react';
import {
    Button, Grid, Typography, ExpansionPanelSummary,
    ExpansionPanelDetails, ExpansionPanelActions, Divider, ExpansionPanel
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PaymentMethodList from './payment-method-list';
import CardSetupDialog from './credit-card-setup-dialog';
import { useMainCompany } from '../../../../relations/component/user-relation-provider';
import { useTranslation } from 'react-i18next';

const PaymentMethodsCtrl = ({ component, ...props }) => {

    const company = useMainCompany();

    const innerProps = {
        company,
        ...props,
    }

    return React.createElement(component, innerProps);
}

const PaymentMethodFormPanel = ({ company, defaultExpanded }) => {

    const [open, setOpen] = useState(false);
    const openDialog = useCallback((ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(true);
        // eslint-disable-next-line
    }, []);
    const closeDialog = useCallback((ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(false);
        // eslint-disable-next-line
    }, []);
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.forms.payment-method-form.index' });

    return (
        <ExpansionPanel defaultExpanded={defaultExpanded}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h4'>{t('panel-title')}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <PaymentMethodList company={company} action={openDialog} />
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
            <Divider />
            <ExpansionPanelActions>
                <Button size="small" disabled={!company.status.endsWith('success')} color='secondary' onClick={openDialog}>
                    {t('add-card')}
                </Button>
            </ExpansionPanelActions>
            <CardSetupDialog company={company} open={open} onClose={closeDialog} />
        </ExpansionPanel>
    );
}

const PaymentMethodFormInPanel = (props) => {
    return (
        <PaymentMethodsCtrl {...props} component={PaymentMethodFormPanel} />
    )
}

export default PaymentMethodFormInPanel;
