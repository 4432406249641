import React from 'react';
import BCardMediaContent from '../../../common/component/card/card-media-content';
import ReferenceName from '../element/reference-name';
import { makeStyles } from '@material-ui/styles';
import ReferenceLogo from '../element/reference-logo';

const useStyles = makeStyles(theme => ({
    flex: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
}));

export default function ReferenceMediaContent({ reference }) {
    const classes = useStyles();

    return (
        <BCardMediaContent className={classes.flex}>
            <ReferenceName variant='h5' color='textPrimary' reference={reference} />
            <ReferenceLogo reference={reference} />
        </BCardMediaContent>
    )

}
