export const SESSION = 'session';
export const DEVICE = 'device';
export const TARGETS = [SESSION, DEVICE];

export const ONE_TIME = 'one-time';
export const SERVICE = 'recurring';
export const TYPES = [ONE_TIME, SERVICE];

export const SEND_INVOICE = 'send_invoice';
export const CHARGE_AUTOMATICALLY = 'charge_automatically';
export const PAYMENT_METHOD = [SEND_INVOICE, CHARGE_AUTOMATICALLY];
