import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useReferenceById } from '../../../../references/selector';
import { useReferenceName } from '../../../../references/component/element/reference-name';
import { usePriceById } from '../../../../prices/selector';
import _ from 'lodash';
import { applyCoefficientOnPrice, computeGraduatedTiersPublicPrice, computePriceString } from '../../../../prices/component/element/price-unit-price';
import { BILLING_TIERS_MODE_GRADUATED, BILLING_TIERS_MODE_PER_UNIT } from '../../../../prices/price-constants';
import RemoveFromOrderButton from '../../../../order-lines/component/actions/remove-from-order';
import Skeleton from '@material-ui/lab/Skeleton';
import { useImpersonateSdk } from '../../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../../lib/auth0-wrapper';
import { COEF_POLICY_CUSTOMER, useCoefficient, useCoefficientPolicy, useCustomer } from '../../../../customers/component/customer-provider';
import { useNotif } from '../../../../common/component/notification';
import ActionOk from '../../../../common/component/notification/action-ok';
import { FINALIZED, OPEN } from '../../../constants';
import { DEVICE } from '../../../../products/product-constants';
import DeviceWinid from '../../../../devices/component/element/device-winid';
import { makePriceStringWithCurrency } from '../../../../prices/prices-utilities';
import { Typography } from '@material-ui/core';
import { useOrderLineReferenceName } from '../../../../order-lines/component/element/order-line-reference-name';
import AssociateDeviceButton from '../../../../devices/component/available-licences/associate';
import { useOrderLineById } from '../../../../order-lines/selector';
import { useTranslation } from 'react-i18next';
import MarkFreeButton from '../../../../order-lines/component/actions/mark-free';

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 700,
    },
    head: {
        color: theme.palette.primary[theme.palette.type]
    }
}));

function useOrderCoefficient(order) {
    const coeffPolicy = useCoefficientPolicy();
    const coef = useCoefficient();
    if (order.payload.status === FINALIZED) {
        return coeffPolicy === COEF_POLICY_CUSTOMER ?
            order.payload.buyer_data.coefficient_customer :
            order.payload.buyer_data.coefficient_buyer;
    } else {
        return coef;
    }

}

function ReferenceNameCell({ reference, ...props }) {
    const classes = useStyles();
    const referenceName = useReferenceName(reference);

    return (
        <TableCell className={classes.head} {...props}>
            {referenceName}
        </TableCell>
    )
}

function FreeLineCell({ line }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.details.order-table.rows.devices' });

    if (line.payload.free) {
        if (line.payload.linked_line) {
            return <LinkedLineCell line={line} />
        } else {
            return <Typography>
                {t('free-line')}
            </Typography>
        }
    } else {
        return null;
    }
}

function LinkedLineCell({ line }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.details.order-table.rows.devices' });
    const linkedLine = useOrderLineById(line.payload.linked_line);
    const reference = useOrderLineReferenceName(linkedLine);
    if (linkedLine) {
        return <Typography>
            {t('free-linked-line', { reference })}
        </Typography>
    } else {
        return null;
    }
}

function DeviceInfoCell({ line }) {
    if (line.payload.device) {
        return <DeviceWinid device={line.payload.device} />
    } else {
        return <AssociateDeviceButton line={line} />
    }
}

function DeviceCells({ addToTotal, price, line, order, ...props }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.details.order-table.rows.devices' });
    const coefficient = useOrderCoefficient(order);
    const priceValue = line.payload.free ? 0 : (order.payload.status === FINALIZED ? line.payload.amount : price.payload.unit_amount);
    const unitPrice = t('unit-price-value', computePriceString(applyCoefficientOnPrice(priceValue, coefficient), price));

    useEffect(() => {
        addToTotal(priceValue);
    }, []);

    const canRemove = order.payload.status === OPEN && !line.payload.linked_line
    const canMarkFree = order.payload.status === OPEN && (!line.payload.office_demo || !line.payload.linked_line)

    return (
        <Fragment>
            <TableCell colSpan={1} align="right">
                {canRemove ? <RemoveFromOrderButton line={line} color='secondary' /> : null}
                {canMarkFree ? <MarkFreeButton line={line} color='secondary' /> : null}
            </TableCell>
            <TableCell colSpan={4}>
                <DeviceInfoCell line={line} />
            </TableCell>
            <TableCell colSpan={1} align="right">
                <FreeLineCell line={line} />
            </TableCell>
            <TableCell colSpan={3} align="right">
                {unitPrice}
            </TableCell>
        </Fragment>
    )
}

function SessionCells({ addToTotal, price, lines, order }) {

    let freeQty = 0;
    let paidQty = 0;
    let paidPrice = 0;
    _.each(lines, line => {
        if (line.payload.free) {
            freeQty++;
        } else {
            paidQty++;
            paidPrice += line.payload.amount;
        }
    });

    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.details.order-table.rows.sessions' });
    const coefficient = useOrderCoefficient(order);
    const priceValue = order.payload.status === FINALIZED ? paidPrice / paidQty : price.payload.unit_amount;
    const unitPrice = t('unit-price-value', computePriceString(applyCoefficientOnPrice(priceValue, coefficient), price));
    const totalPrice = t('total-price-value', computePriceString(applyCoefficientOnPrice(paidPrice, coefficient), price));

    useEffect(() => {
        addToTotal(paidPrice);
    }, []);

    const quantity = t('quantity', { count: paidQty + freeQty });
    const freeQtyIncluded = t('free-qty-included', { count: freeQty });

    return (
        <Fragment>
            <TableCell colSpan={2}>
                {quantity} {freeQtyIncluded}
            </TableCell>
            <TableCell colSpan={3} align="right">
                {unitPrice}
            </TableCell>
            <TableCell colSpan={3} align="right">
                {totalPrice}
            </TableCell>
        </Fragment>
    )
}

function PriceActions({ reference, height, lines, order }) {
    const classes = useStyles();

    lines = _.filter(lines, l => !l.linked_line);
    const canRemove = order.payload.status === OPEN && lines.length > 0;

    return (
        <Fragment>
            <ReferenceNameCell reference={reference} rowSpan={height} colSpan={3} align="left" />
            <TableCell className={classes.head} rowSpan={height} colSpan={1} align="left">
                {canRemove ? <RemoveFromOrderButton line={lines[lines.length - 1]} minusIcon color='secondary' /> : null}
            </TableCell>
        </Fragment>
    )
}

function PriceTierRow({ header, tier, reference, lines, price, addToTotal, order }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.details.order-table.rows.tiers' });
    const coefficient = useOrderCoefficient(order);
    const quantity = tier.quantity;
    useEffect(() => addToTotal(tier.price), []);
    const unitPrice = t('unit-price', computePriceString(applyCoefficientOnPrice(tier.tier.amount, coefficient), price));
    const totalPrice = t('total-price', computePriceString(applyCoefficientOnPrice(tier.price, coefficient), price));

    return (
        <TableRow key={tier.tier._id}>
            {header && (
                <PriceActions height={header} reference={reference} lines={lines} order={order} />
            )}
            <TableCell colSpan={2}>
                {t('quantity', { count: quantity })}
            </TableCell>
            <TableCell colSpan={3} align="right">
                {unitPrice}
            </TableCell>
            <TableCell colSpan={3} align="right">
                {totalPrice}
            </TableCell>
        </TableRow>
    )
}

function PriceGraduatedRow({ reference, lines, price, order, ...props }) {

    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const notif = useNotif();
    const customer = useCustomer();
    const [tiers, setTiers] = useState(null);

    useEffect(() => {
        if (order.payload.status === FINALIZED) {
            const quantityPerTier = [];
            _.chain(lines)
                .groupBy('payload.tier')
                .each((lines, tier) => {
                    quantityPerTier.push({
                        quantity: lines.length,
                        price: _.sumBy(lines, 'payload.amount'),
                        tier: _.find(price.payload.tiers, t => t._id === tier)
                    });
                })
                .value();
            const tiers = _.sortBy(quantityPerTier, ['tier.last', 'tier.up_to']);
            setTiers(tiers);
        } else {
            sdk.orderlines.countForSameReference(auth, lines[0].target, customer.target)
                .then(count => {
                    const quantity = lines.length;
                    const tiers = computeGraduatedTiersPublicPrice(price, quantity, count);
                    setTiers(tiers);
                })
                .catch(e => {
                    notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
                });
        }

    }, []);

    if (tiers === null) {

        return (
            <TableRow>
                <ReferenceNameCell reference={reference} colSpan={3} align="left" />
                <TableCell colSpan={9}>
                    <Skeleton />
                </TableCell>
            </TableRow>
        )

    } else {
        return (
            _.map(tiers, (tier, i) => {
                if (tier.tier) {
                    return (
                        <PriceTierRow
                            key={tier.tier._id}
                            header={i === 0 && tiers.length}
                            tier={tier}
                            reference={reference}
                            lines={lines}
                            price={price}
                            order={order}
                            {...props} />
                    )
                } else {
                    console.log({ tier });
                    return null;
                }
            })
        )
    }
}

function PricePerUnitRow({ reference, lines, price, order, addToTotal, product }) {

    return product.payload.works_for === DEVICE ?
        (
            _.map(lines, line => {
                return (
                    <TableRow key={line.target}>
                        <ReferenceNameCell reference={reference} colSpan={3} align="left" />
                        <DeviceCells
                            addToTotal={addToTotal}
                            price={price}
                            order={order}
                            line={line} />
                    </TableRow>
                )
            })
        ) : (
            <TableRow>
                <PriceActions height={1} reference={reference} lines={lines} />
                <SessionCells addToTotal={addToTotal} price={price} lines={lines} order={order} />
            </TableRow >
        )

}

function OrderTableContentSafe({ price, lines, ...props }) {
    const reference = useReferenceById(price.payload.reference);
    const tiersMode = price.payload.tiers_mode;

    if (tiersMode === BILLING_TIERS_MODE_PER_UNIT) {
        return (
            <PricePerUnitRow price={price} lines={lines} reference={reference} {...props} />
        )
    } else if (tiersMode === BILLING_TIERS_MODE_GRADUATED) {
        return (
            <PriceGraduatedRow price={price} lines={lines} reference={reference} {...props} />
        )
    } else {
        return null;
    }
}

function OrderTableContent({ price, ...props }) {

    price = usePriceById(price);
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.details.order-table.rows' });

    if (price) {
        return <OrderTableContentSafe {...props} price={price} />
    } else {
        return (
            <TableRow>
                <TableCell colSpan={12} align='center'>
                    <Typography>
                        {t('no-price-found')}
                    </Typography>
                </TableCell>
            </TableRow>
        )
    }

}

function OrderTableSessionHeader() {
    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.details.order-table.rows.sessions.headers' });

    return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.head} colSpan={3}>
                    {t('reference')}
                </TableCell>
                <TableCell className={classes.head} colSpan={1}></TableCell>
                <TableCell className={classes.head} colSpan={2}>
                    {t('quantity')}
                </TableCell>
                <TableCell className={classes.head} colSpan={3} align="right">
                    {t('unit-price')}
                </TableCell>
                <TableCell className={classes.head} colSpan={3} align="right">
                    {t('total-price')}
                </TableCell>
            </TableRow>
        </TableHead >
    );
}

function OrderTableDeviceHeader() {
    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.details.order-table.rows.devices.headers' });

    return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.head} colSpan={3}>
                    {t('reference')}
                </TableCell>
                <TableCell className={classes.head} colSpan={1}></TableCell>
                <TableCell className={classes.head} colSpan={5}>
                    {t('host-id')}
                </TableCell>
                <TableCell className={classes.head} colSpan={3} align="right">
                    {t('unit-price')}
                </TableCell>
            </TableRow>
        </TableHead >
    )
}

export default function OrderInnerTable({ product, prices, order, addToTotal, ...props }) {

    const classes = useStyles();
    const coefficient = useOrderCoefficient(order);
    const customer = useCustomer();
    const [subtotal, setSubtotal] = useState(0);
    const addToSubtotal = (amount) => {
        setSubtotal(sub => amount + sub);
        addToTotal(amount);
    }

    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.details.order-table.rows' });

    return (
        <Fragment>
            {product.payload.works_for === DEVICE ? (<OrderTableDeviceHeader />) : (<OrderTableSessionHeader />)}
            <TableBody>
                {
                    _.map(prices, (lines, price) => (
                        <OrderTableContent
                            key={price}
                            order={order}
                            product={product}
                            price={price}
                            lines={lines}
                            addToTotal={addToSubtotal}
                            {...props} />
                    ))
                }
                <TableRow>
                    <TableCell colSpan={6}></TableCell>
                    <TableCell className={classes.head} colSpan={3} align="left">{t('subtotal')}</TableCell>
                    <TableCell className={classes.head} colSpan={3} align="right">{t('price-value', makePriceStringWithCurrency(subtotal * coefficient, customer))}</TableCell>
                </TableRow>
            </TableBody>
        </Fragment >
    )
}
