import React, { useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Step, StepLabel, Stepper } from '@material-ui/core';
import _ from 'lodash';
import CSVImportFileInputStep from './csv-import-file-step';
import CSVImportValidateStep from './csv-import-validate-step';
import CSVImportApplyStep from './csv-import-apply-step';
import { useTranslation } from 'react-i18next';

const STEPS = [
    {
        label: 'steps-label.import',
        hoc: (props) => props,
        element: CSVImportFileInputStep
    },
    {
        label: 'steps-label.validate',
        hoc: (props) => props,
        element: CSVImportValidateStep
    },
    {
        label: 'steps-label.summary',
        hoc: (props) => props,
        element: CSVImportApplyStep
    },
];

function CSVImportModal({ open, onClose, ...props }) {

    const [context, setContext] = useState({});
    const addToContext = (step, data) => {
        setContext(context => _.merge({}, context, { [step]: data }));
    }
    const [step, setStep] = useState(0);
    const nextStep = () => setStep(s => s + 1 > STEPS.length ? s : s + 1);
    const backStep = () => setStep(s => s - 1 < 0 ? s : s - 1);
    const [disableBackdrop, setDisableBackdrop] = useState(false);
    const { t } = useTranslation('translation', { keyPrefix: 'app.products.csv-import' });
    
    const handleClose = () => {
        onClose();
        setContext({});
        setStep(0);
    }

    return (
        <Dialog open={open} disableBackdropClick={disableBackdrop} onClose={handleClose} maxWidth='xl' fullWidth>
            <DialogTitle>
                {t('title')}
            </DialogTitle>
            <DialogContent display={'flex'}>
                <Stepper activeStep={step}>
                    {STEPS.map(({ label }) => {
                        return (
                            <Step key={label}>
                                <StepLabel>{t(label)}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <Box display={'flex'} flexDirection='column' alignItems='center' justifyContent='center'>
                    {React.createElement(STEPS[step].element, STEPS[step].hoc({ ...props, context, nextStep, backStep, setDisableBackdrop, addToContext, onClose: handleClose }))}
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default CSVImportModal;