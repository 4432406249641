import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import { useSdk } from '../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../lib/auth0-wrapper';
import { useMainCompany } from '../../../relations/component/user-relation-provider';
import {
    Box, Button, CircularProgress, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    list: {
        width: '100%',
    },
    green: {
        color: '#639d1e',
    },
}));

const useApplyOnTheServer = (data) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [results, setResults] = useState(null);

    const sdk = useSdk();
    const auth = useAuth0();
    const vd = useMainCompany();

    useEffect(() => {
        sdk.csvImport.apply(auth, data, vd.target)
            .then(({ results, error }) => {
                setResults(results);
                setError(error);
                console.log({ results, error });
            })
            .catch(e => {
                setResults(null);
                setError(e);
            })
            .finally(() => setLoading(false))
    }, []);

    return { loading, results, error }
}

function CSVImportApplyStep({ onClose, context }) {

    const { loading, error, results } = useApplyOnTheServer(context['ValidationResults']);
    const { t } = useTranslation('translation', { keyPrefix: 'app.products.csv-import.apply' });
    const classes = useStyles();

    return (
        <Fragment>
            <Box minHeight='200px' padding={2} flexGrow={1} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                {loading ? <CircularProgress /> : results ? (
                    <Box flexGrow={1}>
                        <List className={classes.list}>
                            <ListItem>
                                <ListItemIcon className={results.products ? classes.green : undefined}>
                                    {results.products ? <CheckCircleIcon /> : <PauseCircleFilledIcon />}
                                </ListItemIcon>
                                <ListItemText primary={t('products-label')} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={results.parameterCategories ? classes.green : undefined}>
                                    {results.parameterCategories ? <CheckCircleIcon /> : <PauseCircleFilledIcon />}
                                </ListItemIcon>
                                <ListItemText primary={t('parameters-label')} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={results.references ? classes.green : undefined}>
                                    {results.references ? <CheckCircleIcon /> : <PauseCircleFilledIcon />}
                                </ListItemIcon>
                                <ListItemText primary={t('references-label')} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={results.prices ? classes.green : undefined}>
                                    {results.prices ? <CheckCircleIcon /> : <PauseCircleFilledIcon />}
                                </ListItemIcon>
                                <ListItemText primary={t('prices-label')} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={results.companies ? classes.green : undefined}>
                                    {results.companies ? <CheckCircleIcon /> : <PauseCircleFilledIcon />}
                                </ListItemIcon>
                                <ListItemText primary={t('company-prices-list')} secondary={t('company-prices-list-desc')} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={results.vayandata ? classes.green : undefined}>
                                    {results.vayandata ? <CheckCircleIcon /> : <PauseCircleFilledIcon />}
                                </ListItemIcon>
                                <ListItemText primary={t('vayandata-prices-list')} secondary={t('vayandata-prices-list-desc')} />
                            </ListItem>
                        </List>
                    </Box>
                ) : <Typography>{JSON.stringify(error)}</Typography>}
            </Box>
            <Box display='flex' width={'100%'} flexDirection='row' alignItems='center' justifyContent='flex-end'>
                <Button disabled={loading} onClick={onClose} color='secondary'>{t('button-confirm')}</Button>
            </Box>
        </Fragment>
    );
}

export default CSVImportApplyStep;