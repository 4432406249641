import React, { } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { getHostId } from './device-winid';
import { Box, Typography } from '@material-ui/core';
import { getLabel } from './device-label';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    label: {
        fontSize: 12,
    }
}));

export default function DeviceSelect({ onChange, value, devices, ...props }) {
    const classes = useStyles();

    return (
        <div>
            <FormControl className={classes.formControl}>
                <Select {...props} value={value} onChange={ev => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    onChange(ev.target.value)
                }}>
                    {devices.map((device) => {
                        return (
                            <MenuItem key={device.target} value={device.target}>
                                <Box>
                                    <Typography>
                                        {getHostId(device)}
                                    </Typography>
                                    <Typography className={classes.label} >
                                        {getLabel(device)}
                                    </Typography>
                                </Box>
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </div>
    );
}
