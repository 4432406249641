import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import ProductController from './product-controller';
import { useIsViewingMainCustomer } from '../../customers/selectors';
import ProductConfigurationPage from './product-configuration/product-configuration-page';

function ProductsLayout(props) {

    let { url } = useRouteMatch();
    const isMainView = useIsViewingMainCustomer();

    return (
        <Switch>
            <Route path={`${url}/view/:productId`} component={ProductController} />
            {!isMainView && <Route path={`${url}/configure`} component={ProductConfigurationPage} />}
        </Switch>
    )

}

export default ProductsLayout;
