import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import {
    Chip,
} from '@material-ui/core';
import { useCustomer } from '../customers/component/customer-provider';
import { useNotif } from '../common/component/notification';
import { useImpersonateSdk } from '../../lib/licensing-sdk';
import { useAuth0 } from '../../lib/auth0-wrapper';
import ActionOk from '../common/component/notification/action-ok';

export const useLoadOfficeDemoRequestsCountCallback = () => {
    const auth = useAuth0();
    const sdk = useImpersonateSdk();
    const customer = useCustomer();
    const notif = useNotif();

    const [count, setCount] = useState(null);
    const [loading, setLoading] = useState(true);

    const callback = useCallback((ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        setLoading(true);
        sdk.devices.countOfficeDemoRequestsForVD(auth, customer.target)
            .then(count => setCount(count))
            .finally(() => setLoading(false))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            });
    }, []);

    return [count, loading, callback];
}

export const useLoadOfficeDemoRequestsCountRoutine = () => {

    const [count, loading, callback] = useLoadOfficeDemoRequestsCountCallback();

    useEffect(() => {
        callback();
        const interval = setInterval(callback, 60 * 1000);
        return () => {
            clearInterval(interval);
        }
    }, []);

    return [count, loading, callback];

}

export const OfficeDemoRequestsCountBadge = () => {

    const [count] = useLoadOfficeDemoRequestsCountRoutine();
    if (count === null) {
        return null;
    }

    return (
        <Chip size='small' max={99} label={count} color='secondary' />
    )
};