import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useImpersonateSdk } from '../../../lib/licensing-sdk';
import { useAuth0 } from '../../../lib/auth0-wrapper';
import SubscriptionListRow, { SubscriptionListHead } from './subscription-list-item';
import ActionOk from '../../common/component/notification/action-ok';
import { useNotif } from '../../common/component/notification';
import { Table, TableBody } from '@material-ui/core';
import { useSubscriptionsList } from '../selector';
import { useCustomer } from '../../customers/component/customer-provider';

export function SubscriptionsList({ orderlines }) {
    return (
        <Table aria-label="subscriptions table">
            <SubscriptionListHead />
            <TableBody>
                {orderlines.map((orderline) => {
                    return (
                        <React.Fragment key={orderline.target}>
                            <SubscriptionListRow orderline={orderline} />
                        </React.Fragment>
                    )
                })}
            </TableBody>
        </Table>
    )
}

export function SubscriptionListCtrl({ component, ...props }) {

    const dispatch = useDispatch();
    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const notif = useNotif();
    const customer = useCustomer();

    const orderlines = useSubscriptionsList(customer);


    useEffect(() => {
        dispatch(sdk.orderlines.list(auth, customer.target))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> })
            });
    }, []);

    const innerProps = {
        orderlines,
        ...props,
    }

    return React.createElement(component, innerProps);
}

export default function DefaultSubscriptionList({ ...props }) {
    return <SubscriptionListCtrl component={SubscriptionsList} {...props} />
}