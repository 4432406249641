import React, { } from 'react';
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    deny: {
        verticalAlign: 'middle',
        color: theme.palette.error[theme.palette.type],
    },
}));

export function CannotProceed(props) {
    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.order-lines.sumup.check' });
    return (
        <span>
            <Typography display='inline'>
                {t('cannot-proceed')}
            </Typography>
            <ClearOutlinedIcon className={classes.deny} />
        </span>
    );
}
