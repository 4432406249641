
import _ from 'lodash';
import axios from 'axios';
import Actions from './actions';
import Selectors from './selectors';

export const INTERNAL_SERVER_ERROR = '@server/INTERNAL_ERROR';
export const SERVER_NETWORK_ERROR = '@server/NETWORK_ERROR';

export default class Resource {

    constructor(resource, uri, n = false, pathInState = 'sdk') {
        this.uri = uri;
        this.resource = resource;
        this.path = pathInState;
        this.n = n;

        this.actions = new Actions(resource);
        this.selectors = Selectors(pathInState, resource);
    }

    clear() {
        return (dispatch, getState) => {
            const clear = this.actions['clear'];
            return dispatch({ type: clear, meta: { timestamp: Date.now() } });
        }
    }

    count(auth, query = {}) {

        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.${this.resource}`);

            const pending = state.allIds.status && state.allIds.status === 'count-pending';

            // dont do anything if operation is pending.
            if (pending) {
                return null;
            }

            dispatch(this._makePendingPayload('count', null));

            const prepend = this.n ?
                `${query.vayanData ?
                    `/v/${query.vayanData}` :
                    ''}${query.distributor ? `/d/${query.distributor}` :
                        ''}${query.company ? `/c/${query.company}` : ''}` :
                '';

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'get',
                    baseURL: this.uri,
                    url: `/${this.resource}/count`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: query,
                });
                dispatch(this._makeSuccessPayload('count', res.data.data, null));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('count', error, null, flag));
                throw error;
            }
        }
    }

    list(auth, query = {}, force = false) {

        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.${this.resource}`);

            const pending = state.allIds.status && state.allIds.status.includes('pending');

            // dont do anything if operation is pending.
            if (!force && pending) {
                return null;
            }

            dispatch(this._makePendingPayload('list', null));

            const prepend = this.n ?
                `${query.vayanData ?
                    `/v/${query.vayanData}` :
                    ''}${query.distributor ? `/d/${query.distributor}` :
                        ''}${query.company ? `/c/${query.company}` : ''}` :
                '';

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'get',
                    baseURL: this.uri,
                    url: `${prepend}/${this.resource}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: query
                });
                dispatch(this._makeSuccessPayload('list', res.data.data, null));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('list', error, null, flag));
                throw error;
            }
        }
    }

    get(auth, identifier, query = {}, force = false) {
        return async (dispatch, getState) => {

            const state = _.get(getState(), `${this.path}.${this.resource}`);

            const existing = state.byId[identifier];
            const pending = existing && existing.status.endsWith('pending');
            const hasErred = existing && existing.status.endsWith('error');

            // dont do anything if operation is pending or already exists.
            // The update can be forced if its existing
            if ((!force && (existing && !hasErred)) || pending) {
                if (existing && existing.payload) return existing.payload;
                return null;
            }

            dispatch(this._makePendingPayload('get', identifier));

            const prepend = this.n ?
                `${query.vayanData ?
                    `/v/${query.vayanData}` :
                    ''}${query.distributor ? `/d/${query.distributor}` :
                        ''}${query.company ? `/c/${query.company}` : ''}` :
                '';

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'get',
                    baseURL: this.uri,
                    url: `${prepend}/${this.resource}/${identifier}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: query,
                });
                dispatch(this._makeSuccessPayload('get', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('get', error, identifier, flag));
                throw error;
            }
        }
    }

    post(auth, identifier, data, query = {}) {

        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.${this.resource}`);

            const existing = !!state.byId[identifier];
            const pending = existing && state.byId[identifier].status.includes('pending');

            // dont do anything if operation is pending.
            if (pending) {
                return null;
            }

            dispatch(this._makePendingPayload('post', identifier));

            const prepend = this.n ?
                `${query.vayanData ?
                    `/v/${query.vayanData}` :
                    ''}${query.distributor ? `/d/${query.distributor}` :
                        ''}${query.company ? `/c/${query.company}` : ''}` :
                '';

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `${prepend}/${this.resource}/${identifier}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    data,
                    params: query,
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, identifier, flag));
                throw error;
            }
        }
    }

    put(auth, data, query = {}) {
        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.${this.resource}`);
            const pending = state.insert.status.includes('pending');

            if (pending) {
                return null;
            }

            dispatch(this._makePendingPayload('put', null));

            const prepend = this.n ?
                `${query.vayanData ?
                    `/v/${query.vayanData}` :
                    ''}${query.distributor ? `/d/${query.distributor}` :
                        ''}${query.company ? `/c/${query.company}` : ''}` :
                '';

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'put',
                    baseURL: this.uri,
                    url: `${prepend}/${this.resource}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    data,
                    params: query,
                });
                dispatch(this._makeSuccessPayload('put', res.data.data, null));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('put', error, null, flag));
                throw error;
            }
        }
    }

    remove(auth, identifier, query = {}) {
        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.${this.resource}`);

            const existing = !!state.byId[identifier];

            // dont do anything if it doesnt exists.
            if (!existing) {
                return null;
            }

            dispatch(this._makePendingPayload('remove', identifier));

            const prepend = this.n ?
                `${query.vayanData ?
                    `/v/${query.vayanData}` :
                    ''}${query.distributor ? `/d/${query.distributor}` :
                        ''}${query.company ? `/c/${query.company}` : ''}` :
                '';

            try {
                const token = await auth.getTokenSilently();
                await axios({
                    method: 'delete',
                    baseURL: this.uri,
                    url: `${prepend}/${this.resource}/${identifier}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: query,
                });
                dispatch(this._makeSuccessPayload('remove', null, identifier));
                return null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('remove', error, identifier, flag));
                throw error;
            }
        }
    }

    _makePendingPayload(operation, identifier) {
        return {
            type: this.actions[operation].pending,
            payload: {},
            meta: { timestamp: Date.now(), target: identifier },
        }
    }

    _makeSuccessPayload(operation, payload, identifier) {
        return {
            type: this.actions[operation].success,
            payload,
            meta: { timestamp: Date.now(), target: identifier },
        }
    }

    _makeErrorPayload(operation, error, identifier, hidden = false) {
        return {
            type: this.actions[operation].error,
            payload: { error, hidden },
            meta: { timestamp: Date.now(), target: identifier },
        }
    }

    _catchUnpredictedError(error, dispatch) {
        if (error.message === 'Network Error') {
            dispatch({
                type: SERVER_NETWORK_ERROR,
                payload: { error },
                meta: { timestamp: Date.now() },
            });
            return true;
        } else if (error.response && error.response.status === 500) {
            dispatch({
                type: INTERNAL_SERVER_ERROR,
                payload: { error },
                meta: { timestamp: Date.now() },
            });
            return true;
        } else {
            return false;
        }
    }

}
