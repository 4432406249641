import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { useImpersonateSdk } from '../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../lib/auth0-wrapper';
import RelationRoleChip from '../../../relations/component/element/relation-role-chip';
import UserEmailWithAvatar from '../../../users/component/element/user-email-with-avatar';
import UserLastLogin from '../../../users/component/element/user-last-login';
import UserLoginsCount from '../../../users/component/element/user-logins-count';
import RelationLastUpdate from '../element/relation-last-update';
import UserEmailEditable from '../../../users/component/element/user-email-editable';
import { TableController } from '../../../common/component/table/table-controller';
import { useNotif } from '../../../common/component/notification';
import ActionOk from '../../../common/component/notification/action-ok';
import { useCustomer } from '../../../customers/component/customer-provider';
import { useRelationListForCustomer } from '../../selectors';
import { useTranslation } from 'react-i18next';

export function RelationTableController(props) {

    const { add, edit, remove, exports, refresh, ...more } = props;

    const [isLoading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const notif = useNotif();
    const { t } = useTranslation('translation', { keyPrefix: 'app.relations.elements.table' });

    const customer = useCustomer();
    const relations = useRelationListForCustomer();
    const data = useMemo(() => _.cloneDeep(_.compact(relations)), [relations]);

    useEffect(() => {
        setLoading(true);
        dispatch(sdk.helpers.loadRelations(auth, customer.target))
            .finally(() => setLoading(false))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            });
    }, []);

    const columns = useMemo(() => [
        {
            title: t('headers.name'), field: 'payload.user', editable: 'onAdd',
            render: rowData => <UserEmailWithAvatar user={rowData.payload.user} relation={rowData.target} />,
            editComponent: UserEmailEditable
        },
        {
            title: t('headers.role'), field: 'payload.role', editable: 'always',
            render: rowData => <RelationRoleChip relation={rowData.target} />,
            lookup: { owner: 'OWNER', manager: 'MANAGER', user: 'USER' }
        },
        {
            title: t('headers.last-login'), editable: 'never',
            render: rowData => rowData && <UserLastLogin user={rowData.payload.user} relation={rowData.target} />
        },
        {
            title: t('headers.logins-count'), editable: 'never',
            render: rowData => rowData && <UserLoginsCount user={rowData.payload.user} relation={rowData.target} />
        },
        {
            title: t('headers.last-updated'), editable: 'never', search: false,
            render: rowData => rowData && <RelationLastUpdate relation={rowData.target} />
        },
    ], []);

    const onAdd = useCallback(async (data) => {
        const { user, role } = data.payload;
        const redirect = window.location.origin + window.location.pathname;
        return dispatch(sdk.relations.put(auth, customer.target, { user, role, redirect }))
            .then((data) => {
                notif.info(t('created'), { action: (key) => <ActionOk notif={notif} handle={key} /> });
                return Promise.resolve(data);
            })
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
                return Promise.reject(e);
            });
    }, [sdk, auth, customer]);

    const onUpdate = useCallback(async (data, old) => {
        const { _id, role } = data.payload;
        if (role === old.payload.role) {
            return;
        }
        return dispatch(sdk.relations.post(auth, _id, customer.target, { role }))
            .then((data) => {
                notif.info(t('updated'), { action: (key) => <ActionOk notif={notif} handle={key} /> });
                return Promise.resolve(data);
            })
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
                return Promise.reject(e);
            });
    }, [sdk, auth, customer]);

    const onDelete = useCallback(async (event, data) => {
        return dispatch(sdk.relations.remove(auth, data.target, customer.target))
            .then((data) => {
                notif.info(t('removed'), { action: (key) => <ActionOk notif={notif} handle={key} /> });
                return Promise.resolve(data);
            })
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
                return Promise.reject(e);
            });
    }, [sdk, auth, customer]);

    const onRefresh = useCallback((event) => {
        setLoading(true);
        return dispatch(sdk.helpers.loadRelations(auth, customer.target, true))
            .finally(() => setLoading(false))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            });
    }, [sdk, auth, customer.target]);

    const innerProps = {
        exportFileName: exports && 'members-list',
        pageSize: 8,
        columns,
        data,
        onAdd: add && onAdd,
        onUpdate: edit && onUpdate,
        onRefresh: refresh && onRefresh,
        onDelete: remove && onDelete,
        isLoading,
        ...more
    }

    return <TableController {...innerProps} />;
}

export function RelationsOverviewTable(props) {
    return (<RelationTableController add edit remove search refresh title='' />)
}

export function RelationsListTable({ onSelect, selected }) {
    return (<RelationTableController add edit search refresh title='' onSelect={onSelect} selected={selected} />)
}
