import React from 'react';
import { Typography } from '@material-ui/core';
import { useCustomerInDb } from '../../selectors';
import { useTranslation } from 'react-i18next';

function CustomerInvoicePolicy({ customer, capitalize, ...props }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.elements.customer-invoice-policy' });
    customer = useCustomerInDb(customer);
    const invoice = t(customer?.payload?.invoice_policy);
    
    return (
        <Typography
            {...props}
            style={{ textTransform: capitalize ? 'capitalize' : 'inherit' }}
        >
            {invoice}
        </Typography>

    )
}

export default CustomerInvoicePolicy;
