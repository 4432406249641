import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link } from '@material-ui/core';
import { useAuth0 } from '../../../lib/auth0-wrapper';
import { useSdk } from '../../../lib/licensing-sdk';


const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const useGiveConsent = (consent, onDone) => {
  const sdk = useSdk();
  const auth = useAuth0();

  const [loading, setLoading] = useState(false);

  const give = useCallback((opinion = true) => {
    setLoading(true);
    sdk.consents.give(auth, consent, opinion)
      .finally(() => {
        setLoading(false);
        onDone();
      })
  }, [consent, onDone]);

  return [give, loading];
}

export function useConsentsModalController() {
  const [open, setOpen] = useState(true);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return [open, handleOpen, handleClose];
}

function UserConsentsModalInternal({ consent, reload }) {
  const classes = useStyles();

  const [open, handleClose] = useConsentsModalController();

  const onDone = () => {
    handleClose();
    reload();
  }

  const [give, loading] = useGiveConsent(consent._id, onDone);
  const { mandatory, title, description, link } = consent;

  const refuse = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    give(false);
  };

  const accept = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    give(true);
  };

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth='lg'
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {description}
        </DialogContentText>
        {link && <Link rel='noopener' target='_blank' href={link.href}>{link.text || link.href}</Link>}
      </DialogContent>
      <DialogActions>
        {!mandatory && (
          <Button disabled={loading} onClick={refuse}>
            Refuse
          </Button>
        )}
        <Button disabled={loading} onClick={accept}>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}


export default function UserConsentsModal({ consent, reload }) {

  if (consent === null) {
    return null;
  }

  return <UserConsentsModalInternal consent={consent} reload={reload} />
}
