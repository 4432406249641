import { useCallback, useState } from 'react';
import { read, utils } from 'xlsx';
import _ from 'lodash';
import { object, string, number, boolean, array } from 'yup';

const PRODUCTS = "CSV-PRODUCT";
const PRODUCTS_COLUMNS = {
    name: 'Name',
    title: 'Title',
    description: 'Description',
    link: 'Doc_Link',
    index: 'Index',
    works_for: 'Cible',
    multiple_orders_allowed: 'Multiple Order',
    multiple_same_reference_allowed: 'Same Ref',
};

const REFERENCES = "CSV-REFERENCE";
const REFERENCES_COLUMNS = {
    reference: 'Reference',
    title: 'Title',
    description: 'Description',
    icon: 'Icon_Link',
    product: 'Product',
    can_revoke: 'Can_Revoke'
}; // other columns are couples of parameters.

const PRICES = "CSV-PRICE";
const PRICES_COLUMNS = {
    reference: 'Reference',
    name: 'Name',
    currency: 'Devise',
    type: 'Type',
    recurring_interval: 'Recurrence_Duration',
    recurring_unit: 'Recurence_Unit',
    tier_mode: 'Mode',
    unit_amount: 'PerUnit_Amount',
} // others columns are linked refs and tiers.


const PARAMS = "CSV-PARAM";
const PARAMS_COLUMNS = {
    name: 'Displayed Name',
    policy_on_value: 'Policy',
    key: 'Key',
}

const ProductSchema = object({
    name: string().required('Name must exists in product'),
    index: number().min(0).required('Must provide an index in product'),
    description: object({
        title: string().required('Title must be set in product'),
        description: string().ensure(),
        link: object({
            href: string().required('Must set a link url in product'),
            text: string().required('Link text must be set in product'),
        })
    }).noUnknown(),
    rules: object({
        multiple_orders_allowed: boolean().required('multiple_orders_allowed must be set in product'),
        multiple_same_reference_allowed: boolean().required('multiple_same_reference_allowed must be set in product'),
    }).noUnknown(),

    works_for: string().ensure().lowercase().oneOf(['device', 'session']).required('Works for must be set in product'),
}).noUnknown();

const ReferenceSchema = object({
    reference: string().required('Reference must exists in reference'),
    product: string().required('Product name must exists in reference'),
    description: object({
        title: string().required('Title must exists in reference'),
        description: string().ensure(),
        logo: string().required('Link to logo must be set in reference'),
    }).noUnknown(),
    parameters: array().of(object({
        category: string().required('parameter category must be set in reference parameters'),
        value: string().required('value must be set in reference parameters'),
    }).noUnknown()),
    sessions: object({
        can_revoke: boolean().required('can_revoke must be set in reference session'),
    }).noUnknown(),
}).noUnknown();

const PriceSchema = object({
    reference: string().required('Reference must exists in price'),
    name: string().required('Price name must exists in price'),
    currency: string().ensure().uppercase().oneOf(['EUR']).required('Currency must be set in price'),
    unit_amount: number().min(0).required('unit amount must be set in price'),
    type: string().ensure().lowercase().oneOf(['one-time', 'recurring']).required('Type must be RECURRING or ONE-TIME in price'),

    recurring: object({
        unit: string().ensure().lowercase().oneOf(['days', 'months', 'years']).required('recurring unit must be days, months or years in price'),
        interval: number().integer().min(0).required('recurring interval must be set in price'),
        upgrade_proration_behavior: string().required('upgrade_proration_behavior must be set in price'),
        downgrade_proration_behavior: string().required('downgrade_proration_behavior must be set in price'),
    }).noUnknown(),

    tiers_mode: string().ensure().lowercase().oneOf(['per_unit', 'graduated']).required('tiers_mode must be set in price'),
    tiers: array().of(object({
        up_to: number().integer().min(0).required('tier up_to must be set in price'),
        amount: number().min(0).required('tier amount must be set in price'),
        last: boolean().required('recurring last_tier must be set in price'),
    }).noUnknown()),

    associatedFreePrices: array().of(string().required('Associated free refs must be set in price.')),
}).noUnknown();

const ParamsSchema = object({
    name: string().required('Param name must exists'),
    policy_on_value: string().ensure().lowercase().oneOf(['max', 'min', 'sum', 'and', 'or', 'distinct', 'flatten']).required('Param policy must exists'),
    key: string().required('Param key must exists'),
});


const parseProductLine = (line) => {
    let product = {
        name: line[PRODUCTS_COLUMNS.name],
        index: line[PRODUCTS_COLUMNS.index],
        description: {
            title: line[PRODUCTS_COLUMNS.title],
            description: line[PRODUCTS_COLUMNS.description],
            link: {
                href: line[PRODUCTS_COLUMNS.link],
                text: line[PRODUCTS_COLUMNS.link],
            }
        },
        rules: {
            multiple_orders_allowed: line[PRODUCTS_COLUMNS.multiple_orders_allowed] === 'Yes',
            multiple_same_reference_allowed: line[PRODUCTS_COLUMNS.multiple_same_reference_allowed] === 'Yes',
        },
        works_for: line[PRODUCTS_COLUMNS.works_for],
    }

    product = ProductSchema.validateSync(product);
    return product;
}

const parseReferenceLine = (line) => {
    let reference = {
        reference: line[REFERENCES_COLUMNS.reference],
        product: line[REFERENCES_COLUMNS.product],
        description: {
            title: line[REFERENCES_COLUMNS.title],
            description: line[REFERENCES_COLUMNS.description],
            logo: line[REFERENCES_COLUMNS.icon],
        },
        parameters: [],
        sessions: {
            can_revoke: line[REFERENCES_COLUMNS.can_revoke] === 'Yes'
        },
    }

    for (let i = 1; true; i++) {
        const nameColumn = `Param${i}`;
        const valueColumn = `Value_Param${i}`;
        const category = line[nameColumn];
        const value = line[valueColumn];
        if (!category) break;

        reference.parameters.push({ category, value });
    }

    reference = ReferenceSchema.validateSync(reference);
    return reference;
}

const parsePriceLine = (line) => {
    let price = {
        reference: line[PRICES_COLUMNS.reference],
        name: line[PRICES_COLUMNS.name],
        currency: line[PRICES_COLUMNS.currency],
        unit_amount: parseFloat(line[PRICES_COLUMNS.unit_amount]),
        type: line[PRICES_COLUMNS.type],

        recurring: {
            unit: line[PRICES_COLUMNS.recurring_unit] || 'days',
            interval: parseInt(line[PRICES_COLUMNS.recurring_interval]) || 0,
            upgrade_proration_behavior: 'always_invoice',
            downgrade_proration_behavior: 'none',
        },

        tiers_mode: line[PRICES_COLUMNS.tier_mode],
        tiers: [],
        associatedFreePrices: [],
    };

    for (let i = 1; true; i++) {
        const uptoColumn = `Upto_Tier${i}`;
        const amountColumn = `Amount_Tier${i}`;
        const lastTierColumn = `Last_Tier${i}`;

        const up_to = parseInt(line[uptoColumn]);
        const amount = parseFloat(line[amountColumn]);
        const last = line[lastTierColumn] === 'TRUE';
        if (isNaN(up_to)) break;

        price.tiers.push({ up_to, amount, last });
    }

    for (let i = 1; true; i++) {
        const linkedRefColumn = `REFFree_With${i}`;

        const freePrice = line[linkedRefColumn];
        if (!freePrice) break;
        price.associatedFreePrices.push(freePrice);
    }

    price = PriceSchema.validateSync(price);
    return price;
}

const parseParamsLine = (line) => {
    let param = {
        name: line[PARAMS_COLUMNS.name] || line[PARAMS_COLUMNS.key],
        policy_on_value: line[PARAMS_COLUMNS.policy_on_value] || 'flatten',
        key: line[PARAMS_COLUMNS.key],
    };

    param = ParamsSchema.validateSync(param);
    return param;
}

export const useParseXlsxFile = () => {
    const [parsing, setParsing] = useState(false);
    const [error, setError] = useState(null);
    const [results, setResults] = useState(null);

    const parser = useCallback(async (fileinput) => {
        return await new Promise((resolve, reject) => {
            setResults(null);
            setError(null);
            const reader = new FileReader()
            reader.onload = async (event) => {
                try {
                    setParsing(true);
                    const binary = event.target.result;
                    const wb = read(binary);

                    const productsSheet = wb.Sheets[PRODUCTS];
                    const referencesSheet = wb.Sheets[REFERENCES];
                    const pricesSheet = wb.Sheets[PRICES];
                    const paramsSheet = wb.Sheets[PARAMS];

                    if (productsSheet === undefined) {
                        throw new Error(`Missing "${PRODUCTS}" sheet`);
                    }
                    if (referencesSheet === undefined) {
                        throw new Error(`Missing "${REFERENCES}" sheet`);
                    }
                    if (pricesSheet === undefined) {
                        throw new Error(`Missing "${PRICES}" sheet`);
                    }
                    if (paramsSheet === undefined) {
                        throw new Error(`Missing "${PARAMS}" sheet`);
                    }

                    const results = {
                        products: _.map(utils.sheet_to_json(productsSheet, {}), parseProductLine),
                        references: _.map(utils.sheet_to_json(referencesSheet, {}), parseReferenceLine),
                        prices: _.map(utils.sheet_to_json(pricesSheet, {}), parsePriceLine),
                        parameterCategories: _.map(utils.sheet_to_json(paramsSheet, {}), parseParamsLine),
                    }

                    setResults(results);
                    resolve(results);
                } catch (e) {
                    setError(e);
                    reject(e);
                } finally {
                    setParsing(false);
                }

            };
            reader.readAsArrayBuffer(fileinput);
        });

    }, []);

    return { parser, parsing, error, results }
}
