import React from 'react';
import {
    Grid, Typography, ExpansionPanelSummary,
    ExpansionPanelDetails, Divider, ExpansionPanel, makeStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DefaultInvoiceList from './invoice-list';
import { useMainCompany } from '../../relations/component/user-relation-provider';
import GenerateInvoiceButton from '../../customers/component/generate-invoice/generate-invoice-button';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme=> ({
    actionRight: {
        marginLeft: 'auto',
    }
}));

const InvoicesListInPanel = ({defaultExpanded}) => {

    const classes = useStyles();
    const company = useMainCompany();
    const { t } = useTranslation('translation', { keyPrefix: 'app.invoices.list' });

    return (
        <ExpansionPanel defaultExpanded={defaultExpanded}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h4'>{t('title')}</Typography>
                <GenerateInvoiceButton className={classes.actionRight} />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DefaultInvoiceList company={company} />
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
            <Divider />
        </ExpansionPanel>
    );
}

export default InvoicesListInPanel;