import React from 'react';
import { Chip } from '@material-ui/core';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { ResourceGetter } from '../../../../lib/licensing-sdk/getter';

function RelationRoleChip({ relation }) {
    return (
        <ResourceGetter id={relation} resource='relations' render={({ relation }) =>
            <WaitingLoaded data={relation} render={() => (
                <Chip label={relation.payload.role} variant='outlined' color='primary' />
            )} />
        } />
    )
}

export default RelationRoleChip;
