import React from 'react';
import { Checkbox } from '@material-ui/core';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { makeStyles } from '@material-ui/styles';
import { CheckBox, IndeterminateCheckBoxOutlined } from '@material-ui/icons';
import { ResourceGetter } from '../../../../lib/licensing-sdk/getter';

const useStyles = makeStyles(theme => ({
    active: {
        color: theme.palette.primary[theme.palette.type],
    },
    inactive: {
        color: 'inherit',
    }
}));

function DeviceOfficeDemoCheckbox({ device }) {

    const classes = useStyles();

    return (
        <ResourceGetter resource='devices' id={device} render={({ device }) =>
            <WaitingLoaded data={device} render={() => (
                <Checkbox checked disabled color='primary'
                    checkedIcon={device.payload.office_demo
                        ? <CheckBox className={classes.active} />
                        : <IndeterminateCheckBoxOutlined className={classes.inactive} />}
                />
            )} />
        } />
    )
}

export default DeviceOfficeDemoCheckbox;
