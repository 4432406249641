import React from 'react';
import { Typography } from '@material-ui/core';
import { useReferenceById } from '../../selector';
import { useTranslation } from 'react-i18next';

export const useReferenceName = (reference) => {
    const { t } = useTranslation('translation', { keyPrefix: 'app.references.elements' })
    if (!(reference && reference.payload)) {
        return t('reference-not-exists');
    }
    return reference.payload.description.title ? reference.payload.description.title : reference.payload.reference;
}

function ReferenceName({ reference, ...props }) {

    reference = useReferenceById(reference);
    const name = useReferenceName(reference);

    return (
        <Typography {...props}>
            {name}
        </Typography>
    )
}

export default ReferenceName;
