import React from 'react';
import { MTableToolbar } from 'material-table';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
 
    highlight: {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.background.paper,
    },
    
}));

export default function Toolbar(props) {
    const classes = useStyles();
    return <MTableToolbar {...props} classes={classes} />

}