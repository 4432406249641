import React, { useMemo } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import WaitingLoaded from '../../../../../common/component/waiting-loaded';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    field: {
        width: '100%'
    }
}));

const CompanyTaxRateField = (props) => {

    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.forms.billing-form.fields.company-tax-rate-field' });
    const { company } = props;
    const classes = useStyles();

    const render = useMemo(() => () => (
        <TextField
            className={classes.field}
            disabled
            name="tax"
            type="text"
            label={t('label')}
            value={company.payload.prefered_tax ? company.payload.prefered_tax.name : t('no-value')}
        />
    ), [company.payload])

    return (
        <WaitingLoaded data={company} render={render} />
    )
}


export default CompanyTaxRateField;
