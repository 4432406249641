import React, { useCallback, useState, useEffect } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { push } from 'connected-react-router';

import { useDispatch } from 'react-redux';
import { useAuth0 } from '../../../../lib/auth0-wrapper';
import { useImpersonateSdk } from '../../../../lib/licensing-sdk';
import { useNotif } from '../../../common/component/notification';
import ActionOk from '../../../common/component/notification/action-ok';
import Loader from '../../../common/component/loader';
import CenteredFullScreen from '../../../common/component/centered-full-screen';
import { useCustomer } from '../../../customers/component/customer-provider';
import { useTranslation } from 'react-i18next';
import OrderHistoryPage from './order-history-page';
import OrderDetailsPage from './order-details-page';
import OpenOrdersPage from './open-orders-page';

export function LoadingOrders() {
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.layout' });
    return (
        <CenteredFullScreen>
            <Loader message={t('loading')} />
        </CenteredFullScreen>
    );
}

function OrderLayout(props) {

    const auth = useAuth0();
    const sdk = useImpersonateSdk();
    const dispatch = useDispatch();
    const notif = useNotif();

    let { url } = useRouteMatch();
    url = url.endsWith('/') ? url.slice(0, -1) : url;
    const [loading, setLoading] = useState(true);

    const pushCallback = useCallback((order) => {
        dispatch(push(`${url}/history/${order.target}`));
        // eslint-disable-next-line   
    }, []);

    const customer = useCustomer();

    useEffect(() => {
        dispatch(sdk.helpers.loadOrders(auth, customer.target))
            .finally(() => setLoading(false))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            });
        // eslint-disable-next-line   
    }, []);

    if (loading) {
        return <LoadingOrders />
    }

    return (
        <Switch>
            <Route path={`${url}/cart`} render={() => <OpenOrdersPage />} />
            <Route path={`${url}/history`} exact render={() => <OrderHistoryPage push={pushCallback} />} />
            <Route path={`${url}/history/:orderId`} render={() => <OrderDetailsPage/>} />
        </Switch>
    )

}

export default OrderLayout;
