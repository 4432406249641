import React, { forwardRef, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useImpersonateSdk } from '../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../lib/auth0-wrapper';

import InteractiveLoadingButton from '../../../common/component/interactive-loading-button';
import { Button } from '@material-ui/core';
import ActionOk from '../../../common/component/notification/action-ok';
import { useNotif } from '../../../common/component/notification';
import { useCustomer } from '../../../customers/component/customer-provider';
import { STATUS_CANCELLED } from '../../constants';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    button: {
        marginRight: theme.spacing(1),
    },
}));

export const UncancelSubscriptionButtonView = forwardRef(({ ...props }, ref) => {

    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.order-lines.actions.prevent-cancellation' });

    return (
        <Button className={classes.button} {...props} ref={ref}>
            {t('button-label')}
        </Button>
    )
});

export function UncancelSubscriptionController(props) {

    const { component, line, ...innerProps } = props;

    const dispatch = useDispatch();
    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const notif = useNotif();
    const { t } = useTranslation('translation', { keyPrefix: 'app.order-lines.actions.prevent-cancellation' });
    const customer = useCustomer();

    const loading = line.status.includes('pending');
    const erred = line.status.includes('error');
    const disabled = loading || erred;
    const tooltip =
        loading ? t('tooltip.loading') :
            erred ? t('tooltip.error') :
                t('tooltip.default');

    innerProps.disabled = disabled;
    innerProps.onClick = useCallback(() => {
        dispatch(sdk.orderlines.uncancel(auth, line.target, customer.target))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            });
        // eslint-disable-next-line   
    }, [sdk, auth, line, dispatch, customer]);

    if (![STATUS_CANCELLED]
        .includes(line.payload.status)) {
        return null;
    }

    return (
        <InteractiveLoadingButton tooltip={tooltip} loading={loading} >
            {React.createElement(component, innerProps)}
        </InteractiveLoadingButton>
    )
}

export default function UncancelSubscriptionButton({ line, ...props }) {
    return (
        <UncancelSubscriptionController
            {...props}
            line={line}
            component={UncancelSubscriptionButtonView}
        />
    )
}
