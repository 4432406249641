import React from 'react';
import ReferenceMedia from './reference-media';
import ReferenceMediaContent from './reference-media-content';

export default function ReferenceCardHeader({ price, reference }) {

    return (
        <ReferenceMedia reference={reference} >
            <ReferenceMediaContent reference={reference} price={price} />
        </ReferenceMedia>
    );

}