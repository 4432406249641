import React from 'react';
import { Form } from 'formik';
import {
    Button
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import AddressFormPanel from '../views/expansion-panel';
import AddressFormBaseCtrl from '.';
import { useAuth0 } from '../../../../../../lib/auth0-wrapper';
import { useSdk } from '../../../../../../lib/licensing-sdk';
import { useNotif } from '../../../../../common/component/notification';
import ActionOk from '../../../../../common/component/notification/action-ok';
import { useCustomer } from '../../../../../customers/component/customer-provider';
import { useMainCompany } from '../../../../../relations/component/user-relation-provider';
import { useViewingAsDistributor, useViewingAsVayanData } from '../../../../../customers/selectors';
import { useTranslation } from 'react-i18next';

const ChangeAddressFormCtrl = ({ defaultCompany, forceVD, ...props }) => {

    const dispatch = useDispatch();
    const sdk = useSdk();
    const auth = useAuth0();
    const notif = useNotif();
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.forms.address-form.controllers.change' })

    const isVayanData = useViewingAsVayanData();
    const isDistributor = useViewingAsDistributor();
    const company = useMainCompany();
    const customer = useCustomer();

    return <AddressFormBaseCtrl {...props} company={defaultCompany || customer} onSubmit={(values) => {
        const imp = forceVD || isVayanData ? sdk.impersonateByVayanData(company.target) :
            isDistributor ? sdk.impersonateByDistributor(company.target) :
                sdk;

        return dispatch(imp.companies.postAddress(auth, defaultCompany?.target || customer.target, values))
            .then((data) => {
                notif.info(t('success'), { action: (key) => <ActionOk notif={notif} handle={key} /> });
                return Promise.resolve(data);
            })
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> })
            });
    }} />

}

const ChangeCompanyAddressFormPanel = ({ submitForm, resetForm, enableActions, ...props }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.forms.address-form.controllers.change' })
    return (
        <Form>
            <AddressFormPanel {...props} actions={
                enableActions &&
                    (<div>
                        <Button size="small"
                            disabled={props.isSubmitting}
                            onClick={resetForm}>
                                {t('cancel')}
                        </Button>
                        <Button size="small"
                            color='secondary'
                            disabled={props.isSubmitting}
                            onClick={submitForm}>
                                {t('save')}
                        </Button>
                    </div>)
            } />
        </Form>
    );
}

export default function ChangeCompanyAddressPanel(props) {
    return <ChangeAddressFormCtrl {...props} component={ChangeCompanyAddressFormPanel} />
}

