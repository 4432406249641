import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    field: {
        width: '100%'
    }
}));

export const billingEmailSchema = (t) => ({
    billing_email: Yup.string().email().required(t('billing-email-validation')),
})

const CompanyBillingEmailField = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.forms.billing-form.fields.company-billing-email-field' });

    return (
        <Field
            {...props}
            component={TextField}
            className={classes.field}
            name="billing_email"
            type="text"
            label={t('label')}
        />
    )
}


export default CompanyBillingEmailField;
