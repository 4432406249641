import React, { forwardRef, useCallback } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import ExposureNeg1Icon from '@material-ui/icons/ExposureNeg1';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useImpersonateSdk } from '../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../lib/auth0-wrapper';

import InteractiveLoadingButton from '../../../common/component/interactive-loading-button';
import { IconButton } from '@material-ui/core';
import ActionOk from '../../../common/component/notification/action-ok';
import { useNotif } from '../../../common/component/notification';
import { useCustomer } from '../../../customers/component/customer-provider';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    button: {
        marginRight: theme.spacing(1),
    },
}));

export const RemoveFromOrderView = forwardRef(({ minusIcon, ...props }, ref) => {

    const classes = useStyles();

    return (
        <IconButton className={classes.button} size='small' {...props} ref={ref}>
            {
                minusIcon ?
                    <ExposureNeg1Icon />
                    : <DeleteIcon />
            }
        </IconButton>
    )
});

export function RemoveFromOrderController(props) {

    const { component, line, ...innerProps } = props;

    const { t } = useTranslation('translation', { keyPrefix: 'app.order-lines.actions.remove-from-order' });
    const dispatch = useDispatch();
    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const notif = useNotif();

    const customer = useCustomer();

    const loading = line.status.includes('pending');
    const erred = line.status.includes('error');
    const disabled = loading || erred;
    const tooltip =
        loading ? t('tooltip.loading') :
            erred ? t('tooltip.error') :
                t('tooltip.default');

    innerProps.disabled = disabled;
    innerProps.onClick = useCallback(() => {
        const order = line.payload.order;
        dispatch(sdk.orderlines.remove(auth, line.target, customer.target))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            });
        // eslint-disable-next-line   
    }, [sdk, auth, line, dispatch, customer]);

    return (
        <InteractiveLoadingButton tooltip={tooltip} loading={loading} >
            {React.createElement(component, innerProps)}
        </InteractiveLoadingButton>
    )
}



export default function RemoveFromOrderButton({ line, ...props }) {
    return (
        <RemoveFromOrderController
            {...props}
            line={line}
            component={RemoveFromOrderView}
        />
    )
}
