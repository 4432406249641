import React from 'react';
import {
    Grid, Typography
} from '@material-ui/core';
import CompanyTaxRateField from '../fields/company-tax-rate-field';
import CompanyCurrencyField from '../fields/company-currency-field';
import CompanyTaxIdField from '../fields/company-tax-id-field';
import CompanyBillingEmailField from '../fields/company-billing-email-field';
import CompanyMetadataField from '../fields/company-metadata-field';
import { useCompanyIsVayanData } from '../../../../selector';
import { useTranslation } from 'react-i18next';

export default function BillingFormGrid({
    taxIdFieldEnabled, currencyFieldEnabled, billingEmailFieldEnabled, metadataFieldEnabled,
    ...props }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.forms.billing-form.views.grid' });
    const isVayanData = useCompanyIsVayanData();

    return (
        <Grid container spacing={2} alignItems='center'>

            <Grid item xs={3}><Typography>{t('billing-email')}</Typography></Grid>
            <Grid item xs={9}>
                <CompanyBillingEmailField disabled={!billingEmailFieldEnabled} {...props} />
            </Grid>

            <Grid item xs={3}><Typography>{t('tax-rate')}</Typography></Grid>
            <Grid item xs={9}>
                <CompanyTaxRateField disabled {...props} />
            </Grid>

            <Grid item xs={3}><Typography>{t('currency')}</Typography></Grid>
            <Grid item xs={9}>
                <CompanyCurrencyField disabled={!currencyFieldEnabled} {...props} />
            </Grid>

            <Grid item xs={3}><Typography>{t('tax-id')}</Typography></Grid>
            <Grid item xs={9}>
                <CompanyTaxIdField disabled={!taxIdFieldEnabled} {...props} />
            </Grid>

            {isVayanData && (
                <>
                    <Grid item xs={3}><Typography>{t('metadata')}</Typography></Grid>
                    <Grid item xs={9}>
                        <CompanyMetadataField disabled={!metadataFieldEnabled} {...props} />
                    </Grid>
                </>
            )}

        </Grid>
    );
}
