import React from 'react';
import { Field } from 'formik';
import { Checkbox } from 'formik-material-ui';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    field: {
        
    }
}));

const CompanyIsDistributorField = ({ namespace, isSubmitting, ...props }) => {

    const classes = useStyles();
    const name = `${namespace ? namespace + '.' : ''}distributor`;

    return (
        <Field
            className={classes.field}
            component={Checkbox}
            name={name}
            disabled={isSubmitting}
        />
    );


}


export default CompanyIsDistributorField;
