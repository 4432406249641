import React, { useMemo, useEffect } from 'react';
import { Field } from 'formik';
import { Select } from 'formik-material-ui';
import { makeStyles } from '@material-ui/styles';
import { MenuItem, FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import regions from 'country-region-data';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

let countiesByCountry = _.mapValues(_.keyBy(regions, 'countryName'), country => _.map(country.regions, 'name'));

const useStyles = makeStyles(theme => ({
    field: {
        width: '100%'
    }
}));

const CompanyCountyField = ({ namespace, errors, values, isSubmitting, setFieldValue, ...props }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.forms.address-form.fields.county' });
    const classes = useStyles();
    const name = `${namespace ? namespace + '.' : ''}county`;
    const countryName = `${namespace ? namespace + '.' : ''}country`;
    const country = _.get(values, countryName);
    const error = _.get(errors, name);
    const counties = useMemo(() => countiesByCountry[country], [country]);
    
    useEffect(() => {
        const county = _.get(values, name);
        if (county !== '' && (counties === undefined || !counties.includes(county))) {
            setFieldValue(name, '');
        }
    }, [country]);


    return (
        <FormControl className={classes.field} error={Boolean(error)}>
            <InputLabel id="company-county-field">{t('label')}</InputLabel>
            <Field
                labelId="company-county-field"
                component={Select}
                name={name}
                disabled={isSubmitting}
                validate={value => counties && counties.includes(value) ? undefined : t('validation')}
            >
                {counties && counties.map(c => <MenuItem key={c} value={c}>{c}</MenuItem>)}
            </Field>
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    );
}

export default CompanyCountyField;
