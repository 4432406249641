import React from 'react';
import { Field } from 'formik';
import { Select } from 'formik-material-ui';
import { makeStyles } from '@material-ui/styles';
import { MenuItem, FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    field: {
        width: '100%'
    }
}));

export const TRUSTED = 'trusted';
export const UNTRUSTED = 'untrusted';
export const TRUST_POLICIES = [UNTRUSTED, TRUSTED];

const CompanyTrustPolicyField = ({ namespace, errors, disabled, isSubmitting }) => {

    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.forms.trust-policy-field' });
    const name = `${namespace ? namespace + '.' : ''}trust_policy`;
    const error = _.get(errors, name);

    return (
        <FormControl className={classes.field} error={Boolean(error)}>
            <InputLabel id="company-trust-policy-field">{t('label')}</InputLabel>
            <Field
                labelId="company-trust-policy-field"
                component={Select}
                name={name}
                disabled={disabled || isSubmitting}
                validate={value => TRUST_POLICIES.includes(value) ? undefined : t('validation')}
            >
                {TRUST_POLICIES.map(c => <MenuItem key={c} value={c}>{c.toUpperCase()}</MenuItem>)}
            </Field>
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    )
}


export default CompanyTrustPolicyField;
