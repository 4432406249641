import React from 'react';
import { Typography } from '@material-ui/core';
import { useCustomerInDb } from '../../selectors';

function CustomerName({ customer, ...props }) {

    customer = useCustomerInDb(customer);

    return (
        <Typography {...props}>{customer?.payload?.name || ''}</Typography>
    )
}

export default CustomerName;