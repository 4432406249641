import React, { Fragment, useState } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import CSVImportModal from './csv-import-modal';
import { useTranslation } from 'react-i18next';

function CSVImportSidebarButton({}) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.products.csv-import' })
    const [open, setOpen] = useState(false);
    const onClose = () => setOpen(false);
    const onOpen = () => setOpen(true);

    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb(ev);
    }

    return (
        <Fragment>
            <ListItem button onClick={handleClick(onOpen)}>
                <ListItemIcon><SystemUpdateAltIcon /></ListItemIcon>
                <ListItemText primary={t('sidebar-label')} />
            </ListItem >
            <CSVImportModal open={open} onClose={handleClick(onClose)} />
        </Fragment>
    );
}

export default CSVImportSidebarButton;