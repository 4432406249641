import React, { useState, useMemo } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import WaitForSetupIntent from './wait-for-setup-intent';
import { useSdk } from '../../../../../lib/licensing-sdk';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '../../../../../lib/auth0-wrapper';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useNotif } from '../../../../common/component/notification';
import ActionOk from '../../../../common/component/notification/action-ok';
import { useCustomer, useCustomerAPIAuthorization } from '../../../../customers/component/customer-provider';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    cardElement: {
        minWidth: '500px',
        padding: theme.spacing(3),
    }
}));

function CardSetupForm({ stripe, elements, secret, onClose }) {

    const [loading, setLoading] = useState(false);
    const sdk = useSdk();
    const dispatch = useDispatch();
    const auth = useAuth0();
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.forms.payment-method-form.credit-card-setup-dialog' });
    const customer = useCustomer();
    const { distributor, vayanData } = useCustomerAPIAuthorization();

    const notif = useNotif();
    const classes = useStyles();
    const theme = useTheme();

    const cardOptions = useMemo(() => ({
        style: {
            base: {
                color: theme.palette.text.primary,
                fontFamily: theme.typography.fontFamily,
                fontSmoothing: "antialiased",
                fontSize: theme.typography.fontSize,
                "::placeholder": {
                    color: theme.palette.text.hint,
                },
            },
            invalid: {
                color: theme.palette.error[theme.palette.type],
                iconColor: theme.palette.error[theme.palette.type],
            },
        },
    }), [theme]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setLoading(true);

        const result = await stripe.confirmCardSetup(secret, {
            payment_method: {
                card: elements.getElement(CardElement),
            }
        }).catch(e => {
            notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
        });

        if (result.error) {
            notif.error(result.error, { action: (key) => <ActionOk notif={notif} handle={key} /> });
        } else {

            try {
                await dispatch(sdk.paymentmethods.put(auth, result.setupIntent, { company: customer.target, distributor, vayanData }))
                notif.info(t('registered'), { action: (key) => <ActionOk notif={notif} handle={key} /> });
                await dispatch(sdk.companies.get(auth, customer.target, { company: customer.target, distributor, vayanData }, true))
            } catch (e) {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> })
            }

            onClose();
        }

        setLoading(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <DialogTitle>{t('title')}</DialogTitle>
            <CardElement className={classes.cardElement} options={cardOptions} />
            <DialogActions>
                <Button disabled={loading} onClick={onClose}>
                    {t('cancel')}
                </Button>
                <Button disabled={loading} type="submit" color='secondary' >
                    {t('add')}
                </Button>
            </DialogActions>
        </form>
    );
}

export default function CardSetupDialog({ open, onClose }) {

    return (
        <Dialog disableBackdropClick open={open} onClose={onClose}>
            <WaitForSetupIntent component={CardSetupForm} onClose={onClose} />
        </Dialog >
    );
}