import React from 'react';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { Typography } from '@material-ui/core';
import { ResourceGetter } from '../../../../lib/licensing-sdk/getter';
import { GRADE_VAYAN_DATA } from '../../constants';
import { useTranslation } from 'react-i18next';

function CompanyGrade({ company, capitalize, ...props }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.elements.company-grade' })

    return (
        <ResourceGetter resource='companies' id={company} render={({ companie: company }) => (
            <WaitingLoaded data={company} render={() => {
                const grade = t(company.payload.grade);
                const coefficient = company.payload.coefficient;
                
                return (
                    <Typography
                        {...props}
                        style={{ textTransform: capitalize ? 'capitalize' : 'inherit' }}
                    >
                        {grade} ({coefficient})
                    </Typography>
                )
            }} />
        )} />

    )
}

export default CompanyGrade;
