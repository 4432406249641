import _ from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useSdk } from '../../lib/licensing-sdk';
import { useCustomer } from '../customers/component/customer-provider';
import { useMainCompany } from '../relations/component/user-relation-provider';

export function useFindProductById(id) {
    const sdk = useSdk();
    const selector = useMemo(() => createSelector(
        sdk.products.selectors.getDb,
        (productsDb) => {
            return productsDb[id];
        }
    ), [id]);

    return useSelector(selector);
}

function useProductListFromPrices(prices) {
    const sdk = useSdk();
    const selector = useMemo(() => createSelector(
        sdk.prices.selectors.getDb,
        sdk.references.selectors.getDb,
        sdk.products.selectors.getDb,
        (pricesDb, referencesDb, productsDb) => {
            const products = _.chain(prices)
                .map(price => pricesDb[price])
                .compact()
                .map('payload.reference')
                .uniq()
                .map(ref => referencesDb[ref])
                .compact()
                .map('payload.product')
                .uniq()
                .map(product => productsDb[product])
                .sortBy('payload.index')
                .compact()
                .value();

            return products;
        }
    ), [prices]);

    return useSelector(selector);
}

export function useProductListForCustomer() {
    const customer = useCustomer();
    const prices = useMemo(() => customer.payload.prices, [customer]);
    return useProductListFromPrices(prices);
}

export function useProductListForCustomerAndCompany() {
    const customer = useCustomer();
    const company = useMainCompany();
    const prices = useMemo(() => _.chain(customer.payload.prices)
        .concat(company.payload.prices)
        .uniq()
        .value(), [customer]);
    return useProductListFromPrices(prices);
}