import React from 'react';
import classnames from 'classnames';
import { CardMedia, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: props => ({
        position: 'relative',
        width: '100%',
        "&::before": {
            content: '" "',
            display: 'block',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            opacity: '0.2',
            backgroundImage: props.background ? `url("${props.background}")` : "none",
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundColor: theme.palette.primary.main,
        }
    }),
    content: {
        position: 'relative'
    },
}));

function BCardMedia({ background, title, className, children, ...props }) {

    const classes = useStyles({ background });

    return (
        <CardMedia className={classnames(classes.root, className)} {...props} title={title}>
            <div className={classes.content}>
                {children}
            </div>
        </CardMedia>
    )
}

export default BCardMedia;
