import React from 'react';
import _ from 'lodash';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { ResourceGetter } from '../../../../lib/licensing-sdk/getter';
import { Typography } from '@material-ui/core';

function DeviceSubscriptionsCount({ device, ...props }) {

    return (
        <ResourceGetter resource='devices' id={device} render={({ device }) =>
            <WaitingLoaded data={device} render={() => <Typography {...props}>{device.payload.lines.length}</Typography>} />
        } />
    )
}

export default DeviceSubscriptionsCount;
