import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    center: {
        alignContent: 'center'
    }
}))

export default function InteractiveLoadingButton({ tooltip, children }) {

    const classes = useStyles();

    if (tooltip) {
        return (
            <Tooltip title={tooltip} aria-label={tooltip}>
                <span className={classes.center}>
                    {children}
                </span>
            </Tooltip>
        )
    } else {
        return children;
    }
}
