import _ from 'lodash';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useSdk } from '../../lib/licensing-sdk';

export function useDeviceCountForCustomer(customer) {
    const sdk = useSdk();
    const selector = useMemo(() => createSelector(
        sdk.devices.selectors.getDb,
        (devices) => {
            const list = _.chain(devices)
                .filter(device => device.payload)
                .filter(device => device.payload.created_by === customer)
                .value();
            return list.length;
        }
    ), [customer]);
    return useSelector(selector);
}

export function useDeviceListForCustomer(customer) {
    const sdk = useSdk();
    const selector = useMemo(() => createSelector(
        sdk.devices.selectors.getDb,
        (devices) => {
            const list = _.chain(devices)
                .filter(device => device.payload)
                .filter(device => device.payload.created_by === customer)
                .value();
            return list;
        }
    ), [customer]);
    return useSelector(selector);
}





