import React from 'react';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { ResourceGetter } from '../../../../lib/licensing-sdk/getter';

export function getLabel(device) {
    return device.payload?.label || null;
}

function DeviceLabel({ device }) {
    return (
        <ResourceGetter resource='devices' id={device} render={({ device }) =>
            <WaitingLoaded data={device} render={() => device.payload.label} />
        } />
    )
}

export default DeviceLabel;
