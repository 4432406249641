
export const allowedCurrencies = ['EUR', 'GBP', 'USD'];

export const toDecimal = (currency, amount) => {
    return amount / 100;
}

export default {
    'EUR': '€',
    'GBP': '£',
    'USD': '$',
    [undefined]:'TODO' 
}
