import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography, Button, Grid } from '@material-ui/core';
import CenteredFullScreen from '../../common/component/centered-full-screen';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    spaced: {
        marginTop: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(2),
    },
}));

function NoMatch(props) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.error.404' });
    const classes = useStyles();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1500);
        return () => clearTimeout(timer);
    }, []);

    return (
        <CenteredFullScreen>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant='h5' component='h3'>{t('404')}</Typography>
                    <Typography>{t('description')}</Typography>
                </Grid>
                <Grid className={classes.spaced} item xs={12}>
                    {loading ?
                        <CircularProgress />
                        :
                        <Button className={classes.button} variant='outlined' onClick={(ev) => {
                            if (ev) {
                                ev.preventDefault();
                                ev.stopPropagation();
                            }

                            window.location.replace('/');
                        }}>{t('home-button')}</Button>
                    }
                </Grid>
            </Grid>
        </CenteredFullScreen>
    );
}


export default NoMatch;