import React, { Fragment } from 'react';
import { List, Typography, ListItem, Button, FormControlLabel, Switch } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import CustomerSelect, { MainCompanySelectItem } from '../../customers/component/customer-select';
import { useChangeCoefficientPolicy, useChangeCustomer, useCoefficientPolicy } from '../../customers/component/customer-provider';
import { useMainCompany } from '../../relations/component/user-relation-provider';
import { useIsViewingMainCustomer } from '../../customers/selectors';
import { useCompanyIsDistributor } from '../../companies/selector';
import { companyName } from '../../companies/component/elements/company-name';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    button: {
        width: '100%',
        fontSize: '0.65rem',
    },
    sidebar: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.getContrastText(theme.palette.background.default)
    }
}));

const SidebarPricePolicyChooser = (props) => {

    const coefficientPolicy = useCoefficientPolicy();
    const changeCoefficientPolicy = useChangeCoefficientPolicy();
    const isDistributorView = useCompanyIsDistributor();
    const { t } = useTranslation('translation', { keyPrefix: 'app.workbench.sidebar.price-chooser' });

    if (!isDistributorView) {
        return null;
    }

    return (
        <ListItem>
            <FormControlLabel
                control={<Switch checked={coefficientPolicy === 'customer'} onChange={(ev) => changeCoefficientPolicy(ev.target.checked)} name="change coefficient" />}
                label={(
                    <Typography variant='body2'>
                        {coefficientPolicy === 'customer' ?
                            t('customer-label') :
                            t('distributor-label')}
                    </Typography>
                )}
                labelPlacement="end"
            />
        </ListItem>
    )
}

const SidebarBackToMainCompanyButton = (props) => {

    const classes = useStyles();

    const change = useChangeCustomer();
    const company = useMainCompany();
    const isMainCompany = useIsViewingMainCustomer();
    const { t } = useTranslation('translation', { keyPrefix: 'app.workbench.sidebar.customer-chooser' });

    if (isMainCompany) {
        return null;
    }

    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb(ev);
    }


    return (
        <Fragment>
            <ListItem>
                <Button className={classes.button} size='small' variant='outlined'
                    onClick={handleClick(() => change(company.target))}>
                    {t('back-to-main', { companyName: companyName(company) })}
                </Button>
            </ListItem>
            <SidebarPricePolicyChooser />
        </Fragment>
    )
}

const MainCompanyName = () => {
    const company = useMainCompany()

    return (
        <MainCompanySelectItem company={company.target} />
    )
}

const SidebarCustomerChooser = ({ select }) => {
    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.workbench.sidebar.customer-chooser' });
    return (
        <List className={classes.sidebar} dense>
            <ListItem>
                <Typography color='textPrimary'>{t('display-for')}</Typography>
            </ListItem>
            <ListItem>
                {select ? <CustomerSelect /> : <MainCompanyName />}
            </ListItem>
            <SidebarBackToMainCompanyButton />
        </List>
    );
}

export default SidebarCustomerChooser;
