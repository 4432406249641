import React from 'react';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, List, ListSubheader, Typography } from "@material-ui/core";
import { useCustomer } from '../customer-provider';
import { companyName } from '../../../companies/component/elements/company-name';
import { CustomersOrderSidebarButton } from '../customer-orders';
import CreateCompanyByDistributorSidebarButton from '../../../companies/component/create-company-modal/distributor/create-company-by-distributor-sidebar-button';
import { CustomersListInternal } from '../customers-list';
import { useTranslation } from 'react-i18next';

export default function CustomerOverviewForDistributor({ onClick }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.overview.for-distributor' })
    const customer = useCustomer();

    return null

    return (
        <ExpansionPanel expanded>
            <ExpansionPanelSummary>
                <Typography>{t('title', { name: companyName(customer) })}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <List subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                {t('actions-label')}
                            </ListSubheader>
                        }>
                            <CreateCompanyByDistributorSidebarButton distributor={customer} />
                            <CustomersOrderSidebarButton buyer={customer} />
                        </List>
                    </Grid>
                    <Grid item xs={4}>
                        <CustomersListInternal subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                {t('customers-label')}
                            </ListSubheader>
                        }/>
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>

    )
}
