import React, { } from 'react';
import { ProceedPending } from './item-check-pending';
import { CannotProceed } from './item-cannot-proceed';
import { CanProceed } from './item-can-proceed';

export function ItemCheckCell({ decision }) {
    if (decision === null) return <ProceedPending />;
    else if (decision === false) return <CannotProceed />;
    else return <CanProceed />;
}
