import Resource from '../resource';
import _ from 'lodash';
import axios from 'axios';
import Actions from '../actions';

export default class OrderResource extends Resource {

    constructor(...opts) {
        super(...opts);
    }

    list(auth, customer, force = false) {

        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.orders`);

            const pending = state.allIds.status && state.allIds.status.includes('pending');

            // dont do anything if operation is pending.
            if (!force && pending) {
                return null;
            }

            dispatch(this._makePendingPayload('list', null));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'get',
                    baseURL: this.uri,
                    url: `c/${customer}/orders`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: {}
                });

                dispatch(this._makeSuccessPayload('list', res.data.data, null));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('list', error, null, flag));
                throw error;
            }
        }
    }

    listForBuyer(auth, force = false) {

        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.orders`);

            const pending = state.allIds.status && state.allIds.status.includes('pending');

            // dont do anything if operation is pending.
            if (!force && pending) {
                return null;
            }

            dispatch(this._makePendingPayload('list', null));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'get',
                    baseURL: this.uri,
                    url: `orders`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: {}
                });
                dispatch(this._makeSuccessPayload('list', res.data.data, null));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('list', error, null, flag));
                throw error;
            }
        }
    }

    get(auth, identifier, customer, force = false) {
        return async (dispatch, getState) => {

            const state = _.get(getState(), `${this.path}.orders`);

            const existing = state.byId[identifier];
            const pending = existing && existing.status.endsWith('pending');
            const hasErred = existing && existing.status.endsWith('error');

            // dont do anything if operation is pending or already exists.
            // The update can be forced if its existing
            if ((!force && (existing && !hasErred)) || pending) {
                if (existing && existing.payload) return existing.payload;
                return null;
            }

            dispatch(this._makePendingPayload('get', identifier));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'get',
                    baseURL: this.uri,
                    url: `c/${customer}/orders/${identifier}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: {},
                });
                dispatch(this._makeSuccessPayload('get', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('get', error, identifier, flag));
                throw error;
            }
        }
    }

    changePurchaseOrder(auth, identifier, customer, value) {
        return async (dispatch, getState) => {
            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `c/${customer}/orders/${identifier}/purchase-order`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: {},
                    data: {
                        purchase_order: value
                    }
                });
                dispatch(this._makeSuccessPayload('get', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('get', error, identifier, flag));
                throw error;
            }
        }
    }

    changeLabel(auth, identifier, customer, value) {
        return async (dispatch, getState) => {
            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `c/${customer}/orders/${identifier}/label`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: {},
                    data: {
                        label: value
                    }
                });
                dispatch(this._makeSuccessPayload('get', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('get', error, identifier, flag));
                throw error;
            }
        }
    }

    review(auth, identifier, customer) {

        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.orders`);

            const existing = !!state.byId[identifier];
            const pending = existing && state.byId[identifier].status.includes('pending');

            // dont do anything if operation is pending.
            if (pending) {
                return null;
            }

            dispatch(this._makePendingPayload('post', identifier));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `c/${customer}/orders/${identifier}/review`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: {},
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, identifier, flag));
                throw error;
            }
        }
    }

    accept(auth, identifier, customer) {

        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.orders`);

            const existing = !!state.byId[identifier];
            const pending = existing && state.byId[identifier].status.includes('pending');

            // dont do anything if operation is pending.
            if (pending) {
                return null;
            }

            dispatch(this._makePendingPayload('post', identifier));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `c/${customer}/orders/${identifier}/accept`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: {},
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, identifier, flag));
                throw error;
            }
        }
    }

    reopen(auth, identifier, customer) {

        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.orders`);

            const existing = !!state.byId[identifier];
            const pending = existing && state.byId[identifier].status.includes('pending');

            // dont do anything if operation is pending.
            if (pending) {
                return null;
            }

            dispatch(this._makePendingPayload('post', identifier));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `c/${customer}/orders/${identifier}/reopen`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: {},
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, identifier, flag));
                throw error;
            }
        }
    }

    paymentReceived(auth, identifier, customer) {

        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.orders`);

            const existing = !!state.byId[identifier];
            const pending = existing && state.byId[identifier].status.includes('pending');

            // dont do anything if operation is pending.
            if (pending) {
                return null;
            }

            dispatch(this._makePendingPayload('post', identifier));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `c/${customer}/orders/${identifier}/payment-received`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: {},
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, identifier, flag));
                throw error;
            }
        }
    }

    paymentNotReceived(auth, identifier, customer) {

        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.orders`);

            const existing = !!state.byId[identifier];
            const pending = existing && state.byId[identifier].status.includes('pending');

            // dont do anything if operation is pending.
            if (pending) {
                return null;
            }

            dispatch(this._makePendingPayload('post', identifier));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `c/${customer}/orders/${identifier}/payment-not-received`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: {},
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, identifier, flag));
                throw error;
            }
        }
    }

    finalize(auth, identifier, customer) {

        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.orders`);

            const existing = !!state.byId[identifier];
            const pending = existing && state.byId[identifier].status.includes('pending');

            // dont do anything if operation is pending.
            if (pending) {
                return null;
            }

            dispatch(this._makePendingPayload('post', identifier));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `c/${customer}/orders/${identifier}/finalize`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: {},
                });

                const order = res.data.data;
                dispatch(this._makeSuccessPayload('post', order, identifier));

                return order || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, identifier, flag));
                throw error;
            }
        }
    }

    void(auth, identifier, customer) {

        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.orders`);

            const existing = state.byId[identifier];
            const pending = existing && existing.status.includes('pending');

            // dont do anything if operation is pending.
            if (!existing || pending) return null;

            dispatch(this._makePendingPayload('post', identifier));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `c/${customer}/orders/${identifier}/void`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: {},
                });
                _.each(existing.payload.order_items, item => {
                    dispatch({
                        type: new Actions('orderitems')['remove'].success,
                        payload: null,
                        meta: { timestamp: Date.now(), target: item },
                    })
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, identifier, flag));
                throw error;
            }
        }
    }

    validate(auth, identifier, customer) {

        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.orders`);

            const existing = !!state.byId[identifier];
            const pending = existing && state.byId[identifier].status.includes('pending');

            // dont do anything if operation is pending.
            if (pending) {
                return null;
            }

            dispatch(this._makePendingPayload('post', identifier));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `c/${customer}/orders/${identifier}/validate`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: {},
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, identifier, flag));
                throw error;
            }
        }
    }

    revoke(auth, identifier, customer) {

        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.orders`);

            const existing = !!state.byId[identifier];
            const pending = existing && state.byId[identifier].status.includes('pending');

            // dont do anything if operation is pending.
            if (pending) {
                return null;
            }

            dispatch(this._makePendingPayload('post', identifier));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `c/${customer}/orders/${identifier}/revoke`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: {},
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, identifier, flag));
                throw error;
            }
        }
    }
}