import React from 'react';
import { Grid } from '@material-ui/core';
import SubscriptionsListInPanel from '../../subscriptions/components';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import SubscriptionPage from '../../subscriptions/components/subscription-page';
import PageHeader from '../../common/component/page-header';
import { useTranslation } from 'react-i18next';

const AccountSubscriptionIndex = () => {

    const { t } = useTranslation('translation', { keyPrefix: 'app.account.account-subscriptions' })


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <PageHeader title={t('page-title')} />
            </Grid>
            <Grid item xs={12}>
                <SubscriptionsListInPanel defaultExpanded />
            </Grid>
        </Grid>
    );

}

const AccountSubscriptions = () => {

    const { url } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${url}`} exact component={AccountSubscriptionIndex} />
            <Route path={`${url}/:subid`} component={SubscriptionPage} />
            <Route path={'*'} render={() => <Redirect to={url} />} />
        </Switch>
    )
}

export default AccountSubscriptions;