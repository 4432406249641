import Resource from '../resource';
import axios from 'axios';
import _ from 'lodash';

export default class UserResource extends Resource {

    sendVerificationEmail(identifier) {

        return async (dispatch) => {
            try {
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `/${this.resource}/${identifier}/verification-email`,
                });
                return res.data.data || null;
            } catch (error) {
                this._catchUnpredictedError(error, dispatch);
                throw error;
            }
        }
    }

    get(auth, identifier, customer, force = false) {
        return async (dispatch, getState) => {

            const state = _.get(getState(), `${this.path}.${this.resource}`);

            const existing = state.byId[identifier];
            const pending = existing && existing.status.endsWith('pending');
            const hasErred = existing && existing.status.endsWith('error');

            // dont do anything if operation is pending or already exists.
            // The update can be forced if its existing
            if ((!force && (existing && !hasErred)) || pending) {
                if (existing && existing.payload) return existing.payload;
                return null;
            }

            dispatch(this._makePendingPayload('get', identifier));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'get',
                    baseURL: this.uri,
                    url: `c/${customer}/${this.resource}/${identifier}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: {},
                });
                dispatch(this._makeSuccessPayload('get', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('get', error, identifier, flag));
                throw error;
            }
        }
    }

    renewPassword(auth, query) {

        return async (dispatch) => {
            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `${this.resource}/renew-password`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: query,
                });
                return res.data.data || null;
            } catch (error) {
                this._catchUnpredictedError(error, dispatch);
                throw error;
            }
        }
    }

}