import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import UserEmailField from '../../../users/component/element/user-email-field';
import { useTranslation } from 'react-i18next';

export function ownerInitialValues() {
    return {
        email: ''
    }
}

export default function OwnerFormForCreation(props) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.create-company-modal.create-company-owner' })
    return (
        <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
                <Typography>{t('title')}</Typography>
            </Grid>
            <Grid item xs={9}>
                <UserEmailField {...props} />
            </Grid>
        </Grid>
    )
}
