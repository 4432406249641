import React from 'react';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

export default function SubscribeButton(props) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.prices.subscribe-button' })

    return (
        <Button {...props}>
            {t('label')}
        </Button>
    )
}

