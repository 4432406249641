import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import CompanyIsDistributorField from '../forms/distributor-form/company-is-distributor-field';
import { useTranslation } from 'react-i18next';

export function distributorInitialValues() {
    return {
        distributor: false
    }
}

export default function DistributorFormForCreation(props) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.create-company-modal.create-company-distributor' });
    return (
        <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
                <Typography>{t('title')}</Typography>
            </Grid>
            <Grid item xs={9}>
                <CompanyIsDistributorField {...props} />
            </Grid>
        </Grid>
    )
}
