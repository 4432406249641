import React, { forwardRef, useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useImpersonateSdk } from '../../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../../lib/auth0-wrapper';

import InteractiveLoadingButton from '../../../../common/component/interactive-loading-button';
import { useNotif } from '../../../../common/component/notification';
import ActionOk from '../../../../common/component/notification/action-ok';
import { AWAITING_VALIDATION } from '../../../constants';
import { useCompanyIsVayanData } from '../../../../companies/selector';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    button: {
        marginRight: theme.spacing(1),
    },
}));

export const ReopenOrderView = forwardRef(({ ...props }, ref) => {

    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.actions.distributor.reopen' })

    return (
        <Button
            className={classes.button}
            size='small'
            {...props}
            ref={ref}
        >
            {t('button-label')}
        </Button>
    )
});

export function ReopenOrderController(props) {

    const { component, order, className, ...innerProps } = props;

    const dispatch = useDispatch();
    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const notif = useNotif();
    const isAdmin = useCompanyIsVayanData();
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.actions.distributor.reopen' })

    const [loading, setLoading] = useState(false);
    let tooltip = loading ? t('tooltip.loading') :
        t('tooltip.default');

    innerProps.disabled = loading;
    innerProps.onClick = useCallback((ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        let imp = sdk;
        if (isAdmin) imp = imp.impersonateByDistributor(order.payload.buyer);

        setLoading(true);
        dispatch(imp.orders.reopen(auth, order.target, order.payload.customer))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line
    }, [sdk, auth, order.target]);

    if (order.payload.status !== AWAITING_VALIDATION) {
        tooltip = t('tooltip.invalid-status');
        innerProps.disabled = true;
    }

    if (order.payload.buyer === order.payload.customer) {
        return null;
    }

    return (
        <InteractiveLoadingButton className={className} tooltip={tooltip} loading={loading} >
            {React.createElement(component, innerProps)}
        </InteractiveLoadingButton>
    )
}



export default function ReopenOrderButton({ ...props }) {
    return (
        <ReopenOrderController {...props} component={ReopenOrderView} />
    )
}
