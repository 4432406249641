import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useImpersonateSdk } from '../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../lib/auth0-wrapper';
import _ from 'lodash';
import { useNotif } from '../../../common/component/notification';
import ActionOk from '../../../common/component/notification/action-ok';
import { MultipleOrders } from './results/multiple-orders';
import { AlreadySubscribed } from './results/already-subscribed';
import { MustDeleteFirst } from './results/must-delete-first';
import { NoOrderFound } from './results/no-order-found';
import { ErrorOccured } from './results/error-occured';
import { makeGetOrderlinesFromTarget } from '../../selector';
import { useCustomer } from '../../../customers/component/customer-provider';

export function runRulesEngine({ product, reference, orderlines }) {

    const productRules = product.payload.rules;

    const ordersForSameProduct = _.filter(orderlines, o => o.payload.product === product.payload._id);
    const ordersForSameReference = _.filter(ordersForSameProduct, o => o.payload.reference === reference);

    // Return true if the subscription concerns the same reference.
    const isSamePlan = ordersForSameReference.length > 0;
    const hasOtherReferencesForProduct = ordersForSameProduct.length > 0;
    // Return false if the product wont allow because multiple orders already exists for the same product.
    const productAllowsMultipleOrders = ordersForSameProduct.length > 0 && productRules.multiple_orders_allowed; // Will reject if product is already subscribed for a target
    // Return false if the product wont allow because multiple orders already exists for the same reference.
    const productAllowsMultipleSameReferenceOrders = productAllowsMultipleOrders && ordersForSameReference.length > 0 && productRules.multiple_same_reference_allowed;

    if (isSamePlan) {
        // Make a new order for the same reference
        if (productAllowsMultipleSameReferenceOrders) {
            return {
                allow: true,
                element: (<MultipleOrders product={product} reference={reference} quantity={ordersForSameReference.length + 1} />)
            }
        } else { // Not possible, deny
            return {
                allow: false,
                element: (<AlreadySubscribed product={product} reference={reference} />)
            }
        }
    } else {
        // Another reference already exists
        if (hasOtherReferencesForProduct) {
            // Make a new order with another reference but same product.
            if (productAllowsMultipleOrders) {
                return {
                    allow: true,
                    element: (<MultipleOrders product={product} reference={reference} quantity={ordersForSameProduct.length + 1} />)
                }
            } else {
                return {
                    allow: false,
                    element: (<MustDeleteFirst product={product} reference={reference} />)
                }
            }
        } else {
            // No other references exists, we can proceed
            return {
                allow: true,
                element: (<NoOrderFound product={product} reference={reference} />)
            }
        }

    }
}

export default function RulesEngine({ device, product, reference, takeDecision }) {

    const dispatch = useDispatch();
    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const notif = useNotif();

    const customer = useCustomer();

    const itemsSelector = useMemo(() => makeGetOrderlinesFromTarget(sdk, device), [sdk, device]);
    const items = useSelector(itemsSelector);

    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);

    // Fetch all orderitems missing.
    useEffect(() => {
        Promise.all(device.payload.lines.map(i =>
            dispatch(sdk.orderlines.get(auth, i, customer.target))
        )).catch(e => {
            setError(e);
            notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
        }).finally(() => setLoading(false));
    }, [device]);

    // Once all items are fetched and available, run the rule engine.
    useEffect(() => {
        if (!isLoading) {
            if (error != null) {
                takeDecision({
                    allow: false,
                    element: (<ErrorOccured />),
                    target: device
                })
            } else {
                const decision = runRulesEngine({ product, reference, orderlines: items });
                decision.target = device;
                takeDecision(decision);
            }
        }
    }, [device, isLoading, error]);

    return null;
}
