import React, { Fragment, useEffect, useState } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { useSdk } from '../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../lib/auth0-wrapper';
import { useMainCompany } from '../../../relations/component/user-relation-provider';
import {
    Box, Button, CircularProgress, Table, TableBody, TableCell, TableRow,
    Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Grid, Divider, TableContainer, makeStyles, Paper
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(() => ({
    expansion: {
        '& .MuiExpansionPanelSummary-content': {
            display: 'block',
        }
    },
    subtitle: {
        fontSize: 12
    },
    cell: {
        width: '30%',
        padding: '0 12px',
        '& p': {
            fontSize: 12,
        },
    },
    headingCell: {
        '& p': {
            fontSize: 20,
        },
        width: '10%',
        textAlign: 'center',
        padding: 0,
        borderBottom: 0,
    },
    changedValueCell: {
        backgroundColor: '#70b74b29'
    },
    deleteColor: {
        color: '#ff3e3e'
    },
    updateColor: {
        color: 'orange'
    },
    createColor: {
        color: '#639d1e'
    }
}));

const ACTION_CREATE = 'create';
const ACTION_UPDATE = 'update';
const ACTION_NOTHING = 'nothing';
const ACTION_ARCHIVE = 'delete';
const ACTION_UNARCHIVE = 'unarchive';

const CSVImportCategory = ({ category, data, keys }) => {

    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.products.csv-import.validate' });
    const createCount = _.filter(data, ({ action }) => action === ACTION_CREATE).length;
    const updateCount = _.filter(data, ({ action }) => action === ACTION_UPDATE).length;
    const deleteCount = _.filter(data, ({ action }) => action === ACTION_ARCHIVE).length;
    const totalCount = data.length;

    return (
        <ExpansionPanel variant='outlined'>
            <ExpansionPanelSummary
                className={classes.expansion}
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography>{category}</Typography>
                <Typography className={classes.subtitle}>{t('summary', { createCount, updateCount, deleteCount, totalCount })}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container spacing={4}>
                    {data.map((data, index) => (
                        <Fragment key={index} >
                            <Divider />
                            <Grid item xs={12}>
                                <CSVImportObject left={data.existing} right={data.required} action={data.action} keys={keys} />
                            </Grid>
                        </Fragment>
                    ))}
                </Grid>

            </ExpansionPanelDetails>
        </ExpansionPanel>
    )
}

const formatValue = (value) => {
    return _.isString(value) ? value :
        _.isBoolean(value) ? value.toString() :
            _.isNumber(value) ? value.toString() :
                _.isArray(value) || _.isObjectLike(value) ? JSON.stringify(value, undefined, 2) :
                    null
}

const CSVImportObject = ({ left, right, action, keys, }) => {
    const classes = useStyles();
    const headingcellClasses = [classes.headingCell];
    if (action === ACTION_CREATE) {
        headingcellClasses.push(classes.createColor);
    } else if (action === ACTION_UPDATE) {
        headingcellClasses.push(classes.updateColor);
    } else if (action === ACTION_ARCHIVE) {
        headingcellClasses.push(classes.deleteColor);
    }

    return (
        <TableContainer component={Paper} variant='outlined' >
            <Table size='small'>
                <TableBody>
                    {keys.map((key, index) => {
                        const lvalue = _.get(left, key);
                        const rvalue = _.get(right, key);
                        const cellclasses = action === 'update' && !_.isEqual(lvalue, rvalue) ? classnames(classes.cell, classes.changedValueCell) : classes.cell;

                        return (
                            <TableRow key={key}>
                                {index === 0 && <TableCell className={classnames(headingcellClasses)} rowSpan={keys.length}>
                                    <Typography>{action.toUpperCase()}</Typography>
                                </TableCell>}
                                <TableCell className={cellclasses}><Typography align='right'>{key}</Typography></TableCell>
                                <TableCell className={cellclasses}><Typography>{formatValue(lvalue)}</Typography></TableCell>
                                <TableCell className={cellclasses}><Typography>{formatValue(rvalue)}</Typography></TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const useValidateOnTheServer = (data) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [results, setResults] = useState(null);

    const sdk = useSdk();
    const auth = useAuth0();
    const vd = useMainCompany();

    useEffect(() => {
        sdk.csvImport.validate(auth, data, vd.target)
            .then(results => {
                const tounarchive = _.chain(results)
                    .map()
                    .flatten()
                    .filter(({ action }) => action === ACTION_UNARCHIVE)
                    .map(({ existing }) => existing.name || existing.key || existing.reference)
                    .value();

                if (tounarchive.length > 0) {
                    throw new Error(`${tounarchive[0]} must be unarchived first. (and ${tounarchive.length - 1} others)`);
                }

                setResults(results);
                setError(null);
                setLoading(false);
            })
            .catch(e => {
                setResults(null);
                setError(e.message);
                setLoading(false);
            })
    }, []);

    return { loading, results, error }
}

function CSVImportValidateStep({ backStep, nextStep, context, addToContext, ...props }) {

    const { loading, error, results } = useValidateOnTheServer(context['CSVFileInput']);
    const { t } = useTranslation('translation', { keyPrefix: 'app.products.csv-import.validate' });
    const handleClick = () => {
        addToContext('ValidationResults', results);
        nextStep();
    }

    return (
        <Fragment>
            <Box minHeight='200px' padding={2} flexGrow={1} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                {loading && <CircularProgress />}
                {results && (
                    <Box flexGrow={1}>
                        <CSVImportCategory keys={['name', 'index', 'description.title', 'description.description', 'description.link.href', 'description.link.text',
                            'works_for', 'rules.multiple_orders_allowed', 'rules.multiple_same_reference_allowed']} data={results.products} category={t('category-products')} />
                        <CSVImportCategory keys={['reference', 'product', 'description.title', 'description.description', 'description.logo', 'sessions.can_revoke',
                            'parameters']} data={results.references} category={t('category-references')} />
                        <CSVImportCategory keys={['name', 'reference', 'currency', 'type', 'tiers_mode', 'unit_amount', 'recurring.unit',
                            'recurring.interval', 'tiers', 'associatedFreePrices']} data={results.prices} category={t('category-prices')} />
                        <CSVImportCategory keys={['key', 'name', 'policy_on_value']} data={results.parameterCategories} category={t('category-parameters')} />
                    </Box>
                )}
                {error && <Typography color='error'>{error}</Typography>}
            </Box>
            <Box display='flex' width={'100%'} flexDirection='row' alignItems='center' justifyContent='flex-end'>
                <Button disabled={loading} onClick={backStep}>{t('back-button')}</Button>
                <Button disabled={loading && error} onClick={handleClick}>{t('apply-button')}</Button>
            </Box>
        </Fragment>
    );
}

export default CSVImportValidateStep;