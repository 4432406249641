import Resource from '../resource';
import axios from 'axios';
import _ from 'lodash';

export default class CustomerResource extends Resource {

    countAwaitingValidationForVD(auth, vdId) {
        return async () => {
            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'get',
                    baseURL: this.uri,
                    url: `v/${vdId}/companies/awaiting-validation/count`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                return res.data.data;
            } catch (error) {
                throw error;
            }
        }
    }

    listAwaitingValidationForVD(auth, vdId) {
        return async () => {
            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'get',
                    baseURL: this.uri,
                    url: `v/${vdId}/companies/awaiting-validation`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                return res.data.data || null;
            } catch (error) {
                throw error;
            }
        }
    }

    list(auth) {
        return async (dispatch) => {
            dispatch(this._makePendingPayload('list', null));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'get',
                    baseURL: this.uri,
                    url: `companies`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                dispatch(this._makeSuccessPayload('list', res.data.data, null));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('list', error, null, flag));
                throw error;
            }
        }
    }

    get(auth, customer) {
        return async (dispatch) => {
            dispatch(this._makePendingPayload('get', customer));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'get',
                    baseURL: this.uri,
                    url: `companies/${customer}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                dispatch(this._makeSuccessPayload('get', res.data.data, customer));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('get', error, customer, flag));
                throw error;
            }
        }
    }

    changeCoefficient(auth, data, customer) {
        return async (dispatch) => {
            dispatch(this._makePendingPayload('post', customer));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `companies/${customer}/coefficient`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    data
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, customer));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, customer, flag));
                throw error;
            }
        }
    }

    changeInvoicePolicy(auth, data, customer) {
        return async (dispatch) => {
            dispatch(this._makePendingPayload('post', customer));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `companies/${customer}/invoice`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    data
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, customer));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, customer, flag));
                throw error;
            }
        }
    }

    changeTrustPolicy(auth, data, customer) {
        return async (dispatch) => {
            dispatch(this._makePendingPayload('post', customer));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `companies/${customer}/trust`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    data
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, customer));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, customer, flag));
                throw error;
            }
        }
    }

    changeDaysUntilDue(auth, data, customer) {
        return async (dispatch) => {
            dispatch(this._makePendingPayload('post', customer));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `companies/${customer}/due`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    data
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, customer));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, customer, flag));
                throw error;
            }
        }
    }

    changeDistributor(auth, data, customer) {
        return async (dispatch) => {
            dispatch(this._makePendingPayload('post', customer));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `companies/${customer}/distributor`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    data
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, customer));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, customer, flag));
                throw error;
            }
        }
    }

    changeOnboardingStatus(auth, data, customer) {
        return async (dispatch) => {
            dispatch(this._makePendingPayload('post', customer));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `companies/${customer}/onboarding-status`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    data
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, customer));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, customer, flag));
                throw error;
            }
        }
    }

    putPrice(auth, price, customer) {
        return async (dispatch) => {
            dispatch(this._makePendingPayload('post', customer));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'put',
                    baseURL: this.uri,
                    url: `companies/${customer}/prices/${price}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, customer));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, customer, flag));
                throw error;
            }
        }
    }

    removePrice(auth, price, customer) {
        return async (dispatch) => {
            dispatch(this._makePendingPayload('post', customer));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'delete',
                    baseURL: this.uri,
                    url: `companies/${customer}/prices/${price}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, customer));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, customer, flag));
                throw error;
            }
        }
    }

    putPrices(auth, prices, customer) {
        return async (dispatch) => {
            dispatch(this._makePendingPayload('post', customer));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'put',
                    baseURL: this.uri,
                    url: `companies/${customer}/prices/multiple`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    data: {
                        prices
                    },
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, customer));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, customer, flag));
                throw error;
            }
        }
    }

    removePrices(auth, prices, customer) {
        return async (dispatch) => {
            dispatch(this._makePendingPayload('post', customer));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'delete',
                    baseURL: this.uri,
                    url: `companies/${customer}/prices/multiple`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    data: {
                        prices
                    },
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, customer));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, customer, flag));
                throw error;
            }
        }
    }

    remove(auth, customer) {
        return async (dispatch) => {
            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'delete',
                    baseURL: this.uri,
                    url: `companies/${customer}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                dispatch(this._makeSuccessPayload('remove', res.data.data, customer));
                return null;
            } catch (error) {
                throw error;
            }
        }
    }

}