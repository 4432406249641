import { GRADE_DISTRIBUTOR, GRADE_VAYAN_DATA, GRADE_CUSTOMER } from './constants';
import { useSdk } from '../../lib/licensing-sdk';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMainCompany } from '../relations/component/user-relation-provider';
import { createSelector } from 'reselect';
import _ from 'lodash';

export const useGetCompanyById = (id) => {
    const sdk = useSdk();
    const companySelector = useMemo(() => sdk.companies.selectors.makeGet(), []); // eslint-disable-line react-hooks/exhaustive-deps
    return useSelector(state => companySelector(state, id));
}

export const useCompanyIsClient = () => {
    const company = useMainCompany();
    return company.payload.grade === GRADE_CUSTOMER;
}

export const useCompanyIsDistributor = () => {
    const company = useMainCompany();
    return company && company.payload.grade === GRADE_DISTRIBUTOR;
}

export const useCompanyIsVayanData = () => {
    const company = useMainCompany();
    return company.payload.grade === GRADE_VAYAN_DATA;
}

export const usePaymentMethodsFor = () => {
    // eslint-disable-next-line
    const sdk = useSdk();
    const selector = useMemo(() => createSelector(
        sdk.paymentmethods.selectors.getDb,
        (paymentMethods) => {
            const list = _.chain(paymentMethods)
                .map()
                .value();
            return list;
        }
    ), []);
    return useSelector(selector);

}
