import React, { Fragment, useEffect } from 'react';
import {
    Grid, Typography, makeStyles, Table, TableRow, TableCell, Box, TableBody, Card, CardHeader, CardContent
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useOrderLineById } from '../../order-lines/selector';
import { useOrderLineReferenceName } from '../../order-lines/component/element/order-line-reference-name';
import OrderLineStatus from '../../order-lines/component/element/order-line-status';
import { orderLinePeriodStartString } from '../../order-lines/component/element/order-line-period-start';
import { orderLinePeriodEndString } from '../../order-lines/component/element/order-line-period-end';
import { useImpersonateSdk } from '../../../lib/licensing-sdk';
import { useAuth0 } from '../../../lib/auth0-wrapper';
import { useCustomer } from '../../customers/component/customer-provider';
import { useDispatch } from 'react-redux';
import { InvoiceList, useInvoicesPagesController } from '../../invoices/component/invoice-list';
import ActionOk from '../../common/component/notification/action-ok';
import { useNotif } from '../../common/component/notification';
import CancelSubscriptionButton from '../../order-lines/component/actions/cancel-subscription';
import UncancelSubscriptionButton from '../../order-lines/component/actions/uncancel-subscription';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    headingText: {
        marginRight: theme.spacing(1)
    }
}));

const SubscriptionPageInternal = ({ orderline }) => {

    const classes = useStyles();
    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const customer = useCustomer();
    const dispatch = useDispatch();
    const notif = useNotif();
    const { t } = useTranslation('translation', { keyPrefix: 'app.subscriptions.page' });

    const referenceName = useOrderLineReferenceName(orderline);

    const { invoices, loadMore, disabled, loading } = useInvoicesPagesController((offset, limit) => async () => {
        return await dispatch(sdk.invoices.listForOrderline(auth, orderline.target, customer.target, { offset, limit }))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> })
            });
    });

    useEffect(() => {
        loadMore()
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> })
            })
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader title={<Fragment>
                        <Typography variant='h5'>
                            {t('card-header', { referenceName })}
                        </Typography>
                    </Fragment>} />
                    <CardContent>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography>
                                            {t('headers.status')}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <OrderLineStatus line={orderline} />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>
                                            {t('headers.period')}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Box display='flex' flexDirection='row' >
                                            <Typography className={classes.headingText}>
                                                {t('headers.start', { date: orderLinePeriodStartString(orderline) })}
                                            </Typography>
                                        </Box>
                                        <Box display='flex' flexDirection='row' >
                                            <Typography className={classes.headingText}>
                                                {t('headers.end', { date: orderLinePeriodEndString(orderline) })}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <Box display='flex' flexDirection='row' >
                                            <CancelSubscriptionButton line={orderline} variant='outlined' />
                                            <UncancelSubscriptionButton line={orderline} variant='outlined' />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardHeader title={'Invoices'} />
                    <CardContent>
                        <InvoiceList invoices={invoices} loadMore={loadMore} loading={loading} disabled={disabled} />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

const SubscriptionPage = () => {

    let { subid } = useParams();
    const { t } = useTranslation('translation', { keyPrefix: 'app.subscriptions.page' });
    const orderline = useOrderLineById(subid);
    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const customer = useCustomer();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(sdk.orderlines.get(auth, subid, customer.target));
    }, [subid]);

    if (orderline && orderline.payload) {
        return (
            <SubscriptionPageInternal orderline={orderline} />
        )
    } else {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography>
                        {t('no-subscriptions', { sub: subid })}
                    </Typography>
                </Grid>
            </Grid>
        )
    }
}

export default SubscriptionPage;