import React from 'react';
import { MTableEditRow } from 'material-table';

export default function EditRow(props) {

    let { data, mode, ...innerProps } = props;

    if (mode === 'update') {
        // Do nothing
    } else {
        //if(mode === 'add') 
        // do nothing on the data.
    }


    return <MTableEditRow {...innerProps} mode={mode} data={data} />

}