import React from 'react';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { Typography } from '@material-ui/core';
import { ResourceGetter } from '../../../../lib/licensing-sdk/getter';

function LoginsCount({ user, ...props }) {
    return <Typography {...props}>{user.payload.logins_count || 0}</Typography>
}

function UserLoginsCount({ user, relation, ...props }) {
    return (
        <ResourceGetter resource='users' id={user} query={{ relation }} render={({ user }) =>
            <WaitingLoaded data={user} render={() => <LoginsCount user={user} {...props} />} />
        } />
    )
}

export default UserLoginsCount;
