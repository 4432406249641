import React from 'react';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { ResourceGetter } from '../../../../lib/licensing-sdk/getter';

export function getHostId(device) {
    return device?.payload?.winid || null;
}

function DeviceWinid({ device, defaultSdk, customer }) {
    return (
        <ResourceGetter resource='devices' id={device} defaultSdk={defaultSdk} customer={customer} render={({ device }) =>
            <WaitingLoaded data={device} render={() => device.payload.winid} />
        } />
    )
}

export default DeviceWinid;
