import { combineReducers } from 'redux';
import Resource, { SERVER_NETWORK_ERROR, INTERNAL_SERVER_ERROR } from './resource';
import Actions from './actions';
import Reducer from './reducers';
import HelpersBuilder from './helpers.js';
import UserReducer from './specific/user-reducer';
import PaymentMethodsReducer from './specific/payment-method-reducer';
import CompanyResource from './specific/company-resource';
import CompanyReducer from './specific/company-reducer';
import UserResource from './specific/user-resource';
import PaymentMethodResource from './specific/payment-method-resource';
import InvoiceReducer from './specific/invoice-reducer';
import InvoiceResource from './specific/invoice-resource';
import OrderResource from './specific/order-resource';
import DeviceResource from './specific/device-resource';
import SessionResource from './specific/session-resource';
import RelationResource from './specific/relation-resource';
import CustomerResource from './specific/customer-resource';
import CustomerReducer from './specific/customer-reducer';
import PriceResource from './specific/price-resource';
import PriceReducer from './specific/price-reducer';
import ReferenceResource from './specific/reference-resource';
import ProductResource from './specific/product-resource';
import OrderlinesResource from './specific/orderlines-resource';
import ConsentsResource from './specific/consents-resource';
import { join } from 'path';
import CsvImport from './csv-import';
import axios from 'axios';

export * from './context';

export { SERVER_NETWORK_ERROR, INTERNAL_SERVER_ERROR };
export const ACTIONS = {
    'orders': new Actions('orders'),
    'parameters': new Actions('parameters'),
    'devices': new Actions('devices'),
    'sessions': new Actions('sessions'),
    'companies': new Actions('companies'),
    'relations': new Actions('relations'),
    'users': new Actions('users'),
    'paymentmethods': new Actions('paymentmethods'),
    'invoices': new Actions('invoices'),

    'products': new Actions('products'),
    'prices': new Actions('prices'),
    'references': new Actions('references'),

    'customers': new Actions('customers'),
}

export const reducers = combineReducers({
    orders: Reducer('orders'),
    parameters: Reducer('parameters'),
    devices: Reducer('devices'),
    sessions: Reducer('sessions'),
    companies: CompanyReducer('companies'),
    relations: Reducer('relations'),
    users: UserReducer(),
    paymentmethods: PaymentMethodsReducer(),
    invoices: InvoiceReducer(),

    products: Reducer('products'),
    references: Reducer('references'),
    prices: PriceReducer(),
    orderlines: Reducer('orderlines'),

    customers: CustomerReducer('customers'),

});

export default class SDK {

    constructor(basepath, pathInState) {
        const uri = process.env.REACT_APP_API_HOST + basepath;

        this.parameters = new Resource('parameters', uri, false, pathInState);
        this.devices = new DeviceResource('devices', uri, false, pathInState);
        this.sessions = new SessionResource('sessions', uri, false, pathInState);
        this.companies = new CompanyResource('companies', uri, false, pathInState);
        this.relations = new RelationResource('relations', uri, false, pathInState);
        this.users = new UserResource('users', uri, false, pathInState);
        this.paymentmethods = new PaymentMethodResource('paymentmethods', uri, false, pathInState);
        this.invoices = new InvoiceResource('invoices', uri, false, pathInState);

        this.products = new ProductResource('products', uri, true, pathInState);
        this.references = new ReferenceResource('references', uri, true, pathInState);
        this.prices = new PriceResource('prices', uri, true, pathInState);
        this.orders = new OrderResource('orders', uri, false, pathInState);
        this.orderlines = new OrderlinesResource(this.devices, this.orders, 'orderlines', uri, true, pathInState);

        this.consents = new ConsentsResource('consents', uri, true, pathInState);

        this.customers = new CustomerResource('customers', uri, true, pathInState);

        this.csvImport = new CsvImport();
        this.helpers = HelpersBuilder(this);

        this._ = {
            basepath,
            pathInState,
        }
    }

    impersonateByVayanData(id) {
        return new SDK(join(this._.basepath, `v/${id}`), this._.pathInState);
    }

    impersonateByDistributor(id) {
        return new SDK(join(this._.basepath, `d/${id}`), this._.pathInState);
    }

    actions() {
        return {
            SERVER_NETWORK_ERROR,
            INTERNAL_SERVER_ERROR,
        }
    }

    async getHealth() {
        const res = await axios({
            method: 'get',
            baseURL: process.env.REACT_APP_API_HOST,
            url: `/health`,
        });

        return res.data;
    }

}
