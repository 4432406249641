import React from 'react';
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { useProductName } from '../element/product-title';
import _ from 'lodash';
import ProductConfigurationReferencesList from './product-configuration-reference-list';
import { ShareProductSwitch } from './share-product-switch';

export default function ProductConfigurationCard({ product, ...props }) {

    const productName = useProductName(product);

    return (
        <Card>
            <CardHeader title={productName} action={<ShareProductSwitch product={product} />} />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ProductConfigurationReferencesList product={product} {...props} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
