import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function Number({ invoice, ...props }) {

    const { number, hosted_invoice_url } = invoice.payload;
    const { t } = useTranslation('translation', { keyPrefix: 'app.invoices.elements.invoice-number' });
    const handleClick = hosted_invoice_url ? (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        window.open(hosted_invoice_url, '_blank');
    }
        : undefined;

    return (
        <Button {...props} variant="text" disabled={!hosted_invoice_url} onClick={handleClick} >
            {number || t('draft')}
        </Button>
    )
}

export default function InvoiceNumber({ invoice, ...props }) {

    return (
        <Number invoice={invoice} {...props} />
    )
}

export function InvoiceNumberLabel({ }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.invoices.elements.invoice-number' });
    return (
        <Typography>{t('label')}</Typography>
    )
}