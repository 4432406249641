import React from 'react';
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';

import { replace } from 'connected-react-router';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { CircularProgress, Typography, Button, Grid } from '@material-ui/core';
import { Translation } from 'react-i18next';

const styles = theme => ({
    root: {
        height: '100%',
        display: 'flex',
    },
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '100%',
        margin: 'auto',
    },
    spaced: {
        marginTop: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(2),
    },
});


class ErrorBoundary extends React.Component {

    static propTypes = {
        children: PropTypes.any.isRequired,
    }

    state = {
        error: null,
        event: null,
        loading: false,
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        console.log(error);
        return { error: error };
    }

    componentDidCatch(error, errorInfo) {
        const { user, profile } = this.props;

        this.setState({ loading: true });

        Sentry.withScope(scope => {
            scope.setExtras(errorInfo);
            scope.setUser({
                email: profile ? profile.email : undefined,
                id: user ? user.provider_id : undefined,
            });
            const event = Sentry.captureException(error);
            this.setState({ event });
            this.resetLoading();
        });
    }

    componentDidMount() {

    }

    componentWillUnmount() {
        if (this.timemout) clearTimeout(this.timeout);
    }

    resetLoading = () => {
        this.timeout = setTimeout(() => {
            this.setState({ loading: false });
            this.timeout = null;
        }, 1000);
    }

    renderBoundary = () => {
        const { classes, replace } = this.props;
        const { event, loading } = this.state;

        return (
            <Translation keyPrefix={"app.errors.boundary"}>
                {
                    (t) => (

                        <div className={classes.centered}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant='h5' component='h3'>{t('title')}</Typography>
                                    <Typography>{t('description')}</Typography>
                                </Grid>
                                <Grid className={classes.spaced} item xs={12}>
                                    {loading ?
                                        <CircularProgress />
                                        :
                                        <div>
                                            <Button className={classes.button} variant='outlined' onClick={(ev) => {
                                                if (ev) {
                                                    ev.preventDefault();
                                                    ev.stopPropagation();
                                                }
                                                this.setState({ error: null, event: null });
                                                replace('/');
                                            }}>{t('back-button')}</Button>
                                            {event && <Button className={classes.button} variant='contained' onClick={(ev) => {
                                                if (ev) {
                                                    ev.preventDefault();
                                                    ev.stopPropagation();
                                                }
                                                Sentry.showReportDialog({ eventId: event });
                                            }}>{t('report')}</Button>}
                                        </div>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    )
                }
            </Translation>
        );
    }

    render() {
        const { classes, children } = this.props;
        const { error } = this.state;

        return (
            <div className={classes.root}>
                {error ? this.renderBoundary() : children}
            </div>
        );
    }

}

const mapStateToProps = state => ({

});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        replace,
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(ErrorBoundary));