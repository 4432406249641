import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function requiresPaymentMethod(payment_intent) {
    return payment_intent.last_payment_error ? payment_intent.last_payment_error.message : '';
}

function PaymentIntentStatus({ invoice, ...props }) {

    const { payment_intent } = invoice.payload;
    const { t } = useTranslation('translation', { keyPrefix: 'app.invoices.elements.payment-status' })

    if (!payment_intent) return null;

    let status = payment_intent && payment_intent.status;

    status = status === 'requires_payment_method' ? requiresPaymentMethod(payment_intent)
        : status === 'requires_confirmation' ? t('requires-confirmation')
            : status === 'requires_action' ? t('requires-action')
                : status === 'processing' ? t('processing')
                    : status;

    return (
        <Typography {...props} color={'primary'}>{status}</Typography>
    )
}

export default function InvoicePaymentIntentStatus({ invoice, ...props }) {
    return (
        <PaymentIntentStatus invoice={invoice} {...props} />
    )
}

export function PaymentIntentLabel() {
    const { t } = useTranslation('translation', { keyPrefix: 'app.invoices.elements.payment-status' })
    return (
        <Typography>{t('label')}</Typography>
    )
}