import React, { useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import AddressFormForCreation, { addressInitialValues } from '../create-company-address';
import { currencyInitialValues } from '../create-company-currency';
import NameFormForCreation, { nameInitialValues } from '../create-company-name';
import { useNotif } from '../../../../common/component/notification';
import { useSdk } from '../../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../../lib/auth0-wrapper';
import { useDispatch } from 'react-redux';
import ActionOk from '../../../../common/component/notification/action-ok';
import TaxIdFormForCreation, { taxIdInitialValues } from '../create-company-tax-id';
import BillingEmailFormForCreation, { billingEmailInitialValues } from '../create-company-billing-email';
import * as Yup from 'yup';
import { billingEmailSchema } from '../../forms/billing-form/fields/company-billing-email-field';
import { taxIdSchema } from '../../forms/billing-form/fields/company-tax-id-field';
import { useLoadPrimaryRelationsCallback } from '../../../../relations/actions';
import { useTranslation } from 'react-i18next';


function useHandleSubmit(onClose) {
    const notif = useNotif();
    const sdk = useSdk();
    const auth = useAuth0();
    const dispatch = useDispatch();
    const loadRelations = useLoadPrimaryRelationsCallback();

    return useCallback((values, { setSubmitting }) => {
        const payload = {
            name: values.name,
            address: values.address,
            tax_id_type: values.tax_id_type,
            tax_id_value: values.tax_id_value,
            billing_email: values.billing_email,
            currency: values.currency,
        };

        dispatch(sdk.companies.put(auth, payload))
            .then(async ({ relation }) => {
                await loadRelations();
                return relation
            })
            .then(onClose)
            .catch(err => {
                const message = err.response?.data?.message || err.message;
                notif.error(message, { action: (key) => <ActionOk notif={notif} handle={key} /> });
                setSubmitting(false);
            });
    }, []);
}

export function CreateCompanyByCustomerModal({ open, onSuccess, onClose, ...props }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.create-company-modal.customer.create-company-by-customer' });
    const initialValues = _.merge({},
        addressInitialValues(),
        currencyInitialValues(),
        taxIdInitialValues(),
        nameInitialValues(),
        billingEmailInitialValues(),
    );

    const schema = Yup.object().shape({
        ...taxIdSchema,
        ...billingEmailSchema,
    }).unknown(true);

    const handleSubmit = useHandleSubmit(onSuccess);

    if (open) {
        return (
            <Dialog scroll='paper' fullWidth maxWidth='lg' open={open} onClose={onClose}>
                <DialogTitle>
                    {t('title')}
                </DialogTitle>
                <Formik
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={handleSubmit}
                >
                    {(props) => (
                        <Form>
                            <DialogContent>
                                <Grid container spacing={4}>
                                    <Grid item xs={6} container spacing={1} >
                                        <Grid item xs={12}>
                                            <NameFormForCreation  {...props} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <BillingEmailFormForCreation {...props} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TaxIdFormForCreation {...props} />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <AddressFormForCreation namespace='address' {...props} />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button disabled={props.isSubmitting} onClick={onClose}>
                                    {t('cancel')}
                                </Button>
                                <Button disabled={props.isSubmitting} onClick={props.handleReset}>
                                    {t('reset')}
                                </Button>
                                <Button disabled={props.isSubmitting} onClick={props.handleSubmit} color='secondary' >
                                    {t('proceed')}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik >
            </Dialog >
        );
    } else {
        return null;
    }

}