import _ from 'lodash';
import { combineReducers } from 'redux';
import Actions from '../actions';

const InvoiceReducer = () => {

    const actions = new Actions('invoices');

    const list = actions['list'];
    const get = actions['get'];
    const post = actions['post'];
    const clear = actions['clear'];

    return combineReducers({
        insert: (state = { error: null, status: 'idle', lastUpdate: null }, { type, payload, meta }) => {
            state = _.cloneDeep(state);
            return state;
        },
        byId: (state = {}, { type, payload, meta }) => {

            state = _.cloneDeep(state);

            switch (type) {
                case clear:
                    state = {};
                    break;
                case list.success:
                    const newState = _.keyBy(payload, 'id');
                    state = _.mapValues(newState, payload => ({ error: null, target: payload.id, payload, status: 'list-success', lastUpdate: meta.timestamp }));
                    break;
                case get.pending:
                case post.pending:
                    state = _.merge(state, { [meta.target]: { target: meta.target, status: 'get-pending', lastUpdate: meta.timestamp } });
                    break;
                case get.success:
                case post.success:
                    state = _.merge(state, { [meta.target]: { error: null, status: 'get-success', lastUpdate: meta.timestamp } });
                    state[meta.target].payload = _.clone(payload);
                    break;
                case get.error:
                case post.error:
                    state = _.merge(state, { [meta.target]: { error: payload.error, status: 'get-error', lastUpdate: meta.timestamp } });
                    break;
                default:
                    break;
            }

            return state;
        },
        allIds: (state = { status: 'idle', ids: [], error: null, count: 0 }, { type, payload, meta }) => {

            state = _.cloneDeep(state);

            switch (type) {
                case clear:
                    state = { status: 'idle', ids: [], error: null, count: 0 };
                    break;
                case list.pending:
                    state = _.merge(state, { error: null, status: 'list-pending', lastUpdate: meta.timestamp });
                    break;
                case list.success:
                    state = _.merge(state, { count: payload.length, error: null, status: 'list-success', lastUpdate: meta.timestamp });
                    state.ids = _.union(state.ids, _.map(payload, 'id'));
                    break;
                case list.error:
                    state = _.merge(state, { error: payload.error, status: 'list-error', lastUpdate: meta.timestamp });
                    break;
                default:
                    break;
            }

            return state;
        }
    });
}

export default InvoiceReducer;