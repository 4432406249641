import React, { useCallback, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    field: {
        width: '100%'
    }
}));


export default function DeviceQuantityEditable(props) {

    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.devices.elements' });

    const onChange = useCallback((ev) => {
        const value = parseInt(ev.target.value, 10);
        if(isNaN(value)) {
            props.onChange('');
        } else if (value > 100) {
            props.onChange(100);
        } else if (value < 1) {
            props.onChange(1);
        } else {
            props.onChange(value);
        }
    }, []);

    useEffect(() => {
        if (!props.value) {
            props.onChange(1);
        }
    }, []);

    return (
        <TextField
            className={classes.field}
            type='number'
            inputProps={{
                min: 1,
                max: 100,
            }}
            label={t('quantity-editable')}
            name="quantity"
            value={props.value}
            onBlur={onChange}
            onChange={onChange}
        />
    );
}
