
import React, { useMemo, createContext, useContext, useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useDispatch } from 'react-redux';
import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useSdk } from '../../../lib/licensing-sdk';
import { useAuth0 } from '../../../lib/auth0-wrapper';
import Loader from '../../common/component/loader';
import { useLoadPrimaryRelations } from '../actions';
import CenteredFullScreen from '../../common/component/centered-full-screen';
import { Box, Button, Typography } from '@material-ui/core';
import { useUserRelationsList } from '../selectors';
import LogoutButton from '../../users/component/authentication/logout-button';
import CreateCompanyByCustomerButton from '../../companies/component/create-company-modal/customer/create-company-by-customer-button';
import { useTranslation } from 'react-i18next';

const userRelationContext = createContext({
    user: undefined,
    relation: undefined,
    company: undefined,
    change: () => { console.error('early call to change().'); }
});

export const useCurrentRelation = () => useContext(userRelationContext).relation;
export const useMainCompany = () => useContext(userRelationContext).company;
export const useChangeRelation = () => useContext(userRelationContext).change;

const InternalUserRelationProvider = ({ children, user, changeLocation }) => {

    const { relation: relationId } = useParams();

    const sdk = useSdk();
    const relationSelector = useMemo(() => sdk.relations.selectors.makeGet(), []);
    const companySelector = useMemo(() => sdk.companies.selectors.makeGet(), []);

    const relation = useSelector(state => relationSelector(state, relationId));
    const company = useSelector(state => companySelector(state, relation?.payload?.target));
    const { t } = useTranslation('translation', { keyPrefix: 'app.relations.user-relation-provider' });

    const value = {
        user,
        relation,
        company,
        change: (id) => {
            changeLocation(id);
        }
    }

    if (!relation || !company) {
        return (
            <CenteredFullScreen>
                <Box display={'flex'} flexDirection={'column'}>
                    <Typography>{t('company-not-found')}</Typography>
                    <Button contained onClick={() => window.location.replace('/')}>{t('go-home')}</Button>
                </Box>
            </CenteredFullScreen>
        );
    }

    return (
        <userRelationContext.Provider value={value}>
            {children}
        </userRelationContext.Provider>
    );
}

const UserRelationChooser = ({ user, changeLocation }) => {
    const changeRelation = changeLocation;
    const relations = useUserRelationsList(user.sub);
    const hasRelations = relations.length > 0;
    const { t } = useTranslation('translation', { keyPrefix: 'app.relations.user-relation-provider' });

    useEffect(() => {
        if (hasRelations) {
            changeRelation(relations[0].target);
        }
    }, []);

    if (!hasRelations) {
        return (
            <CenteredFullScreen>
                <Box p={4} width={{ xs: '100%', md: '640px' }} display='flex' flexDirection='column' alignItems='center'>
                    <Typography align='justify'>
                        {t('no-relations-1')}
                    </Typography>
                    <br />
                    <Typography align='justify'>
                        {t('no-relations-2')}
                    </Typography>
                    <Box display='flex' flexDirection='row' justifyContent='space-evenly' width='100%' mt={4}>
                        <LogoutButton variant='outlined' />
                        <CreateCompanyByCustomerButton variant='outlined' onSuccess={(relation) => changeRelation(relation)} />
                    </Box>
                </Box>
            </CenteredFullScreen>
        )
    } else {
        return null
    }
}

const UserRelationProvider = ({ children, ...props }) => {

    let { url } = useRouteMatch();
    url = url.endsWith('/') ? url.slice(0, -1) : url;
    const dispatch = useDispatch();
    const { t } = useTranslation('translation', { keyPrefix: 'app.relations.user-relation-provider' });

    const auth = useAuth0();
    const user = auth.user;

    const changeLocation = (to = '') => {
        dispatch(push(`${url}/r/${to}`));
    };

    const [loading, setLoading] = useState(true);

    useLoadPrimaryRelations(undefined, undefined, () => setLoading(false));

    if (loading) {
        return (
            <CenteredFullScreen>
                <Loader message={t('loading')} />
            </CenteredFullScreen>
        );
    } else {
        return (
            <Switch >
                <Route path={`${url}/r/:relation`} render={() =>
                    <InternalUserRelationProvider changeLocation={changeLocation} user={user} children={children} />
                } />
                <Route path={`${url}/`} render={() =>
                    <UserRelationChooser changeLocation={changeLocation} user={user} />
                } />
            </Switch>
        );
    }
}

export default UserRelationProvider;
