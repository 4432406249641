import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import {
    ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Chip,
} from '@material-ui/core';
import { WatchLater } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { useOpenOrdersForBuyerSelector, useOrdersForBuyerSelector } from '../../orders/selectors';
import { useImpersonateSdk } from '../../../lib/licensing-sdk';
import { useAuth0 } from '../../../lib/auth0-wrapper';
import { useNotif } from '../../common/component/notification';
import ActionOk from '../../common/component/notification/action-ok';
import { useChangeCustomer, useChangeLocation, useCustomer } from './customer-provider';
import { OrderHistoryTable } from '../../orders/component/details/order-history/order-history-table';
import { useTranslation } from 'react-i18next';

export const useLoadCustomersOrdersCallback = (buyer) => {
    const auth = useAuth0();
    const sdk = useImpersonateSdk();
    const dispatch = useDispatch();
    const notif = useNotif();

    const [loading, setLoading] = useState(true);

    const callback = useCallback((ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        setLoading(true);
        const imp = sdk.impersonateByDistributor(buyer.target);
        dispatch(imp.orders.listForBuyer(auth, true))
            .finally(() => setTimeout(() => setLoading(false), 1000))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            });
    }, [buyer.target]);

    return [loading, callback];
}

export const useLoadCustomersOrdersRoutine = (buyer) => {

    const [loading, callback] = useLoadCustomersOrdersCallback(buyer);

    useEffect(() => {
        callback();
        const interval = setInterval(callback, 60 * 1000);
        return () => {
            clearInterval(interval);
        }
    }, [buyer.target]);

    return [loading, callback];

}

export const CustomersOrdersCountBadge = ({ buyer, ...props }) => {

    const orders = useOpenOrdersForBuyerSelector(buyer.target);
    const count = orders.length;

    if (count > 0) {
        return (
            <Chip size='small' max={99} label={count} color='secondary' />
        )
    } else {
        return null;
    }

};

export const CustomersOrderSidebarButton = ({ buyer, ...props }) => {

    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.customer-orders' })
    const changeLocation = useChangeLocation();
    const onClick = (ev) => {
        if (ev) {
            ev.stopPropagation();
            ev.preventDefault();
        }

        changeLocation('customers/orders');
    }

    return (
        <ListItem {...props} button onClick={onClick}>
            <ListItemIcon>
                <WatchLater />
            </ListItemIcon>
            <ListItemText primary={t('label')} />
            <ListItemSecondaryAction>
                <CustomersOrdersCountBadge buyer={buyer} />
            </ListItemSecondaryAction>
        </ListItem >
    );
}

export default function CustomersOrders({ }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.customer-orders' })
    const buyer = useCustomer();
    const orders = useOrdersForBuyerSelector(buyer.target);
    const [loading, onRefresh] = useLoadCustomersOrdersCallback(buyer);

    const changeLocation = useChangeCustomer();

    const pushCallback = useCallback((order) => {
        changeLocation(order.payload.customer, `orders/${order.target}`);
    }, []);

    return (

        <OrderHistoryTable title={t('label')}
            orders={orders} onClick={pushCallback} distributor
            onRefresh={onRefresh} />

    )
}