import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '../../../lib/auth0-wrapper';
import { useImpersonateSdk } from '../../../lib/licensing-sdk';
import UserList from './user-list';
import { useNotif } from '../../common/component/notification';
import ActionOk from '../../common/component/notification/action-ok';
import { useCustomer } from '../../customers/component/customer-provider';

function UsersLayout(props) {

    const dispatch = useDispatch();
    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const notif = useNotif();

    const customer = useCustomer();

    useEffect(() => {
        dispatch(sdk.helpers.loadRelations(auth, customer.target))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            });
    }, [customer.target]);

    let { url } = useRouteMatch();
    url = url.endsWith('/') ? url.slice(0, -1) : url;

    return (
        <Switch>
            <Route path={`${url}`} exact component={UserList} />
        </Switch>
    )

}

export default UsersLayout;
