import React, { useContext } from "react";
import { useViewingAsDistributor, useViewingAsVayanData } from "../../app/customers/selectors";
import { useMainCompany } from "../../app/relations/component/user-relation-provider";

export const SdkContext = React.createContext();

export const useSdk = () => useContext(SdkContext);
export const useImpersonateSdk = () => {
    const sdk = useSdk();
    const company = useMainCompany();
    const isVayanData = useViewingAsVayanData();
    const isDistributor = useViewingAsDistributor();

    const imp = isVayanData ? sdk.impersonateByVayanData(company.target) :
        isDistributor ? sdk.impersonateByDistributor(company.target) :
            sdk;

    return imp;
}

export const useSdkForDistributor = () => {
    const sdk = useSdk();
    const company = useMainCompany();
    const imp = sdk.impersonateByDistributor(company.target);
    return imp;
}

export const withSdk = (Component) => {
    return function SdkWrapper(props) {
        return (
            <SdkContext.Consumer>
                {(sdk) => <Component {...props} sdk={sdk} />}
            </SdkContext.Consumer>
        )
    }
};

export const SdkProvider = ({ children, sdk }) => {
    return (
        <SdkContext.Provider value={sdk}>
            {children}
        </SdkContext.Provider>
    );
};
