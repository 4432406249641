import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import CustomerCoefficientField from '../../../customers/component/fields/coefficient-field';
import { useTranslation } from 'react-i18next';

export function coefficientInitialValues() {
    return {
        coefficient: 1
    }
}

export default function CoefficientFormForCreation(props) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.create-company-modal.create-company-coefficient' });
    return (
        <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
                <Typography>{t('title')}</Typography>
            </Grid>
            <Grid item xs={9}>
                <CustomerCoefficientField {...props} />
            </Grid>
        </Grid>
    )
}
