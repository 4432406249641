import React from 'react';
import { List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SessionReferenceName from '../../element/session-reference-name';
import RevokeSessionButton from '../../actions/revoke';
import SessionEndDateString from '../../element/session-end-date-string';
import RenewSessionButton from '../../actions/renew';
import SessionOrderStatus from '../../element/session-order-status';

const useStyles = makeStyles(theme => ({
    fullwidth: {
        width: "100%",
    },
    inline: {
        display: 'inline',
    }
}));

export const SessionItem = ({ policy, session }) => {

    const classes = useStyles();

    return (
        <ListItem className={classes.fullwidth}>
            <ListItemIcon>
                <SessionOrderStatus session={session} />
            </ListItemIcon>
            <ListItemText primary={<SessionReferenceName session={session} />}
                secondary={(<SessionEndDateString session={session} />)} />
            <ListItemSecondaryAction>
                <RevokeSessionButton className={classes.inline} session={session} />
                <RenewSessionButton className={classes.inline} session={session} />
            </ListItemSecondaryAction>
        </ListItem>
    )
}

export default function SessionsList({ sessions }) {
    const classes = useStyles();

    return (
        <List className={classes.fullwidth}>
            {sessions.map((session) => <SessionItem key={session.target} session={session} />)}
        </List>
    )
}