import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useImpersonateSdk } from '../../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../../lib/auth0-wrapper';

import { OPEN } from '../../../constants';
import ActionOk from '../../../../common/component/notification/action-ok';
import { useNotif } from '../../../../common/component/notification';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    button: {
        marginRight: theme.spacing(1),
    },
}));

export const PurchaseOrderEditable = ({ ...props }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.actions.customer.purchase-order' })

    return (
        <TextField
            {...props}
            label={t('placeholder')}
        />
    )
};

export function ChangePurchaseOrderController(props) {

    const { component, order, className, ...innerProps } = props;

    const dispatch = useDispatch();
    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const notif = useNotif();

    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(order.payload.purchase_order)

    innerProps.disabled = loading || order.payload.status !== OPEN
    innerProps.value = value
    innerProps.onChange = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        setValue(ev.target.value)
    }
    innerProps.onBlur = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        if (order.payload.status !== OPEN) return

        setLoading(true);
        dispatch(sdk.orders.changePurchaseOrder(auth, order.target, order.payload.customer, value))
            .then(() => {
                setLoading(false);
            })
            .catch(e => {
                setLoading(false);
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            });
    }


    return React.createElement(component, innerProps)
}



export default function ChangePurchaseOrder({ ...props }) {
    return (
        <ChangePurchaseOrderController {...props} component={PurchaseOrderEditable} />
    )
}
