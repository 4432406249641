import React, { useCallback, useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

export default function DeviceLabelEditable(props) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.devices.elements.label-edit' })
    const [error, setError] = useState(false)

    const onChange = useCallback((ev) => {

        const value = ev.target.value;
        if (value.length > 100) {
            setError('Only 100 characters allowed.');
        } else {
            setError(false);
        }

        props.onChange(value.slice(0, 100));
    }, []);

    useEffect(() => {
        if (!props.value) {
            props.onChange('');
        }
    }, []);

    return (
        <TextField
            error={!!error}
            label={t('label', { length: props?.value?.length || 0 })}
            name="label"
            value={props.value || ''}
            onBlur={onChange}
            onChange={onChange}
            helperText={error}
        />
    );
}
