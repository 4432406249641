import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useDispatch } from 'react-redux';
import { Typography, Button, Grid } from '@material-ui/core';

import { useSdk } from '../../../lib/licensing-sdk';
import { useNotif } from '../../common/component/notification';
import ActionDismiss from '../../common/component/notification/action-dismiss';
import ActionOk from '../../common/component/notification/action-ok';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
    },
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '100%',
        margin: 'auto',
    },
    spaced: {
        marginTop: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(2),
    },
}));


function EmailVerified(props) {

    const { goHome, disable, id, email } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const sdk = useSdk();
    const notif = useNotif();

    const sendVerificationEmail = useCallback((ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        dispatch(sdk.users.sendVerificationEmail(id))
            .then(() => notif.info(`An email was sent to ${email}.`, { persist: true, action: (key) => <ActionOk notif={notif} handle={key} /> }))
            .catch(e => notif.error(e, { persist: true, action: (key) => <ActionDismiss notif={notif} handle={key} /> }));
    }, [sdk, id]);

    return (
        <div className={classes.centered}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant='h5' component='h3'>Please activate your account before using the platform</Typography>
                    <Typography>We have already sent an email to {email} when you signed up.</Typography>
                    <Typography>Follow the instructions it contains in order to validate your email.</Typography>
                </Grid>
                <Grid className={classes.spaced} item xs={12}>
                    <Button className={classes.button} disable={disable} variant='contained' onClick={sendVerificationEmail}>Resend email</Button>
                    <Button className={classes.button} disable={disable} variant='outlined' onClick={goHome}>Go Home</Button>
                </Grid>
            </Grid>
        </div >
    );

}

export default EmailVerified;