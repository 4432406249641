import React, { forwardRef, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import { useAuth0 } from '../../../../lib/auth0-wrapper';
import { useNotif } from '../../../common/component/notification';
import { useImpersonateSdk } from '../../../../lib/licensing-sdk';
import { useDispatch } from 'react-redux';
import ActionOk from '../../../common/component/notification/action-ok';
import InteractiveLoadingButton from '../../../common/component/interactive-loading-button';
import { useCustomer } from '../../../customers/component/customer-provider';
import { useIsManager } from '../../../relations/selectors';
import { useViewingAsVayanData } from '../../../customers/selectors';
import { useTranslation } from 'react-i18next';

export const RevokeSessionView = forwardRef(({ ...props }, ref) => {
    const isAdmin = useViewingAsVayanData();
    const { t } = useTranslation('translation', { keyPrefix: 'app.sessions.actions.revoke' })
    const color = isAdmin ? 'primary' : 'secondary';
    return (
        <Button {...props} color={color} ref={ref}>
            {t('button-label')}
        </Button>
    )
});

export function RevokeSessionController({ className, component, ...props }) {
    const { session } = props;

    const dispatch = useDispatch();
    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const notif = useNotif();
    const { t } = useTranslation('translation', { keyPrefix: 'app.sessions.actions.revoke.tooltip' });

    const isAdmin = useViewingAsVayanData();
    const customer = useCustomer();

    const loading = session.status.includes('pending');
    const erred = session.status.includes('error');
    const disabled = loading || erred;
    const tooltip =
        loading ? t('loading') :
            erred ? t('error') :
                t('default');

    props.disabled = disabled;
    props.onClick = useCallback((ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        dispatch(sdk.sessions.revoke(auth, session.target, customer.target))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            });
    }, [sdk, auth, session]);

    if (!isAdmin && !session.payload.can_revoke) {
        return null;
    }

    return (
        <InteractiveLoadingButton className={className} tooltip={tooltip} loading={loading} >
            {React.createElement(component, props)}
        </InteractiveLoadingButton>
    )
}

export default function RevokeSessionButton({ session, ...props }) {

    const isManager = useIsManager();
    if (!isManager) {
        return null;
    }

    return (
        <RevokeSessionController {...props} session={session} component={RevokeSessionView} />
    )
}

