import React from 'react';
import {
    Grid, Typography, ExpansionPanelSummary,
    ExpansionPanelDetails, Divider, ExpansionPanel
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DefaultSubscriptionList from './subscriptions-list';
import { useMainCompany } from '../../relations/component/user-relation-provider';
import { useTranslation } from 'react-i18next';

const SubscriptionsListInPanel = ({ defaultExpanded }) => {

    const company = useMainCompany();
    const { t } = useTranslation('translation', { keyPrefix: 'app.subscriptions.list' })
    return (
        <ExpansionPanel defaultExpanded={defaultExpanded}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h4'>
                    {t('title')}
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DefaultSubscriptionList company={company} />
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
            <Divider />
        </ExpansionPanel>
    );
}

export default SubscriptionsListInPanel;