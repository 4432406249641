import React from 'react';
import { Typography } from '@material-ui/core';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { ResourceGetter } from '../../../../lib/licensing-sdk/getter';
import currencies from '../../../common/currencies';
import { BILLING_TIERS_MODE_GRADUATED, BILLING_TIERS_MODE_PER_UNIT } from '../../price-constants';
import _ from 'lodash';
import { useCoefficient } from '../../../customers/component/customer-provider';
import { useTranslation } from 'react-i18next';


export function computeGraduatedTiersPublicPrice(price, quantity, initialQuantity = 0) {
    const tiers = _.sortBy(price.payload.tiers, ['last', 'up_to']);
    const quantityPerTier = [];

    let lastUpTo = 0;
    for (let tier of tiers) {
        if (tier.last) {
            quantityPerTier.push({ quantity, tier, price: tier.amount * quantity });
            break; // terminal state
        } else if (tier.up_to <= initialQuantity) {
            lastUpTo = tier.up_to;
            continue; // skip the tiers that are below or equals the initialQuantity.
        } else {
            let quantityForTier = tier.up_to - lastUpTo;
            const quantityAlreadyInTier = initialQuantity < lastUpTo ? 0 : initialQuantity - lastUpTo;
            quantityForTier -= quantityAlreadyInTier;

            const quantityInTier = quantityForTier >= quantity ? quantity : quantityForTier;
            quantityPerTier.push({ quantity: quantityInTier, tier, price: tier.amount * quantityInTier });
            quantity -= quantityInTier;
            lastUpTo = tier.up_to;
            if (quantity <= 0) break; // terminal state
            else continue;
        }
    }

    return quantityPerTier;
}

export function applyCoefficientOnPrice(amount, coefficient) {
    return amount * coefficient;
}

export function computePriceString(amount, price) {
    return { currency: currencies[price.payload.currency], amount: amount.toFixed(2) };
}

function computePriceContent(publicPrice, coefficient, currency) {
    const unitPrice = publicPrice * coefficient;
    return { currency: currencies[currency], amount: unitPrice.toFixed(2) };
}

function TierPrice({ tier, mode, first, currency, coefficient, ...props }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.prices.elements.unit-price' })

    const content = t('tier-price', computePriceContent(tier.amount, coefficient, currency));

    let message;
    if (mode === BILLING_TIERS_MODE_GRADUATED) {
        if (first) {
            message = t('graduated.first', { quantity: tier.up_to });
        } else if (tier.last) {
            message = t('graduated.last');
        } else {
            message = t('graduated.next', { quantity: tier.up_to });
        }
    } else {
        if (first) {
            message = t('volume-based.first', { quantity: tier.up_to });
        } else if (tier.last) {
            message = t('volume-based.last');
        } else {
            message = t('volume-based.next', { quantity: tier.up_to });
        }
    }

    return (
        <Typography {...props}>{content} {message}</Typography>
    );

}

function TiersPrices({ price, ...props }) {
    const currency = price.payload.currency;
    const coefficient = useCoefficient();

    const tiers = _.sortBy(price.payload.tiers, ['last', 'up_to']);
    const mode = price.payload.tiers_mode;

    return _.map(tiers, (tier, i) => {
        return <TierPrice key={i} mode={mode} first={i == 0} tier={tier} currency={currency} coefficient={coefficient} />
    });

}

function UnitPrice({ price, ...props }) {
    const publicPrice = price.payload.unit_amount;
    const currency = price.payload.currency;
    const coefficient = useCoefficient();
    const { t } = useTranslation('translation', { keyPrefix: 'app.prices.elements.unit-price' })

    const content = t('unit-price', computePriceContent(publicPrice, coefficient, currency));

    return (
        <Typography {...props}>
            {content}
        </Typography>
    );
}

function SwitchPrices(props) {

    if (props.price.payload.tiers_mode === BILLING_TIERS_MODE_PER_UNIT) {
        return <UnitPrice {...props} />
    } else {
        return <TiersPrices {...props} />
    }

}

function PriceUnitPrice({ price, ...props }) {
    return (
        <ResourceGetter resource='prices' id={price} render={({ price }) =>
            <WaitingLoaded data={price} render={() => (
                <SwitchPrices {...props} price={price} />
            )} />
        } />
    )
}

export default PriceUnitPrice;
