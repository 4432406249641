import React, { useCallback, useState } from 'react';
import { Button, Dialog, DialogActions, DialogTitle, Typography } from '@material-ui/core';
import { ONBOARDING_COMPLETED } from '../../../companies/constants';
import { useNotif } from '../../../common/component/notification';
import { useSdk } from '../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../lib/auth0-wrapper';
import { useDispatch } from 'react-redux';
import ActionOk from '../../../common/component/notification/action-ok';
import { useMainCompany } from '../../../relations/component/user-relation-provider';
import { useChangeLocation } from '../customer-provider';
import { useTranslation } from 'react-i18next';

function useHandleConfirm(onClose, customer, vayandata) {
    const notif = useNotif();
    const sdk = useSdk();
    const auth = useAuth0();
    const dispatch = useDispatch();
    const changeLocation = useChangeLocation();

    return useCallback(() => {
        const payload = {
            onboarding_status: ONBOARDING_COMPLETED
        };

        const imp = sdk.impersonateByVayanData(vayandata.target);

        dispatch(imp.customers.changeOnboardingStatus(auth, payload, customer.target))
            .then(() => {
                onClose();
                changeLocation(`customers/awaiting-validation`)
            })
            .catch(err => {
                const message = err.response?.data?.message || err.message;
                notif.error(message, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            });
    }, []);
}

function ValidationModal({ open, onClose, onConfirm }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.awaiting-validation.validate-company-button' })
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <Typography display='inline'>{t('modal-title')}</Typography>
            </DialogTitle>
            <DialogActions>
                <Button onClick={onClose} color="inherit">{t('modal-cancel')}</Button>
                <Button onClick={onConfirm} color="primary">{t('modal-confirm')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default function ValidationButton({ customer }) {
    const [open, setOpen] = useState(false);
    const onOpen = () => setOpen(true);
    const onClose = () => setOpen(false);
    const vayandata = useMainCompany();
    const onConfirm = useHandleConfirm(onClose, customer, vayandata);
    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.awaiting-validation.validate-company-button' })

    return (
        <>
            <Button variant='contained' color='primary' onClick={onOpen}>{t('label')}</Button>
            <ValidationModal open={open} onClose={onClose} onConfirm={onConfirm} />
        </>
    )
}