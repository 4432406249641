import React, { Fragment, forwardRef, useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import InteractiveLoadingButton from '../../../common/component/interactive-loading-button';
import MakeOrderFromDevice from '../../../orders/component/order-modal/make-order-from-device.js';
import { useIsManager } from '../../../relations/selectors';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    button: {
        marginRight: theme.spacing(1),
    },
}));

export const SubscribeDeviceView = forwardRef((props, ref) => {

    const { t } = useTranslation('translation', { keyPrefix: 'app.devices.actions.subscribe' });
    const classes = useStyles();

    return (
        <Button
            color='secondary'
            className={classes.button}
            size='small'
            {...props}
            ref={ref}
        >{t('button-label')}</Button>
    )
})

export default function SubscribeDeviceButton({ device, ...innerProps }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.devices.actions.subscribe' });
    const [open, setOpen] = useState(false);
    const openDialog = useCallback((ev) => {
        if (ev) {
            ev.stopPropagation();
            ev.preventDefault();
        }

        setOpen(true)
    }, []);
    const closeDialog = useCallback((ev) => {
        if (ev) {
            ev.stopPropagation();
            ev.preventDefault();
        }
        setOpen(false);
    }, []);

    const isManager = useIsManager();
    const loading = device.status.includes('pending');
    const erred = device.status.includes('error');
    const disabled = loading || erred || !device.payload.active;
    const tooltip =
        loading ? t('loading') :
            erred ? t('error') :
                !device.payload.active ? t('deactivated') :
                    t('tooltip');


    if (!isManager) {
        return null;
    }

    return (
        <Fragment>
            <InteractiveLoadingButton tooltip={tooltip} loading={loading} >
                <SubscribeDeviceView disabled={disabled} onClick={openDialog} {...innerProps} />
            </InteractiveLoadingButton>
            <MakeOrderFromDevice device={device} open={open} onClose={closeDialog} />
        </Fragment>

    )
}
