import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '../../lib/auth0-wrapper';
import { useSdk } from '../../lib/licensing-sdk';
import { useNotif } from '../common/component/notification';
import ActionOk from '../common/component/notification/action-ok';
import { useMainCompany } from '../relations/component/user-relation-provider';

export const useLoadPermanentDataForCustomer = (setLoading, setError) => {

    const sdk = useSdk();
    const auth = useAuth0();
    const dispatch = useDispatch();
    const notif = useNotif();

    const company = useMainCompany();

    useEffect(() => {
        setError(false);
        setLoading(true);
        dispatch(sdk.helpers.clear())
            .then(() => dispatch(sdk.prices.list(auth, company.target)))
            .catch((err) => {
                setError(true);
                const message = err.response?.data?.message || err.message;
                notif.error(message, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            })
            .finally(() => {
                setLoading(false);
            })
    }, [company.target]); // eslint-disable-line react-hooks/exhaustive-deps
}

export const useLoadPermanentDataForVayanData = (setLoading, setError) => {

    const sdk = useSdk();
    const auth = useAuth0();
    const dispatch = useDispatch();
    const notif = useNotif();

    const company = useMainCompany();

    useEffect(() => {
        setError(false);
        setLoading(true);

        const imp = sdk.impersonateByVayanData(company.target);

        dispatch(sdk.helpers.clear())
            .then(() => dispatch(sdk.prices.list(auth, company.target)))
            .then(() => dispatch(imp.customers.list(auth)))
            .then((customers) =>
                Promise.all(_.map(customers, customer =>
                    dispatch(imp.customers.get(auth, customer))
                ))
            )
            .catch((err) => {
                setError(true);
                const message = err.response?.data?.message || err.message;
                notif.error(message, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            })
            .finally(() => {
                setLoading(false);
            })
    }, [company.target]); // eslint-disable-line react-hooks/exhaustive-deps
}

export const useLoadPermanentDataForDistributor = (setLoading, setError) => {

    const sdk = useSdk();
    const auth = useAuth0();
    const dispatch = useDispatch();
    const notif = useNotif();

    const company = useMainCompany();

    useEffect(() => {
        setError(false);
        setLoading(true);

        const imp = sdk.impersonateByDistributor(company.target);

        dispatch(sdk.helpers.clear())
            .then(() => dispatch(sdk.prices.list(auth, company.target)))
            .then(() => dispatch(imp.customers.list(auth)))
            .then((customers) =>
                Promise.all(_.map(customers, customer => dispatch(imp.customers.get(auth, customer))))
            )
            .catch((err) => {
                setError(true);
                const message = err.response?.data?.message || err.message;
                notif.error(message, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            })
            .finally(() => {
                setLoading(false);
            });
    }, [company.target]); // eslint-disable-line react-hooks/exhaustive-deps
}