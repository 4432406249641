import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { useCustomer } from '../customer-provider';
import { useMainCompany } from '../../../relations/component/user-relation-provider';
import GenerateInvoiceModal from './generate-invoice-modal';
import { useViewingAsVayanData } from '../../selectors';
import { INVOICE_MANUALLY } from '../../../companies/constants';
import { useTranslation } from 'react-i18next';

export default function GenerateInvoiceButton({ ...props }) {

    const [open, setOpen] = useState(false);
    const onClose = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(false);
    };

    const onOpen = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(true);
    }

    const company = useMainCompany();
    const customer = useCustomer();
    const isAdmin = useViewingAsVayanData();
    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.generate-invoice.button' });

    const isPolicyManually = customer.payload.invoice_policy === INVOICE_MANUALLY;

    if (!isAdmin) return null;
    if (!isPolicyManually) return null;

    return (
        <React.Fragment>
            <Button {...props} onClick={onOpen}>{t('label', { name: customer.payload.name })}</Button>
            <GenerateInvoiceModal
                vayandata={company}
                customer={customer}
                open={open} onClose={onClose} />
        </React.Fragment>
    );
}
