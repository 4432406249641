import { makeStyles } from "@material-ui/core";


const useStylesSidebar = makeStyles(theme => ({
    iconSelected: {
        color: theme.palette.getContrastText(theme.palette.background.default)
    },
    iconDefault: {
        color: theme.palette.getContrastText(theme.palette.primary.main)
    },
    sidebarSelected: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.getContrastText(theme.palette.background.default),
    },
    sidebarDefault: {
        backgroundColor: 'inherit',
        color: 'inherit',
    },
    textSelected: {
        fontWeight: theme.typography.fontWeightMedium
    },
    textDefault: {
        fontWeight: theme.typography.fontWeightLight
    }
}))

export const useSidebarSelected = (selected = false) => {
    const classes = useStylesSidebar()
    return {
        sidebarItem: selected ? classes.sidebarSelected : classes.sidebarDefault,
        icon: selected ? classes.iconSelected : classes.iconDefault,
        text: selected ? classes.textSelected : classes.textDefault,
    }
}
