import React from 'react';
import {
    ListItem,
    ListItemText,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSidebarSelected } from '../../common/sidebar';

function UsersSidebarButton({ onClick, selected, ...props }) {

    const sidebarClasses = useSidebarSelected(selected)
    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb(ev);
    }

    const { t } = useTranslation('translation', { keyPrefix: 'app.users.sidebar-button' });


    return (
        <ListItem {...props} button onClick={handleClick(onClick)}>
            <ListItemText
                primaryTypographyProps={{ className: sidebarClasses.text }}
                primary={t('label')} />
        </ListItem >
    )

}

export default UsersSidebarButton;
