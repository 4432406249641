import React from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    field: {
        width: '100%'
    }
}));

const CompanyNameField = ({ namespace, isSubmitting, ...props }) => {

    const classes = useStyles();
    const name = `${namespace ? namespace + '.' : ''}name`;
    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.forms.name-form.company-name-field' });

    return (
        <Field
            className={classes.field}
            component={TextField}
            name={name}
            type="text"
            label={t('label')}
            validate={value => value !== '' ? undefined : t('validation') }
            disabled={isSubmitting}
        />
    );
}


export default CompanyNameField;
