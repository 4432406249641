import React from 'react';
import { Typography } from '@material-ui/core';
import { useCustomerInDb } from '../../selectors';

function CustomerDaysUntilDue({ customer, ...props }) {

    customer = useCustomerInDb(customer);

    return (
        <Typography {...props}>{customer.payload.days_until_due}</Typography>
    )
}

export default CustomerDaysUntilDue;