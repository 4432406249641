
import React, { useMemo, createContext, useContext, useEffect, useState } from 'react';
import { join } from 'path';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Switch, Route, useRouteMatch, useParams, Redirect } from 'react-router-dom';
import { push, goBack } from 'connected-react-router';
import { useSdk } from '../../../lib/licensing-sdk';
import { useMainCompany } from '../../relations/component/user-relation-provider';
import { GRADE_DISTRIBUTOR, GRADE_VAYAN_DATA } from '../../companies/constants';
import CenteredFullScreen from '../../common/component/centered-full-screen';
import { Box, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const COEF_POLICY_CUSTOMER = 'customer';
export const COEF_POLICY_BUYER = 'buyer';

const customerContext = createContext({
    customer: undefined,
    coefficientPolicy: {
        coefficient: null,
        policy: null,
        change: (checked) => { console.error('early call to coefficientPolicy.change().'); }
    },
    change: () => { console.error('early call to change().'); },
    goTo: () => { console.error('early call to goTo().'); }
});

export const useCustomer = () => useContext(customerContext).customer;
export const useChangeCustomer = () => useContext(customerContext).change;
export const useChangeLocation = () => useContext(customerContext).goTo;
export const useCoefficient = () => useContext(customerContext).coefficientPolicy.coefficient;
export const useCoefficientPolicy = () => useContext(customerContext).coefficientPolicy.policy;
export const useChangeCoefficientPolicy = () => useContext(customerContext).coefficientPolicy.change;

export const useCustomerAPIAuthorization = () => {
    const company = useMainCompany();
    const customer = useCustomer();

    let distributor = undefined;
    let vayanData = undefined;
    if (company && customer) {
        if (company.target !== customer.target) { // if the main company and the customer seen are different then we are in a priviledged case.
            distributor = company.payload.grade === GRADE_DISTRIBUTOR ? company.target : undefined;
            vayanData = company.payload.grade === GRADE_VAYAN_DATA ? company.target : undefined;
        }
    }

    return { distributor, vayanData };
}

const InternalCustomerProvider = ({ children, changeLocation }) => {

    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.customer-provider' })
    const { customer: customerId } = useParams();
    const [coefPolicy, setCoefPolicy] = useState(COEF_POLICY_CUSTOMER);
    const sdk = useSdk();
    const dispatch = useDispatch();
    // eslint-disable-next-line   
    const customerSelector = useMemo(() => sdk.customers.selectors.makeGet(), []);
    let customer = useSelector(state => customerSelector(state, customerId));

    const company = useMainCompany();

    useEffect(() => {
        setCoefPolicy('customer');
    }, [customerId]);

    if (customerId === company.target) {
        customer = company;
    }

    if (customer && customer.payload) {

        const value = {
            customer,
            coefficientPolicy: {
                coefficient: coefPolicy === COEF_POLICY_CUSTOMER ? customer.payload.coefficient : company.payload.coefficient,
                policy: coefPolicy,
                change: (checked) => setCoefPolicy(checked ? COEF_POLICY_CUSTOMER : COEF_POLICY_BUYER)
            },
            change: (id, path = '') => {
                changeLocation(join(id, path));
            },
            goTo: (path) => {
                changeLocation(join(customerId, path))
            }
        }

        return (
            <customerContext.Provider value={value}>
                {children}
            </customerContext.Provider>
        );
    } else {
        return (
            <CenteredFullScreen>
                <Box display='flex' flexDirection='column'>
                    <Typography>{t('waiting-data')}</Typography>
                    <Button onClick={() => dispatch(goBack())}>{t('back-button')}</Button>
                </Box>
            </CenteredFullScreen>
        );
    }
}

const CustomerProvider = ({ children, ...props }) => {

    let { url } = useRouteMatch();
    url = url.endsWith('/') ? url.slice(0, -1) : url;
    const dispatch = useDispatch();

    const changeLocation = (to = '') => {
        dispatch(push(`${url}/c/${to}`));
    };

    const company = useMainCompany();

    return (
        <Switch >
            <Route path={`${url}/c/:customer`} render={() =>
                <InternalCustomerProvider changeLocation={changeLocation} children={children} />
            } />
            <Route path={`${url}/`} render={() =>
                <Redirect to={`${url}/c/${company.target}`} />
            } />
        </Switch>
    );
}

export default CustomerProvider;
