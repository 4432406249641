import React, { forwardRef } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '../../../../../lib/auth0-wrapper';
import ActionOk from '../../../../common/component/notification/action-ok';
import { useNotif } from '../../../../common/component/notification';
import { useStripe } from '@stripe/react-stripe-js';
import InteractiveLoadingButton from '../../../../common/component/interactive-loading-button';
import { useCustomer } from '../../../../customers/component/customer-provider';
import { useImpersonateSdk } from '../../../../../lib/licensing-sdk';
import { useTranslation } from 'react-i18next';

const Reconcile3DSecure = forwardRef((props, ref) => {
    const { t } = useTranslation('translation', { keyPrefix: 'app.invoices.actions.customer.handle-3d-secure' });
    return (
        <Button
            {...props}
            color='secondary'
            size='small'
            ref={ref}
        >{t('button-label')}</Button>
    )
})

function Reconcile3DSecureController({ ...props }) {

    const { invoice } = props;
    const { t } = useTranslation('translation', { keyPrefix: 'app.invoices.actions.customer.handle-3d-secure' });
    const customer = useCustomer();

    const stripe = useStripe();
    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const notif = useNotif();
    const dispatch = useDispatch();

    const loading = invoice.status.includes('pending');
    const erred = invoice.status.includes('error');
    const disabled = loading || erred;
    const tooltip =
        loading ? t('loading') :
            erred ? t('error') :
                t('tooltip');

    const { status, payment_intent } = invoice.payload;
    if (status !== 'open') return null;
    if (!payment_intent || payment_intent.status !== 'requires_action') return null;


    props.disabled = disabled;
    props.onClick = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        stripe.confirmCardPayment(payment_intent.client_secret)
            .then((result) => {
                if (result.error) throw result.error;
            }).catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            }).finally(() =>
                dispatch(sdk.invoices.get(auth, invoice.target, customer.target, true)));
    }

    return (
        <InteractiveLoadingButton tooltip={tooltip} loading={loading} >
            {React.createElement(Reconcile3DSecure, props)}
        </InteractiveLoadingButton>
    )
}


export default function Reconcile3DSecureButton({ invoice }) {
    return (
        <Reconcile3DSecureController
            invoice={invoice}
        />
    )
}
