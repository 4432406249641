import React, { forwardRef, useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useImpersonateSdk } from '../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../lib/auth0-wrapper';
import InteractiveLoadingButton from '../../../common/component/interactive-loading-button';
import { useNotif } from '../../../common/component/notification';
import ActionOk from '../../../common/component/notification/action-ok';
import { useCustomer } from '../../../customers/component/customer-provider';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    button: {
        marginRight: theme.spacing(1),
    },
}));

export const AcceptRequestView = forwardRef(({ onClick, ...props }, ref) => {

    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.office-demo-requests.actions.vayandata.accept' })
    return (
        <Button
            color='primary'
            className={classes.button}
            size='small'
            onClick={onClick}
            {...props}
            ref={ref}
        >{t('label')}</Button>
    )
});

export function AcceptRequestController(props) {

    const { component, request, className, onDone = () => { }, ...innerProps } = props;

    const sdk = useImpersonateSdk();
    const customer = useCustomer();
    const auth = useAuth0();
    const notif = useNotif();
    const { t } = useTranslation('translation', { keyPrefix: 'app.office-demo-requests.actions.vayandata.accept' })

    const [loading, setLoading] = useState(false);
    const disabled = loading;
    const tooltip = loading ? t('tooltip.loading') :
        t('tooltip.default');

    innerProps.disabled = disabled;
    innerProps.onClick = useCallback((ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        setLoading(true);
        sdk.devices.acceptOfficeDemoRequestsForVD(auth, customer.target, request._id)
            .then(() => {
                onDone(request);
            })
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            })
            .finally(() => setLoading(false));
    }, [sdk, auth, request]);

    return (
        <InteractiveLoadingButton className={className} tooltip={tooltip} loading={loading} >
            {React.createElement(component, innerProps)}
        </InteractiveLoadingButton>
    )
}



export default function AcceptRequestButton({ ...props }) {
    return (
        <AcceptRequestController {...props} component={AcceptRequestView} />
    )
}
