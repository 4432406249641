import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { useSdk } from "../../lib/licensing-sdk";
import { useCustomer } from "../customers/component/customer-provider";
import { useCurrentRelation } from "./component/user-relation-provider"

export const OWNER = 'owner';
export const ACCOUNTANT = 'accountant';
export const MANAGER = 'manager';
export const USER = 'user';
export const ROLES = [OWNER, ACCOUNTANT, MANAGER, USER];

export const useRole = () => {
    const relation = useCurrentRelation();
    return relation.payload.role;
}

export const useIsOwner = () => {
    const role = useRole();
    return  [OWNER].includes(role);
}

export const useIsAccountant = () => {
    const role = useRole();
    return [OWNER, ACCOUNTANT].includes(role);
}

export const useIsManager = () => {
    const role = useRole();
    return [OWNER, MANAGER].includes(role);
}

export const useIsUser = () => {
    const role = useRole();
    return [OWNER, USER].includes(role);
}

export function useRelationListForCustomer() {
    const sdk = useSdk();
    const customer = useCustomer();
    const selector =  useMemo(() => createSelector(
        sdk.relations.selectors.getDb,
        (relations) => {
            return _.chain(relations)
            .filter(relation => relation.payload)
            .filter(relation => relation.payload.target === customer.target)
            .value();
        }
    ), []);

    return useSelector(selector);
}

export const useUserRelationsList = (user) => {
    const sdk = useSdk();

    const selector = useMemo(() => createSelector(
        sdk.relations.selectors.makePopulateList(),
        (relations) => _.chain(relations)
            .filter(relation => relation.payload.user == user)
            .value()
    ), [user]);

    return useSelector(selector);
}