import React, { forwardRef, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import { useCustomer } from '../../../../customers/component/customer-provider';
import { useTranslation } from 'react-i18next';

const PayInvoiceInStripe = forwardRef(({ invoice, ...props }, ref) => {

    const customer = useCustomer();
    const { t } = useTranslation('translation', { keyPrefix: 'app.invoices.actions.customer.pay-invoice' });
    const onClick = useCallback((ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        window.open(invoice.payload.hosted_invoice_url, '_blank');
    }, [invoice, customer]);

    return (
        <Button
            {...props}
            ref={ref}
            color='secondary'
            size='small'
            onClick={onClick}
        >{t('button-redirect-to-stripe')}</Button>
    )
})

export default PayInvoiceInStripe;

