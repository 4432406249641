import React, { useEffect, useState } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '../../../../../lib/auth0-wrapper';
import { useSdk } from '../../../../../lib/licensing-sdk';
import Loader from '../../../../common/component/loader';
import { useNotif } from '../../../../common/component/notification';
import ActionOk from '../../../../common/component/notification/action-ok';
import { useCustomer, useCustomerAPIAuthorization } from '../../../../customers/component/customer-provider';

export default function WaitForSetupIntent({ component, ...props }) {

    const stripe = useStripe();
    const elements = useElements();

    const dispatch = useDispatch();
    const auth = useAuth0();
    const sdk = useSdk();
    const notif = useNotif();
    const [secret, setSecret] = useState(null);

    const customer = useCustomer();
    const { distributor, vayanData } = useCustomerAPIAuthorization();

    useEffect(() => {
        dispatch(sdk.paymentmethods.createSetupIntent(auth, { company: customer.target, distributor, vayanData }))
            .then((clientSecret) => {
                setSecret(clientSecret);
            })
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> })
            });
    }, []);

    if (!secret || !stripe || !elements) {
        return <Loader />
    } else {
        props.secret = secret;
        props.stripe = stripe;
        props.elements = elements;
        return React.createElement(component, props);
    }
}