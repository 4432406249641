import React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as Sentry from '@sentry/browser';
import './i18n';
import './index.css';
import * as serviceWorker from './serviceWorker';

import theme from './theme';

import SDK, { SdkProvider } from './lib/licensing-sdk';

// Handlers are responsible of the initialization
import HistoryHandler from './core/history-handler';
import AuthHandler from './core/auth-handler';
import StoreHandler from './core/store-handler';
import RouterHandler from './core/router-handler';

import ErrorBoundary from './app/error/component/error-boundary';
import RequireLogin from './app/authentication/component/require-login';

import App from './app/application/component';
import { CssBaseline } from '@material-ui/core';
import NotificationProvider from './app/common/component/notification';

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: `${process.env.NODE_ENV}-${process.env.REACT_APP_VERSION}`,
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, { locale: 'en' });
const sdk = new SDK(process.env.REACT_APP_API_BASEPATH, 'sdk');

ReactDOM.render(
    <HistoryHandler>
        <AuthHandler>
            <SdkProvider sdk={sdk}>
                <StoreHandler>
                    <RouterHandler>
                        <Elements stripe={stripePromise}>
                            <MuiThemeProvider theme={theme}>
                                <CssBaseline />
                                <NotificationProvider>
                                    <ErrorBoundary>
                                        <RequireLogin>
                                            <App />
                                        </RequireLogin>
                                    </ErrorBoundary>
                                </NotificationProvider >
                            </MuiThemeProvider>
                        </Elements>
                    </RouterHandler>
                </StoreHandler>
            </SdkProvider>
        </AuthHandler>
    </HistoryHandler>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
