import React from 'react';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { Typography } from '@material-ui/core';
import { ResourceGetter } from '../../../../lib/licensing-sdk/getter';
import { DEVICE } from '../../product-constants';

export function getProductDeviceName(product) {
    return product?.payload?.description?.device_name || DEVICE;
}

function ProductDeviceName({ product, plural, ...props }) {
    return (
        <ResourceGetter id={product} resource='products' render={({ product }) =>
            <WaitingLoaded data={product} render={() => (
                <Typography {...props} >
                    {product.payload.description.device_name || product.payload.works_for}{plural ? 's' : null}
                </Typography>)
            } />
        } />
    )
}

export default ProductDeviceName;
