import React from 'react';
import { Typography } from '@material-ui/core';
import { ONE_TIME } from '../../price-constants';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { ResourceGetter } from '../../../../lib/licensing-sdk/getter';
import { useTranslation } from 'react-i18next';

export function usePriceReccuringInterval(price) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.prices.elements.recurring-interval' })

    if (price.payload.type === ONE_TIME) {
        return null;
    } else {
        const interval = price.payload.recurring.interval;
        const unit = t(`units.${price.payload.recurring.unit}`, { count: interval });
        const message = t('interval', { interval, unit })
        return message;
    }
}

function RecurringInterval({ price, ...props }) {

    const message = usePriceReccuringInterval(price);

    if (price.payload.type === ONE_TIME) {
        return null;
    } else {
        return (
            <Typography {...props}>
                {message}
            </Typography>
        );
    }
}

function PriceRecurringInterval({ price, ...props }) {

    return (
        <ResourceGetter resource='prices' id={price} render={({ price }) =>
            <WaitingLoaded data={price} render={() => (
                <RecurringInterval {...props} price={price} />
            )} />
        } />
    )
}

export default PriceRecurringInterval;
