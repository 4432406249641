import React from 'react';
import { Formik } from 'formik';

export default function AddressFormBaseCtrl(props) {
    const { component, onSubmit, company, ...innerProps } = props;

    if (company.payload) {
        return (
            <Formik
                initialValues={company.payload.address}
                onSubmit={onSubmit}
            >
                {(props) => React.createElement(component, { ...innerProps, company, ...props })}
            </Formik>
        );
    } else {
        return React.createElement(component, { ...innerProps, company, isSubmitting: true });
    }
}
