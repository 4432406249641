import React from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { useTranslation } from 'react-i18next';


const CustomerCoefficientField = ({ namespace, min = 0, max = 1, isSubmitting, ...props }) => {

    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.fields.customer-coefficient' })
    const name = `${namespace ? namespace + '.' : ''}coefficient`;

    return (
        <Field
            component={TextField}
            name={name}
            type="number"
            label={t('label')}
            InputProps={{ inputProps: { min: `${min}`, max: `${max}`, step: '0.05' } }}
            validate={value => {
                if (value > max || value < min) return t('validation', { min, max });
            }}
            disabled={isSubmitting}
        />
    );
}


export default CustomerCoefficientField;
