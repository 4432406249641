import React from 'react';
import {
    ListItem,
    ListItemText,
} from '@material-ui/core';
import { useCustomerIsDistributed, useCustomerIsVayanData } from '../../customers/selectors';
import { useTranslation } from 'react-i18next';
import { useSidebarSelected } from '../../common/sidebar';

function AddressSidebarButton({ onClick, selected, ...props }) {
    const { t } = useTranslation();
    const isVayanData = useCustomerIsVayanData();
    const isDistributed = useCustomerIsDistributed();
    const sidebarClasses = useSidebarSelected(selected)

    if (isVayanData || isDistributed) return null;

    return (
        <ListItem {...props} button onClick={onClick}>
            <ListItemText
                primaryTypographyProps={{ className: sidebarClasses.text }}
                primary={t('app.account.account-address.address')} />
        </ListItem >
    )

}

export default AddressSidebarButton;
