import React, { Fragment, forwardRef, useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useImpersonateSdk } from '../../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../../lib/auth0-wrapper';
import { FINALIZED } from '../../../constants';
import InteractiveLoadingButton from '../../../../common/component/interactive-loading-button';
import { useNotif } from '../../../../common/component/notification';
import ActionOk from '../../../../common/component/notification/action-ok';
import { Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    button: {
        marginRight: theme.spacing(1),
    },
}));

export const RevokeOrderView = forwardRef(({ onClick, ...props }, ref) => {

    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.actions.vayandata.revoke' })
    const [open, setOpen] = useState(false);

    const handleClose = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(false);
    }
    const handleOpen = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(true);
    }
    const handleConfirm = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        onClick();
        setOpen(false);
    }

    return (
        <Fragment>
            <Button
                color='primary'
                className={classes.button}
                size='small'
                onClick={handleOpen}
                {...props}
                ref={ref}
            >
                {t('button-label')}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{
                    t('modal.title')
                }</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} >
                        {t('modal.cancel')}
                    </Button>
                    <Button onClick={handleConfirm} color="primary" autoFocus>
                        {t('modal.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
});

export function RevokeOrderController(props) {

    const { component, order, className, ...innerProps } = props;

    const dispatch = useDispatch();
    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const notif = useNotif();
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.actions.vayandata.revoke' })

    const [loading, setLoading] = useState(false);
    let tooltip = loading ? t('tooltip.loading') :
        t('tooltip.default');

    innerProps.disabled = loading;
    innerProps.onClick = useCallback((ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        setLoading(true);
        dispatch(sdk.orders.revoke(auth, order.target, order.payload.customer))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            })
            .finally(() => setLoading(false));
    }, [sdk, auth, order.target]);

    if (order.payload.status !== FINALIZED) {
        tooltip = t('tooltip.invalid-status');
        innerProps.disabled = true;
    }

    return (
        <InteractiveLoadingButton className={className} tooltip={tooltip} loading={loading} >
            {React.createElement(component, innerProps)}
        </InteractiveLoadingButton>
    )
}



export default function RevokeOrderButton({ ...props }) {
    return (
        <RevokeOrderController {...props} component={RevokeOrderView} />
    )
}
