import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    full: {
        width: '100%',
        display: 'flex',
        flexGrow: 1,
    }
}));

const FullScreen = ({ children }) => {
    const classes = useStyles();

    return (
        <div className={classes.full}>
            {children}
        </div>
    );
}

export default FullScreen;
