import React, { useCallback, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { useReferenceById } from '../../../references/selector';
import { useReferenceName } from '../../../references/component/element/reference-name';
import AssociateDevice from '../../../sessions/component/associate-modal/associate-device';
import AssociateActions from './actions';
import { useFindProductById } from '../../../products/selector';
import DeviceSumupList from '../../../order-lines/component/sumup/device-sumup-list';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

export default function AssociateDeviceModal({ open, onClose, line }) {

    const reference = useReferenceById(line.payload.reference);
    const product = useFindProductById(reference.payload.product);
    const { t } = useTranslation('translation', { keyPrefix: 'app.devices.available-licenses.modal' });

    const referenceName = useReferenceName(reference);
    const [device, setDevice] = useState(null);
    const [proceed, setProceed] = useState([]);
    const onProceed = useCallback((payload) => setProceed(payload), [setProceed]);
    const devices = _.compact([device]);

    if (open) {
        return (
            <Dialog fullWidth maxWidth='md' open={open} onClose={onClose}>
                <DialogTitle>
                    <Typography display='inline'>{t('title', { reference: referenceName })}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}><AssociateDevice device={device} setDevice={setDevice} /></Grid>
                        {device && <Grid item xs={12}><DeviceSumupList devices={devices} product={product} reference={reference} onProceed={onProceed} /></Grid>}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <AssociateActions line={line} device={device} onClose={onClose} disabled={!proceed.allow} />
                </DialogActions>
            </Dialog >
        );
    } else {
        return null;
    }

}
