import React, { useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import { useIsManager } from '../../../relations/selectors';
import AssociateDeviceModal from './associate-modal';
import { useTranslation } from 'react-i18next';

export function AssociateDeviceButtonView({ line, ...props }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.devices.available-licenses.button' })
    const [open, setOpen] = useState(false);
    const openDialog = useCallback(() => setOpen(true), []);
    const closeDialog = useCallback(() => setOpen(false), []);

    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb(ev);
    }

    return (
        <React.Fragment>
            <Button {...props} onClick={handleClick(openDialog)} color='secondary'>{t('label')}</Button>
            <AssociateDeviceModal line={line} open={open} onClose={handleClick(closeDialog)} />
        </React.Fragment>
    )
}

export default function AssociateDeviceButton({...props }) {

    const isManager = useIsManager();
    if (!isManager) {
        return null;
    }

    return <AssociateDeviceButtonView {...props} />
}

