import React from 'react';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { Typography } from '@material-ui/core';
import { ResourceGetter } from '../../../../lib/licensing-sdk/getter';

export function companyName(company) {
    return company.payload.name;
}

function CompanyName({ company, defaultSdk, ...props }) {
    return (
        <ResourceGetter resource='companies' id={company} defaultSdk={defaultSdk} render={({ companie: company }) =>
            <WaitingLoaded data={company} render={() =>
                (<Typography {...props}>{company.payload.name}</Typography>)
            } />
        } />
    )
}

export default CompanyName;