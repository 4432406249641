import React from 'react';
import { Typography } from '@material-ui/core';

export function orderLinePeriodStartString(line) {
    if (line.payload?.period.start) {
        const start = new Date(line.payload.period.start);
        return start.toLocaleString()
    } else {
        return '';
    }
}

function OrderLinePeriodStart({ line, ...props }) {
    if (line.payload?.period.end) {
        return (
            <Typography {...props}>
                {orderLinePeriodStartString(line)}
            </Typography>
        )
    } else {
        return null;
    }
}

export default OrderLinePeriodStart;
