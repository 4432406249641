import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useReferenceById } from '../../selector';

export const useReferenceLogo = (reference) => reference.payload.description.logo ? reference.payload.description.logo : null;

const useStyles = makeStyles(theme => ({
    right: {
        minWidth: '40px',
        maxWidth: '40px',
        height: '40px',
        marginLeft: theme.spacing(2),
    }
}));

function ReferenceLogo({ reference, ...props }) {

    const classes = useStyles();

    reference = useReferenceById(reference);
    const logo = useReferenceLogo(reference);

    return (
        <div className={classes.right}>
            {logo ? <img src={logo} height='40px' width='40px' /> : <Box width={40} height={40}></Box>}
        </div>
    )
}

export default ReferenceLogo;
