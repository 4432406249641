import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { ResourceGetter } from '../../../../lib/licensing-sdk/getter';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'inline-block',
        whiteSpace: 'nowrap',
    },
    picture: {
        display: 'inline',
        verticalAlign: 'middle',
        borderRadius: '50%',
    },
    large: {
        width: 40,
        height: 40,
    },
    small: {
        width: 25,
        height: 25,
    },
    text: {
        marginLeft: theme.spacing(2),
        verticalAlign: 'middle',
    }
}))

function UserEmailWithAvatar({ user, small }) {
    const classes = useStyles();

    const pictureClasses = [classes.picture, (small ? classes.small : classes.large)];

    return (
        <ResourceGetter resource='users' id={user} render={({ user }) =>
            <WaitingLoaded data={user} render={() => (
                <div className={classes.container}>
                    {user.payload.picture && <img className={classnames(pictureClasses)} src={user.payload.picture} alt={user.payload.email} />}
                    <span className={classes.text}>{user.payload.email}</span>
                </div>
            )} />
        } />
    )

}

export default UserEmailWithAvatar;
