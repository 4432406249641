import React from 'react';
import { useCustomer } from '../customer-provider';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, List, Typography } from "@material-ui/core";
import { companyName } from '../../../companies/component/elements/company-name';
import ChangeCoefficientByDistributorSidebarButton from '../change-coefficient/change-coefficient-by-distributor-sidebar-button';
import CustomerCoefficient from '../elements/customer-coefficient';
import { useTranslation } from 'react-i18next';

export default function CustomerOverviewByDistributor({ props }) {
    const customer = useCustomer();
    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.overview.by-distributor' })

    return (
        <ExpansionPanel expanded>
            <ExpansionPanelSummary>
                <Typography>{t('title', { name: companyName(customer) })}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Typography>{t('coefficient')}</Typography>
                        <CustomerCoefficient customer={customer.target} />
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>
                        <List>
                            <ChangeCoefficientByDistributorSidebarButton />
                        </List>
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>

    )
}
