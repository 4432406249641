import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import DeviceList from './device-list';

function DevicesLayout() {

    let { url } = useRouteMatch();
    url = url.endsWith('/') ? url.slice(0, -1) : url;

    return (
        <Switch>
            <Route path={`${url}`} exact component={DeviceList} />
        </Switch>
    )

}

export default DevicesLayout;
