import React from 'react';
import { Typography } from '@material-ui/core';
import { useCustomerInDb } from '../../selectors';
import { ONBOARDING_COMPLETED } from '../../../companies/constants';
import { useTranslation } from 'react-i18next';

function CustomerOnboarding({ customer, capitalize, ...props }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.elements.company-onboarding' })
    customer = useCustomerInDb(customer);
    const status = customer.payload.onboarding_status !== ONBOARDING_COMPLETED ? t('pending') : null;
    if (!status) return null

    return (
        <Typography
            {...props}
            style={{ textTransform: capitalize ? 'capitalize' : 'inherit' }}
        >
            {status}
        </Typography>

    )
}

export default CustomerOnboarding;
