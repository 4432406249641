import React from 'react';
import CenteredFullScreen from '../../common/component/centered-full-screen';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export default function ProductNotFound() {
    const { t } = useTranslation('translation', { keyPrefix: 'app.products' })
    
    return (
        <CenteredFullScreen>
            <Typography>{t('not-found')}</Typography>
        </CenteredFullScreen>
    );
}
