import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function PaymentReceived({ order, ...props }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.elements.payment-received' })
    const message = order.payload.distributor.receive_payment ? t('received') : t('not-received');
    const color = order.payload.distributor.receive_payment ? t('received-color') : t('not-received-color');

    return (
        <Typography variant='overline' color={color} {...props} >{message}</Typography>
    )

}

function OrderPaymentReceived({ order, ...props }) {
    return (
        <PaymentReceived order={order} {...props} />
    )
}

export default OrderPaymentReceived;
