import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import {
    Card,
    CardContent,
    CardHeader,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@material-ui/core';
import { ChevronRight, Refresh as RefreshIcon } from '@material-ui/icons'
import { useDispatch } from 'react-redux';
import { useSdk } from '../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../lib/auth0-wrapper';
import { useNotif } from '../../../common/component/notification';
import ActionOk from '../../../common/component/notification/action-ok';
import { useChangeLocation, useCustomer } from '../customer-provider';
import { useTranslation } from 'react-i18next';

export const useLoadAwaitingValidationCustomersCallback = () => {
    const auth = useAuth0();
    const sdk = useSdk();
    const customer = useCustomer();
    const dispatch = useDispatch();
    const notif = useNotif();

    const [loading, setLoading] = useState(true);
    const [list, setList] = useState(null);

    const callback = useCallback((ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        setLoading(true);
        dispatch(sdk.customers.listAwaitingValidationForVD(auth, customer.target))
            .then((list) => setList(list))
            .finally(() => setTimeout(() => setLoading(false), 1000))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            });
    }, []);

    return [list, loading, callback];
}

export const useLoadAwaitingValidationCustomersRoutine = () => {

    const [list, loading, callback] = useLoadAwaitingValidationCustomersCallback();

    useEffect(() => {
        callback();
        const interval = setInterval(callback, 60 * 1000);
        return () => {
            clearInterval(interval);
        }
    }, []);

    return [list, loading, callback];

}

export default function AwaitingValidationCustomersListPage({ }) {
    const [list, loading, callback] = useLoadAwaitingValidationCustomersRoutine();
    const changeLocation = useChangeLocation();
    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.awaiting-validation.list-page' });

    if (list) {
        if (list.length > 0) {
            return (
                <Card>
                    <CardHeader title={t('card-title')} action={(
                        <IconButton onClick={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();
                            callback()
                        }}>
                            <RefreshIcon />
                        </IconButton>
                    )} />
                    <CardContent>
                        <List>
                            {_.map(list, (customer) => {
                                return (
                                    <ListItem button key={customer._id} onClick={(ev) => {
                                        ev.preventDefault();
                                        ev.stopPropagation();
                                        changeLocation(`customers/awaiting-validation/${customer._id}`)
                                    }}>
                                        <ListItemText primary={customer?.name} secondary={customer?.address?.country} />
                                        <ListItemIcon>
                                            <IconButton onClick={(ev) => {
                                                ev.preventDefault();
                                                ev.stopPropagation();
                                                changeLocation(`customers/awaiting-validation/${customer._id}`)
                                            }
                                            }>
                                                <ChevronRight />
                                            </IconButton>
                                        </ListItemIcon>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </CardContent>
                </Card >
            )
        } else {
            return <Typography>{t('no-customer')}</Typography>
        }
    } else {
        if (loading) {
            return <Typography>{t('loading')}</Typography>
        } else {
            return null
        }
    }
}