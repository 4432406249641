import React, { useMemo } from 'react';
import _ from 'lodash';
import CenteredFullScreen from '../../../common/component/centered-full-screen';
import {
    ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary,
    List, ListItem, ListItemSecondaryAction, ListItemText, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useReferenceName } from '../../../references/component/element/reference-name';
import { useReferenceById } from '../../../references/selector';
import { useLinesUnassociatedWithDevice } from '../../../order-lines/selector';
import AssociateDeviceButton from './associate';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    fullwidth: {
        width: "100%",
    }
}));

export const NoAvailableLicences = ({ }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'app.devices.available-licenses.list' })

    return (
        <CenteredFullScreen>
            <Typography>{t('no-license')}</Typography>
        </CenteredFullScreen>
    )
}

const ReferenceListItem = ({ reference, lines }) => {

    const classes = useStyles();
    const { t } = useTranslation('translation', { keyPrefix: 'app.devices.available-licenses.list' })
    reference = useReferenceById(reference);

    const primary = useReferenceName(reference);
    const secondary = t('subheader', { count: lines.length });

    return (
        <ListItem className={classes.fullwidth}>
            <ListItemText primary={primary} secondary={secondary} />
            <ListItemSecondaryAction><AssociateDeviceButton line={lines[0]} /></ListItemSecondaryAction>
        </ListItem>
    )
}

const AvailableLicencesByReference = ({ lines, ...props }) => {

    const classes = useStyles();

    const byReference = useMemo(() => {
        return _.groupBy(lines, line => line.payload.reference);
    }, [lines]);

    return (
        <List className={classes.fullwidth}>
            {_.map(byReference, (lines, reference) => (
                <ReferenceListItem key={reference} reference={reference} lines={lines} />
            ))}
        </List>
    )
}

export default function AvailableDevicesLicences({ title, ...props }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.devices.available-licenses.list' })
    title = title || t('title');
    const lines = useLinesUnassociatedWithDevice();

    return (
        <ExpansionPanel defaultExpanded={true}>
            <ExpansionPanelSummary>
                <Typography variant='h4'>{title}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                {lines.length === 0
                    ? <NoAvailableLicences {...props} />
                    : <AvailableLicencesByReference {...props} lines={lines} />
                }
            </ExpansionPanelDetails>
        </ExpansionPanel>
    )
}