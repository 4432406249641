import React, { useCallback, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

export default function UserEmailEditable(props) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.users.elements.email-edit' })
    const [error, setError] = useState(false)

    const onChange = useCallback((ev) => {

        const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;
        const value = ev.target.value;
        if (!regex.test(value)) {
            setError(t('validation'));
            if(props.setError) props.setError(true);
        } else {
            setError(false);
            if(props.setError) props.setError(false);
        }

        props.onChange(value.trim());
    }, []);

    return (
        <TextField
            error={!!error}
            label={t('label')}
            name='email'
            type='email'
            value={props.value || ''}
            onBlur={onChange}
            onChange={onChange}
            helperText={error}
        />
    );
}
