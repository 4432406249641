import React, { useState } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { useCustomer } from '../customer-provider';
import { useMainCompany } from '../../../relations/component/user-relation-provider';
import ChangeDaysUntilDueByModal from './change-days-until-due-modal';
import { useTranslation } from 'react-i18next';

export default function ChangeDaysUntilDueSidebarButton({ defaultCustomer, ...props }) {

    const [open, setOpen] = useState(false);
    const onClose = () => setOpen(false);
    const onOpen = () => setOpen(true);

    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.change-days-until-due.sidebar-button' })

    const company = useMainCompany();
    const currentCustomer = useCustomer();
    const customer = defaultCustomer || currentCustomer;

    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb(ev);
    }

    return (
        <React.Fragment>
            <ListItem button onClick={handleClick(onOpen)}>
                <ListItemIcon><ScheduleIcon /></ListItemIcon>
                <ListItemText primary={t('label', { name: customer.payload.name })} />
            </ListItem >
            <ChangeDaysUntilDueByModal
                vayandata={company}
                customer={customer}
                open={open} onClose={handleClick(onClose)} />
        </React.Fragment>
    );
}
