import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeviceSumupRow from './device-sumup-row';
import { getProductDeviceName } from '../../../products/component/element/product-device-name';
import { useTranslation } from 'react-i18next';

export function DeviceSumupListHeader({ product }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.order-lines.sumup.headers' });
    const deviceName = getProductDeviceName(product);
    return (
        <TableHead>
            <TableRow>
                <TableCell>
                    {t(deviceName)}
                </TableCell>
                <TableCell align="center">
                    {t('comment')}
                </TableCell>
                <TableCell align="right">
                    {t('status')}
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

export default function DeviceSumupList({ devices, product, reference, onProceed }) {

    return (
        <TableContainer component={Paper}>
            <Table aria-label="order sumup table">
                <DeviceSumupListHeader product={product.target} />
                <TableBody>
                    {devices.map((device, i) => {
                        return (<DeviceSumupRow key={i} device={device} product={product} reference={reference} onProceed={onProceed} />)
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
