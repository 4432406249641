import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export function ErrorOccured() {
    const { t } = useTranslation('translation', { keyPrefix: 'app.order-lines.rules' });
    return (
        <span>
            <Typography display='inline' color='error'>
                {t('error-occured')}
            </Typography>
        </span>
    )
}
