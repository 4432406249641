import React from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    field: {
        width: '100%'
    }
}));

const UserEmailField = ({ namespace, isSubmitting, ...props }) => {

    const classes = useStyles();
    const name = `${namespace ? namespace + '.' : ''}email`;
    const { t } = useTranslation('translation', { keyPrefix: 'app.users.elements.email' });
    return (
        <Field
            className={classes.field}
            component={TextField}
            name={name}
            type="email"
            label={t('label')}
            validate={value => value !== '' ? undefined : t('validation')}
            disabled={isSubmitting}
        />
    );
}


export default UserEmailField;
